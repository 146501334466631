import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnDestroy, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { StepsEnum } from '../../../../../enums/steps.enum';
import { ILoaderInformationState } from 'src/app/store/states/loader-information.state';
import { LoaderActions } from 'src/app/store/actions';
import {
	carrierSelectedForOfflineBid,
	clearToggledRatesSelected,
	setResultData,
	showAltRates,
} from 'src/app/store/actions/result-data.actions';
import {
	selectApplicationId,
	selectFriendlyId,
	selectInterviewMetadata,
} from 'src/app/store/selectors/interview-metadata.selector';
import { BasePageComponent } from '../../base-page.component';
import { IAppState } from '../../../../../store/states/app.state';
import { QuoteDataService } from '../../../../../services/quote-data.service';
import { ActionApiService } from '../../../../../services/action-api.service';
import { selectLoaderInformation } from '../../../../../store/selectors/loader-information.selector';
import { selectQuoteData } from '../../../../../store/selectors/quote-data.selectors';
import { UpdateInterviewMetadata } from '../../../../../store/actions/interview-metadata.actions';
import { ConsumerBindTypeEnum } from '../../../../../enums/consumer-bind-type.enum';
import { LobsEnum } from '../../../../../enums/lobs.enum';
import { selectAgentPhoneNumber } from '../../../../../store/selectors/active-theme.selector';
import { RatesModel } from './models/rates.model';
import { IResultState } from '../../../../../store/states/result-data.state';
import { CoverageModel } from '../../../../../models/coverage.model';
import { showPopupAction } from '../../../../../store/actions/popup.actions';
import { RateService } from './services/rate.service';
import {
	getD2CFeatures,
	NOT_ALLOWED_ADDITIONAL_COVERAGES_VALUES,
	pricePerMonth,
} from '../../../../../utils/general.utils';
import {
	selectIsAltRatesVisible,
	selectRatePlanSelectedForOfflineBid,
	selectRates,
	selectSource,
} from '../../../../../store/selectors/result-data.selectors';
import { LobService } from 'src/app/services/lob.service';
import { first, map } from 'rxjs/operators';
import { ADDITIONAL_COVERAGES_TEXTS } from '../../../popup/additional-coverages/texts.data';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from '../../../../../services/config.service';
import { CssPropertiesEnum } from 'src/app/models/css-properties.enum';
import { ActivatedRoute } from '@angular/router';
import { IQuoteDataState } from 'src/app/store/states/quote-data.state';

@UntilDestroy()
@Component({
	selector: 'app-result',
	templateUrl: './result.component.html',
	styleUrls: ['./result.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResultComponent extends BasePageComponent implements OnInit, OnDestroy {
	loaderInformation$: Observable<ILoaderInformationState>;
	apiInterval: NodeJS.Timer;
	rates: RatesModel;
	videoApiUrl: string;
	firstName: string;
	phoneNumber: string;
	friendlyId: string;
	lob: LobsEnum;
	StepsEnum = StepsEnum;
	LobsEnum = LobsEnum;
	noRates = false;
	isMonthly = true;
	isOffline = false;
	selectedRates: CoverageModel[] = [];
	isMultiLob = false;
	lobSelection = [];
	lobsInitialized = [];
	isMultiLobWithSingleLobResults = false;
	ratePlanSelectedForOfflineBid: CoverageModel[] = [];
	getLobService: LobService;
	isFetchingResultsCompleted = false;
	isAdditionalCoveragesActive;
	additionalCoveragesIncluded = [];
	tooltipAdditionalCoveragesText: string;
	isNotOemResultsLazyLoading = true;
	isOnlineRatesVisible = true;
	showMore = false;
	activeAdditionalCoverages = [];
	isVideoPosterExists: boolean;
	videoPosterSrc$: Observable<string>;
	anchorCarrier: CoverageModel;
	anchorCarrierNames: string[];
	// determine if rates should be visible while submission in progress
	isRatesLazyLoading: boolean;
	NOT_ALLOWED_ADDITIONAL_COVERAGES_VALUES = NOT_ALLOWED_ADDITIONAL_COVERAGES_VALUES;

	constructor(
		injector: Injector,
		protected quoteDataService: QuoteDataService,
		protected store: Store<IAppState>,
		private actionApi: ActionApiService,
		private changeRef: ChangeDetectorRef,
		private rateServices: RateService,
		private lobService: LobService,
		private translateService: TranslateService,
		private config: ConfigService,
		private route: ActivatedRoute
	) {
		super(injector, store);

		this.videoApiUrl = this.config.getConfig().videoStorageUrl;
		this.loaderInformation$ = this.store.select(selectLoaderInformation);
		this.actionApi = injector.get(ActionApiService);
	}

	get isMedia(): boolean {
		return this.theme?.texts[StepsEnum.RESULT].videoUrl || this.theme?.images['ratesImage'];
	}

	get mediaPosition(): string {
		return this.theme?.additionalClasses.resultMediaPosition;
	}

	get url(): string {
		return this.theme?.texts[StepsEnum.RESULT].videoUrl && this.videoApiUrl
			? `${this.videoApiUrl}${this.theme.texts[StepsEnum.RESULT].videoUrl}`
			: null;
	}

	get image(): string {
		return this.theme?.images['ratesImage'];
	}

	get mediaAlt(): string {
		return this.theme?.texts[StepsEnum.RESULT].mediaAlt;
	}

	get autoplay(): boolean {
		return this.theme?.texts[StepsEnum.RESULT].videoAutoplay;
	}

	get initialVolume(): string {
		return this.theme?.properties[CssPropertiesEnum.videoInitialVolume];
	}

	get isOem(): boolean {
		// for the OEM flow we have to check
		// if Quick flow is not Multilob
		// if selected lob is PersonalAuto
		// and if oemEndorsement is TRUE
		return !this.isMultiLob && this.lobSelection[0] === LobsEnum.PERSONAL_AUTO && this.d2CFeatures?.oemEndorsement;
	}

	ngOnInit(): void {
		super.ngOnInit();

		this.store.dispatch(carrierSelectedForOfflineBid({ rates: [] }));

		this.isVideoPosterExists = !!this.theme?.images['ratesVideoPoster'];

		if (this.isVideoPosterExists) {
			this.videoPosterSrc$ = this.store.select(selectSource).pipe(
				map((source) => {
					return this.actionApi.getFileSrc(this.theme?.images['ratesVideoPoster'], source);
				})
			);
		}

		combineLatest([
			this.lobService.getLob$(),
			this.store.select(selectQuoteData),
			this.store.select(selectAgentPhoneNumber),
			this.store.select(selectFriendlyId),
			this.store.select(selectInterviewMetadata),
		])
			.pipe(first())
			.subscribe(([lob, quoteData, phoneNumber, friendlyId, interviewMetadata]) => {
				this.lob = lob;
				this.firstName = quoteData.FirstName.charAt(0).toUpperCase() + quoteData.FirstName.slice(1);
				this.phoneNumber = phoneNumber;
				this.friendlyId = friendlyId;
				this.lobSelection = interviewMetadata.lobSelection?.filter((lobSel) => lobSel !== LobsEnum.FLOOD);
				this.lobsInitialized = interviewMetadata.lobsInitialized?.filter((lobSel) => lobSel !== LobsEnum.FLOOD);
				this.isMultiLob = interviewMetadata.isMultiLob || interviewMetadata.crossSellTo.length > 0;
				this.isNotOemResultsLazyLoading = !this.isOem;

				// Check if we have additional coverages
				this.checkForAdditionalCoverages(quoteData);

				// *
				// start check if we have anchorCarriers in leadSource
				const anchorCarriers = interviewMetadata.leadSource.additionalInformation?.d2CFeatures?.anchorCarriers;

				if (anchorCarriers && Object.entries(anchorCarriers)?.length && anchorCarriers[this.lob]) {
					// assign the name of anchor carrier
					this.anchorCarrierNames = anchorCarriers[this.lob].map((carrierName) => carrierName.toLowerCase());
				}

				this.isRatesLazyLoading = !this.anchorCarrierNames?.length;
				// end check anchorCarriers
				// **

				if (
					this.quoteDataService.serverQuoteErrors.value &&
					this.quoteDataService.serverQuoteErrors.value.validationErrors &&
					this.quoteDataService.serverQuoteErrors.value.validationErrors.length
				) {
					// If there are errors, it means that the user was not able to fix them in previous steps
					// should not make a submission with invalid data, so redirect to error page.
					this.routingService.navigateToErrorPage(StepsEnum.TECHNICAL_ERROR);
				} else {
					if (!interviewMetadata.Submission) {
						// no need to call to submission again
						this.restoreSubmissionResults();
					} else {
						// need to call to submission FQ
						this.callForSubmission();
					}
				}
			});

		this.store
			.select(selectRatePlanSelectedForOfflineBid)
			.pipe(untilDestroyed(this))
			.subscribe((ratePlanSelectedForOfflineBid) => {
				this.ratePlanSelectedForOfflineBid = [...ratePlanSelectedForOfflineBid];

				if (this.rates && this.rates.ratesByLob) {
					this.rates.ratesByLob.map((lobGroup) => {
						lobGroup.expanded = false;
					});
					const lobGroupsWithNoSelectedRate = this.rates.ratesByLob.filter(
						(lobGroup) => !this.ratePlanSelectedForOfflineBid.find((selectedRate) => selectedRate.lob === lobGroup.lob)
					);
					if (lobGroupsWithNoSelectedRate[0]) {
						lobGroupsWithNoSelectedRate[0].expanded = true;
					}
				}
				this.changeRef.detectChanges();
			});

		if (this.isOem) {
			this.store
				.select(selectIsAltRatesVisible)
				.pipe(untilDestroyed(this))
				.subscribe((res) => {
					this.isOnlineRatesVisible = res;
					this.changeRef.detectChanges();
				});
		}

		this.rateServices.coveragesApplied.pipe(untilDestroyed(this)).subscribe((res) => {
			this.store
				.select(selectQuoteData)
				.pipe(first())
				.subscribe((quoteData) => {
					this.checkForAdditionalCoverages(quoteData);
				});

			if (res) {
				this.isFetchingResultsCompleted = false;
				this.rates = null;
				this.callForSubmission();
			}
		});
	}


	checkForAdditionalCoverages(quoteData: IQuoteDataState) {
		this.activeAdditionalCoverages = [];
		this.additionalCoveragesIncluded = [];
		ADDITIONAL_COVERAGES_TEXTS.forEach((cov) => {
			if (quoteData[cov.name] && !NOT_ALLOWED_ADDITIONAL_COVERAGES_VALUES.includes(quoteData[cov.name])) {
				this.activeAdditionalCoverages.push(cov.name);
			}
		});
		this.tooltipAdditionalCoveragesText = this.translateService.instant('labels.followingCoverageAdded');
		let allAdditionalCoverages = ADDITIONAL_COVERAGES_TEXTS.map((i) => i.name);
		allAdditionalCoverages.forEach((coverage) => {
			if (quoteData[coverage] && quoteData[coverage] !== false && quoteData[coverage] !== null) {
				this.additionalCoveragesIncluded.push(coverage);
				this.tooltipAdditionalCoveragesText =
					this.tooltipAdditionalCoveragesText +
					this.translateService.instant(`coveragesTexts.additionalCoverages.PersonalHome.${coverage}.0.displayName`) +
					', ';
			}
		});
		this.tooltipAdditionalCoveragesText = this.tooltipAdditionalCoveragesText.slice(0, -2) + '.';
	}

	/** get quotes every 5 seconds and display them on the page until "Completed" status from the server */
	getRates() {
		this.store.select(selectApplicationId).subscribe((applicationId) => {
			const intervalFunction = () => {
				this.actionApi
					.post({ action: 'api/submissionResults', endpoint: `applications`, applicationId })
					.toPromise()
					.then((res: IResultState) => {
						if (!res) {
							console.error('applications request error');
							return;
						}
						this.handleResponse(res);
						this.changeRef.detectChanges();
						setTimeout(() => {
							this.baseNgAfterViewInit();
						});
					})
					.catch((err) => console.log(err));
			};

			intervalFunction();

			this.apiInterval = setInterval(() => {
				intervalFunction();
			}, 5000);
		});
	}

	handleResponse(res) {
		if (Object.keys(res).length !== 0) {
			if (res.quotes) {
				// this should be done before groupByConsumerBindType
				this.customRatesManipulations(res.quotes);
			}
			this.groupRates(res);
			res.rates = res.quotes;

			if (this.isMultiLob) {
				this.rates = this.groupForMultiLob(this.rates);
				// this flag indicates that there are rates only for one of all lobs
				this.isMultiLobWithSingleLobResults =
					this.rates.ratesByLob.filter((lobGroup) => lobGroup.rates.length > 0).length === 1;
				// this.rates.ratesByLob = this.groupForMultiLob({ ...res }, 'lob');
			}

			// Manipulating for additionl coverages
			if (
				(getD2CFeatures()?.homeownersAdditionalCoverage &&
					this.lobSelection.length == 1 &&
					this.lobSelection[0] === LobsEnum.PERSONAL_HOME) ||
				(this.lobSelection.length == 2 &&
					this.lobSelection.includes(LobsEnum.PERSONAL_HOME) &&
					this.lobSelection.includes(LobsEnum.PERSONAL_AUTO))
			) {
				this.rates = this.groupForAdditionalCoverages(this.rates);
				this.isAdditionalCoveragesActive = true;
			}
			this.store.dispatch(setResultData({ data: res }));
		}

		if (res.status === 'Completed') {
			this.store.dispatch(UpdateInterviewMetadata({ data: { Submission: false } }));

			this.stopFetchingResults();

			this.isOffline = !this.rates.rates.online?.length;
		}

		if (this.isFetchingResultsCompleted) {
			const isAnchorCarrier = this.rates.rates?.anchorCarrier?.length && res.quotes.length === 1;
			this.isRatesLazyLoading = true;

			if (isAnchorCarrier) {
				this.anchorCarrier = { ...this.rates.rates.anchorCarrier[0], selected: true };
			}
		}
	}

	groupRates(res) {
		if (res.quotes) {
			this.rates = this.groupByConsumerBindType(res);

			if (this.rates.rates && this.rates.rates.toggled) {
				this.store.dispatch(clearToggledRatesSelected());
			}
		} else {
			this.rates = res;
		}
	}

	groupByConsumerBindType(res) {
		const rates = {
			...res,
			rates: res.quotes.reduce((memo, rate: CoverageModel) => {
				let isOffline = rate.consumerBindType === ConsumerBindTypeEnum.CALL_AGENT;

				memo.oem = memo.oem || [];
				memo.oemOffline = memo.oemOffline || [];
				memo.toggled = memo.toggled || [];
				memo.online = memo.online || [];
				memo.offline = memo.offline || [];
				memo.anchorCarrier = memo.anchorCarrier || [];
				memo.a = memo.offline || [];

				if (rate.lob === LobsEnum.FLOOD) {
					memo.toggled.push({ ...rate, isToggledType: true });
				} else {
					let isHasSpecificCoverage = false;
					this.isOnlineRatesVisible = true;

					// check is OEM
					// if TRUE than find specific coverage in the rate and add this rate to the oem group
					const autoCoverages = rate.coverages?.personalAuto?.entityCoverages?.personalAuto;

					if (autoCoverages) {
						Object.keys(autoCoverages).forEach((key) => {
							if (autoCoverages[key].filter((c) => c.coverage === 'OEMCoverage').length) {
								isHasSpecificCoverage = true;
							}
						});
					}

					if (this.isOem && isHasSpecificCoverage) {
						memo[isOffline ? 'oemOffline' : 'oem'].push({ ...rate, isVisible: true, isOffline });
					} else {
						if (isOffline) {
							memo.offline.push(rate);
						} else {
							memo.online.push({ ...rate, isVisible: true });
						}
					}

					// assign anchor carrier if we have
					if (this.anchorCarrierNames?.includes(rate.carrier?.toLowerCase())) {
						memo.anchorCarrier.push({ ...rate });
					}
				}

				return memo;
			}, {}),
		};

		return this.sortRates(rates);
	}

	groupForAdditionalCoverages(rates) {
		const ratesByAdditionalCoverages = {
			allCoverages: { offline: [], online: [] },
			someAdditionalCoverages: { offline: [], online: [] },
		};

		rates.quotes.forEach((element) => {
			// need to check for addtional coverages for this rate
			if (element.coverages.personalHome) {
				let numCurrAdditionalCoverages = element.coverages.personalHome.policyLevelCoverages.filter((e) => {
					return (
						!NOT_ALLOWED_ADDITIONAL_COVERAGES_VALUES.includes(e.value) &&
						this.activeAdditionalCoverages.includes(e.coverage)
					);
				});

				if (numCurrAdditionalCoverages.length == this.activeAdditionalCoverages.length) {
					if (element.consumerBindType == 'API') {
						ratesByAdditionalCoverages.allCoverages.online.push({ ...element, isVisible: true });
					} else {
						ratesByAdditionalCoverages.allCoverages.offline.push({ ...element, isVisible: true });
					}
				} else {
					if (element.consumerBindType == 'API') {
						ratesByAdditionalCoverages.someAdditionalCoverages.online.push({ ...element, isVisible: true });
					} else {
						ratesByAdditionalCoverages.someAdditionalCoverages.offline.push({ ...element, isVisible: true });
					}
				}
			}
		});
		if (
			!(
				ratesByAdditionalCoverages.allCoverages.online.length + ratesByAdditionalCoverages.allCoverages.offline.length
			) &&
			ratesByAdditionalCoverages.someAdditionalCoverages.online.length +
			ratesByAdditionalCoverages.someAdditionalCoverages.offline.length
		) {
			this.showMore = true;
		}
		return {
			...rates,
			ratesByAdditionalCoverages,
		};
	}

	groupForMultiLob(rates) {
		const quotes = rates.quotes.filter((quote) => quote.lob !== LobsEnum.FLOOD);

		return {
			...rates,
			ratesByLob: this.groupByLob(quotes),
			ratesByBundle: this.groupByBundle(quotes),
		};
	}

	groupByBundle(rates: CoverageModel[]) {
		return rates
			.reduce((memo, rate) => {
				memo = memo || [];

				const bundle = memo.find((el) => el.carrier === rate.carrier);

				if (bundle) {
					bundle.rates.push(rate);
					bundle.lobs.push(rate.lob);
					bundle.premium += pricePerMonth(rate.premium, rate.term);
				} else {
					memo.push({
						carrier: rate.carrier,
						premium: pricePerMonth(rate.premium, rate.term),
						lobs: [rate.lob],
						rates: [rate],
					});
				}

				return memo;
			}, [])
			.filter((bundle) => bundle.lobs.length > 1)
			.sort((a, b) => {
				return a.order - b.order;
			});
	}

	groupByLob(rates: CoverageModel[]) {
		return rates
			.reduce(
				(memo, rate) => {
					const lobGroup = memo.find((el) => el.lob === rate.lob);

					if (lobGroup) {
						lobGroup.rates.push(rate);
					}

					return memo;
				},
				this.lobSelection.map((lob) => ({
					lob,
					rates: [],
					expanded: true,
				}))
			)
			.map((lobGroup, index, ratesByLob) => ({
				...lobGroup,
				expanded:
					lobGroup.rates.length &&
					(!ratesByLob[index - 1] || !(ratesByLob[index - 1].expanded && ratesByLob[index - 1].rates.length)),
				rates: lobGroup.rates.sort((a, b) => {
					return a.order - b.order;
				}),
			}));
	}

	stopFetchingResults(isDestroy?: boolean) {
		this.isFetchingResultsCompleted = true;

		clearInterval(this.apiInterval);

		this.store.dispatch(LoaderActions.Hideloader());

		if (
			!isDestroy &&
			((this.rates && this.rates.quotes.length === 0) ||
				!this.rates.quotes.filter((item) => item.lob !== LobsEnum.FLOOD).length)
		) {
			this.noRates = true;
		}

		this.toggleOemResultsVisibility();

		this.changeRef.detectChanges();
	}

	ngOnDestroy() {
		this.stopFetchingResults(true);
		this.store.dispatch(showAltRates({ show: false }));
	}

	chooseMultiLobBundle(rates: CoverageModel[], isOffline = false) {
		this.handleBundleSelection(rates);
		this.openAllCoveragesPopup(isOffline);
	}

	chooseMultiLobSeparateRate(rate: CoverageModel, isOffline = false): void {
		// If there are rates for only one lob of multi lob, should open a single lob flow
		if (this.isMultiLobWithSingleLobResults) {
			this.chooseRate(rate, isOffline);
		} else {
			this.handleMultiLobSeparateRateSelection(true, rate);
			this.openAllCoveragesPopup(isOffline, true);
		}
	}

	chooseRate(rate: CoverageModel, isOffline = false): void {
		this.handleRateSelection(true, rate, 'unshift');
		this.openAllCoveragesPopup(isOffline);
	}

	handleToggledRate(isSelected, rate: CoverageModel): void {
		if (this.isAdditionalCoveragesActive) {
			this.toggle(this.rates.ratesByAdditionalCoverages['allCoverages'], isSelected, rate);
			this.toggle(this.rates.ratesByAdditionalCoverages['someAdditionalCoverages'], isSelected, rate);
		} else {
			this.toggle(this.rates.rates, isSelected, rate);
		}
	}

	toggle(rates, isSelected, rate) {
		rates.online = rates.online.map((item) => ({
			...item,
			isVisible: !isSelected,
		}));

		if (isSelected) {
			rates.offline = [...rates.online, ...rates.offline].map((i) => {
				return { ...i, isVisible: true };
			});
		} else {
			rates.offline = rates.offline.filter((item) => item.consumerBindType === ConsumerBindTypeEnum.CALL_AGENT);
		}

		this.handleRateSelection(isSelected, rate, 'push');
	}

	handleRateSelection(isSelected, rate: CoverageModel, action = 'push'): void {
		this.selectedRates = this.selectedRates.filter((item) => item.carrier !== rate.carrier && item.lob !== rate.lob);

		if (isSelected || action === 'push') {
			this.selectedRates[action]({ ...rate, isSelected: !!isSelected });
		}

		this.pushEvent(
			rate.carrier + `-choose-offer-button-${rate.consumerBindType}`,
			rate.consumerBindType === 'API' ? 'buy-now' : 'view-quote'
		);
	}

	handleBundleSelection(rates: CoverageModel[]): void {
		this.selectedRates = rates.map((rate) => ({ ...rate, isSelected: true }));

		this.pushEvent(
			rates[0].carrier + `-choose-offer-button-${this.selectedRates[0].consumerBindType}`,
			this.selectedRates[0].consumerBindType === 'API' ? 'buy-now' : 'view-quote'
		);
	}

	handleMultiLobSeparateRateSelection(isSelected, rate: CoverageModel): void {
		this.selectedRates[0] = { ...rate, isSelected: true };

		this.pushEvent(
			rate.carrier + `-choose-offer-button-${rate.consumerBindType}`,
			rate.consumerBindType === 'API' ? 'buy-now' : 'view-quote'
		);
	}

	getLobGroupSelectedRateCarrierName(lob: string): string {
		const selectedRateInLobGroup = this.ratePlanSelectedForOfflineBid.find((rate) => rate.lob === lob);
		return selectedRateInLobGroup ? selectedRateInLobGroup.carrier : '';
	}

	openAllCoveragesPopup(isOffline: boolean, openForSelect?: boolean) {
		this.store.dispatch(
			showPopupAction({
				componentName: 'all-coverages',
				payload: {
					text: this.translateService.instant('popups.titles.all-coverages') + this.friendlyId,
					rates: this.rateServices.transformRates(this.selectedRates),
					isMonthly: this.isMonthly,
					gtmLabel: 'CoveragePopUp',
					customClass: 'side-popup',
					isOffline,
					openForSelect,
					lob: this.lob,
				},
			})
		);
	}

	pushEvent(label: string, button: string): void {
		this.eventsService.addEvent(this.EventActions.BUTTON_CLICK, { label, button, module: 'result-module' });
	}

	sortRates(rates) {
		const coverageTypeKeys = Object.entries(rates.rates);

		if (coverageTypeKeys.length) {
			coverageTypeKeys.forEach(([key]) => {
				rates.rates[key].sort((a, b) => {
					return a.order - b.order;
				});
			});
		}

		return rates;
	}

	customRatesManipulations(rates) {
		// if lob is Renters AND Safeco rate present AND SafecoHNT rate present
		// then show only SafecoHNT rate, but with Safeco logo
		if (this.lob === LobsEnum.RENTERS) {
			const safecoRateIndex = rates.findIndex((rate) => rate.carrier === 'Safeco Insurance');
			const safecoHNTRateIndex = rates.findIndex((rate) => rate.carrier === 'SafecoHNT Insurance');
			if (safecoRateIndex !== -1 && safecoHNTRateIndex !== -1) {
				rates[safecoHNTRateIndex].customLogo = 'Safeco Insurance';
				rates.splice(safecoRateIndex, 1);
			}
		}
	}

	toggleGroup(lobGroup: { lob: string; rates: CoverageModel[]; expanded: boolean }) {
		this.rates.ratesByLob.forEach(
			(group) => (group.expanded = group.lob === lobGroup.lob ? !group.expanded : group.expanded)
		);
	}

	onMultilobTabChange() {
		this.selectedRates = [];

		this.store.dispatch(carrierSelectedForOfflineBid({ rates: [] }));
	}

	handleEditCoverages() {
		this.store.dispatch(
			showPopupAction({
				componentName: 'additional-coverages',
				payload: {
					text: 'popups.titles.additional-coverages',
					gtmLabel: 'CoveragePopUp',
					customClass: 'side-popup',
				},
			})
		);
	}

	getAllFullAdditionalCoverages() {
		return this.rates?.ratesByAdditionalCoverages['allCoverages'].online
			.map((i) => {
				return { ...i, isOnline: true };
			})
			.concat(
				this.rates?.ratesByAdditionalCoverages['allCoverages'].offline.map((i) => {
					return { ...i, isOnline: false };
				})
			)
			.filter((coverage) => coverage.isVisible == true);
	}

	getSomeAdditionalCoverages() {
		return this.rates?.ratesByAdditionalCoverages['someAdditionalCoverages'].online
			.map((i) => {
				return { ...i, isOnline: true };
			})
			.concat(
				this.rates?.ratesByAdditionalCoverages['someAdditionalCoverages'].offline.map((i) => {
					return { ...i, isOnline: false };
				})
			)
			.filter((coverage) => coverage.isVisible == true);
	}

	handleShowMore() {
		this.showMore = true;
	}

	loadAlternativeCarriers(): void {
		this.store.dispatch(
			showPopupAction({
				componentName: 'alt-carriers',
				payload: {
					textKey: 'rates.alternativePpTitle',
					contentKey: 'rates.alternativePpText',
				},
			})
		);
	}

	showOemInfo(): void {
		this.store.dispatch(
			showPopupAction({
				componentName: 'oem-selection',
				payload: {
					textKey: 'rates.verifiedPpTitle',
					contentKey: 'rates.verifiedPpText',
				},
			})
		);
	}

	showNonOemInfo(): void {
		this.store.dispatch(
			showPopupAction({
				componentName: 'non-oem-selection',
				payload: {
					textKey: 'rates.nonOemPpTitle',
					contentKey: 'rates.nonOemPpText',
				},
			})
		);
	}

	private toggleOemResultsVisibility(): void {
		if (this.isOem) {
			this.isNotOemResultsLazyLoading = true;
			this.isOnlineRatesVisible = !this.rates?.rates.oem.length;

			if (this.isOnlineRatesVisible) {
				this.store.dispatch(showAltRates({ show: true }));
			}
		}
	}

	private restoreSubmissionResults() {
		this.isFetchingResultsCompleted = true;

		this.store.dispatch(LoaderActions.Hideloader());

		this.store
			.select(selectRates)
			.pipe(first())
			.subscribe((res) => {
				if (res.length === 0) {
					this.noRates = true;
				}

				this.handleResponse({ quotes: res });

				this.toggleOemResultsVisibility();
			});
	}

	private callForSubmission() {
		this.actionApi
			.submitQuote()
			.pipe(untilDestroyed(this))
			.subscribe({
				next: () => this.getRates(),
				error: () => this.routingService.navigateToErrorPage(StepsEnum.TECHNICAL_ERROR),
			});

		/** responsible for the overlay in app component refactor */
		this.store.dispatch(LoaderActions.ShowLoader());
	}
}
