import { FlowType } from '../enums/step-groups.enum';
import { StepsGroup } from '../models/step-groups.model';
import { IProgressMeter } from '../store/states/progress-meter.state';
import { LobsEnum } from '../enums/lobs.enum';
import { IAppState } from '../store/states/app.state';

export const groupedSteps = (pm: IProgressMeter): StepsGroup[] => {
	let groupedSteps = [];
	const isMultiLob = getIsMultiLob();
	const activeStep = pm.flowOptions[pm.flowType][pm.activeStep];
	const isQQ = activeStep?.stepProperties?.progressMeter?.flowType !== FlowType.fq;
	const flowOptions = pm.flowOptions[pm.flowType];

	//*
	// Filter all steps that have progressMeter visible:true, group/multiLobGroup parameters and by type of flow (FullQuote or QuickQuote)
	// group or multiLobGroup parameters define if step should be grouped as a part of multi lob (like Auto + Home)
	// or this step should be a part of one of the group of Flow
	// e.g - Primary driver and Drivers pages are part of Drivers group of Auto flow in the UI
	let steps = flowOptions
		.filter((step) => step?.stepProperties?.progressMeter?.visible)
		.filter((step) => step?.stepProperties?.progressMeter[isMultiLob && isQQ ? 'multiLobGroup' : 'group'])
		.filter((step) => step?.stepProperties?.progressMeter?.flowType === (isQQ ? FlowType.qq : FlowType.fq));

	//*
	// Also we need to get rid of extra Additional question pages filtering them by selected carrier
	if (!isQQ && getSelectedCarrier()) {
		const additionalQuestions =
			[...steps].filter(
				({ stepProperties }) =>
					stepProperties?.progressMeter?.carrier?.toLowerCase() === getSelectedCarrier().toLowerCase()
			) || [];

		steps = [
			...additionalQuestions,
			...steps.filter((steps) => !steps?.stepProperties?.progressMeter?.hasOwnProperty('carrier')),
		];
	}

	steps.forEach((step) => {
		const stepPM = { ...step?.stepProperties?.progressMeter };
		const groupName = isMultiLob ? stepPM?.multiLobGroup : stepPM?.group;
		let indexInGroup = groupedSteps.map((g) => g.name).indexOf(groupName);

		const newStep = {
			...step,
			active: step.name === activeStep.name,
		};

		if (indexInGroup < 0) {
			groupedSteps.push({
				name: groupName,
				steps: [],
			});

			indexInGroup = groupedSteps.length - 1;
		}

		groupedSteps[indexInGroup].steps.push(newStep);
	});

	groupedSteps = groupedSteps.map((group, index) => ({
		...group,
		active: !!group.steps.filter((s) => s.active).length,
	}));

	return (
		groupedSteps.map((group, index) => ({
			...group,
			progress: getProgress(group, index, activeStep.name, groupedSteps),
		})) || []
	);
};

export const getIsMultiLob = (): boolean => {
	return (
		JSON.parse(sessionStorage.getItem('state'))?.interviewMetadata?.lobSelection?.filter(
			(lob: string) => lob !== LobsEnum.FLOOD
		).length > 1
	);
};

export const getSelectedCarrier = (): string => {
	return (JSON.parse(sessionStorage.getItem('state')) as IAppState).resultData?.selectedCarrier;
};

const getProgress = (group: StepsGroup, index: number, activeStepName: string, groupedSteps: StepsGroup[]): number => {
	const activeGroupIndex = groupedSteps.map((g) => g.active).indexOf(true);
	const activeStepIndex = group.steps.map((s) => s.name).indexOf(activeStepName);

	if (index > activeGroupIndex || (activeStepIndex === 0 && activeGroupIndex === 0)) {
		return 0;
	} else if (activeStepIndex === 0 && activeGroupIndex !== groupedSteps.length - 1) {
		return 50;
	} else if (index < activeGroupIndex || activeGroupIndex === groupedSteps.length - 1 || group.steps.length - 1) {
		return 100;
	}

	return (50 / (group.steps.length - 1)) * (activeStepIndex + 1) + 50;
};
