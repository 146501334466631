import { AfterViewInit, Component, Injector, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';

import { Store } from '@ngrx/store';
import { first } from 'rxjs/operators';

import { BasePageComponent } from '../../base-page.component';
import { Validations } from 'src/app/utils/validation';
import { QuoteDataService } from 'src/app/services/quote-data.service';
import { IAppState } from 'src/app/store/states/app.state';
import { EventActions } from '../../../../../models/event-data.model';
import { ActionApiService } from 'src/app/services/action-api.service';
import { EditDiscoveredDriver, EditManualDriver, UpdateQuoteData } from 'src/app/store/actions/quote-data.actions';
import { LoaderActions } from 'src/app/store/actions';
import { selectManualDrivers, selectQuoteData } from 'src/app/store/selectors/quote-data.selectors';
import { showPopupAction } from 'src/app/store/actions/popup.actions';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NextButtonComponent } from '../../../../next-button/next-button.component';
import { FormattedDateService } from 'src/app/services/formatted-date.service';
import { DriverSourceEnum } from './model/driver-source.enum';
import { DriversService } from 'src/app/services/drivers.service';
import { NavigateToNextPage } from 'src/app/store/actions/routing.actions';

@UntilDestroy()
@Component({
	selector: 'app-additional-drivers',
	templateUrl: './additional-drivers.component.html',
	styleUrls: ['./additional-drivers.component.scss'],
})
export class AdditionalDriversComponent extends BasePageComponent implements OnInit, AfterViewInit {
	@ViewChild(NextButtonComponent, { static: true }) nextButtonComponent: NextButtonComponent;
	eventActions = EventActions;
	actionApiService: ActionApiService;
	discoverDrivers;
	applicant;
	selectedState: string;

	numDrivers = 0;
	numActiveDrivers = 0;

	MAX_NUM_DRIVERS = 25;
	MAX_NUM_ACTIVE_DRIVERS = 6;
	DATE_FORMAT = 'YYYY-MM-DD';

	SequenceNum = 0;
	isDiscoverDrivers: boolean;
	first = false;

	constructor(
		actionApiService: ActionApiService,
		injector: Injector,
		protected quoteDataService: QuoteDataService,
		protected store: Store<IAppState>,
		private formattedDateService: FormattedDateService,
		private driversService: DriversService
	) {
		super(injector, store);
		this.actionApiService = actionApiService;
	}

	get primaryDriverFormControl(): FormArray {
		return this.form.controls.primaryDriver as FormArray;
	}

	get discoverDriversFormControl(): FormArray {
		return this.form.controls.discoverDrivers as FormArray;
	}

	get manualDriversFormControl(): FormArray {
		return this.form.controls.manualDrivers as FormArray;
	}

	getValidDrivers(drivers) {
		return drivers.controls.filter(
			(driver) => driver && driver.value && driver.value.FirstName && driver.value.LastName
		);
	}

	getActiveDrivers(drivers) {
		return drivers.controls.filter((driver) => driver && driver.value && driver.value.active);
	}

	ngOnInit(): void {
		super.ngOnInit();
		this.initForm();

		this.store.dispatch(LoaderActions.Hideloader());
		this.driversService.removeDuplicatesDrivers();

		this.store
			.select(selectQuoteData)
			.pipe(first())
			.subscribe((quoteData) => {
				this.isDiscoverDrivers = !!quoteData.discoverDrivers.length;
				this.selectedState = quoteData.PropertyAddress.State;
				this.applicant = quoteData.FirstName + ' ' + quoteData.LastName;

				quoteData.Drivers?.forEach((item) => {
					if (item.SequenceNum == 0) {
						if (item) {
							const formGroupItem = this.createFormControl(item, 'primary');
							this.primaryDriverFormControl.push(formGroupItem);
							this.primaryDriverFormControl.markAllAsTouched();
						}
					} else {
						const formGroupItem = this.createFormControl(item, 'Drivers');
						this.discoverDriversFormControl.push(formGroupItem);
						this.discoverDriversFormControl.markAllAsTouched();
						this.registerChange(formGroupItem);
					}
				});

				quoteData.discoverDrivers.forEach((item) => {
					if (item) {
						const formGroupItem = this.createFormControl(item, 'discoverDrivers');
						this.discoverDriversFormControl.push(formGroupItem);
						this.discoverDriversFormControl.markAllAsTouched();
						this.registerChange(formGroupItem);
					}
				});
			});

		this.store
			.select(selectManualDrivers)
			.pipe(untilDestroyed(this))
			.subscribe((manualDrivers) => {
				if (!this.first) {
					manualDrivers?.forEach((item) => {
						if (item) {
							const formGroupItem = this.createFormControl(item, 'manualDrivers');
							this.manualDriversFormControl.push(formGroupItem);
							this.manualDriversFormControl.markAllAsTouched();
							this.registerChange(formGroupItem);
						}
					});
				} else {
					if (manualDrivers.length !== this.manualDriversFormControl.controls.length) {
						const formGroupItem = this.createFormControl(manualDrivers[manualDrivers.length - 1], 'manualDrivers');
						this.manualDriversFormControl.push(formGroupItem);
						this.manualDriversFormControl.markAllAsTouched();
						this.registerChange(formGroupItem);
					}
				}
			});

		this.quoteDataService.updateDriver.pipe(untilDestroyed(this)).subscribe((driverToUpdate) => {
			if (driverToUpdate) {
				if (driverToUpdate.source !== DriverSourceEnum.manual) {
					this.discoverDriversFormControl.controls[
						this.discoverDriversFormControl.value.findIndex((i) => i.Id == driverToUpdate.driver.data.Id)
					].patchValue(driverToUpdate?.driver?.data);
				} else {
					this.manualDriversFormControl.controls[
						this.manualDriversFormControl.value.findIndex((i) => i.Id == driverToUpdate.driver.data.Id)
					].patchValue(driverToUpdate?.driver?.data);
				}
			}
		});

		this.store
			.select(selectQuoteData)
			.pipe(untilDestroyed(this))
			.subscribe((quoteData) => {
				this.numActiveDrivers = 0;
				if (quoteData.Drivers) {
					quoteData.Drivers.forEach((Driver) => {
						if (Driver.active) {
							this.numActiveDrivers++;
						}
					});
				}
				if (quoteData.manualDrivers) {
					quoteData.manualDrivers.forEach((manualDriver) => {
						if (manualDriver.active) {
							this.numActiveDrivers++;
						}
					});
				}

				if (quoteData.discoverDrivers) {
					quoteData.discoverDrivers.forEach((discoverDriver) => {
						if (discoverDriver.active) {
							this.numActiveDrivers++;
						}
					});
				}
			});

		this.first = true;
	}

	initForm(): void {
		this.form = new FormGroup({
			primaryDriver: new FormArray([]),
			discoverDrivers: new FormArray([]),
			manualDrivers: new FormArray([]),
		});
		this.form.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
			this.validateSpouseCount(this.form);
		});
	}

	validateSpouseCount = (formControl) => {
		let formArray = [...formControl.controls.manualDrivers.controls, ...formControl.controls.discoverDrivers.controls];
		const controls = formArray.filter(
			(control) => control.get('DriverRelationshipToDriver1').value === 'Spouse' && control.get('active').value
		);
		if (controls.length > 1) {
			controls.forEach((control) => {
				setTimeout(() => {
					control.controls.DriverRelationshipToDriver1.setErrors({ duplicateSpouse: true });
					control.markAllAsTouched();
				}, 0);
			});
		} else {
			controls.forEach((control) => {
				setTimeout(() => {
					control.controls.DriverRelationshipToDriver1.setErrors(null);
				}, 0);
			});
		}
	};

	createFormControl(item, type?) {
		let data;
		let formGroupItem;

		// If driver is on policy data - it means inside Drivers and it doesn't have active attribute
		//so the active attribute is supposed to be true
		data = {
			Id: item.Id,
			SequenceNum: this.SequenceNum,
			FirstName: [item.FirstName, [Validators.required, Validators.maxLength(50), Validations.alphabet]],
			LastName: [item.LastName, [Validators.required, Validators.maxLength(50), Validations.alphabet]],
			DOB: [item.DOB, [Validators.required]],
			Gender: [item.Gender, [Validators.required]],
			MaritalStatus: [item.MaritalStatus, [Validators.required]],
			DriverLicenseStatus: [item.DriverLicenseStatus, [Validators.required]],
			DriverEmploymentIndustry: [item.DriverEmploymentIndustry, [Validators.required]],
			DriverOccupationStr: [item.DriverOccupationStr, [Validators.required]],
			DriverEducation: [item.DriverEducation, [Validators.required]],
			active: item.active,
			DriverDateLicensed: null,
			type: type,
		};

		if (type !== 'primary') {
			data.DriverRelationshipToDriver1 = [item.DriverRelationshipToDriver1, [Validators.required]];
		}

		if (type == 'primary' && !item.DOB) {
			this.store
				.select(selectQuoteData)
				.pipe(first())
				.subscribe((res) => {
					data.DOB = res.DateOfBirth;
				});
		}

		this.SequenceNum = this.SequenceNum + 1;

		formGroupItem = this.formBuilder.group(data) as FormGroup;

		if (['MA', 'DC', 'PA'].includes(this.selectedState)) {
			formGroupItem.controls?.DriverOccupationStr?.disable();

			if (this.selectedState === 'MA') {
				formGroupItem.controls?.DriverEducation?.disable();
			}
		}

		return formGroupItem;
	}

	handleContinue() {
		const primaryDriver = this.form.value.primaryDriver.map((item) => {
			return { Id: item.Id, SequenceNum: item.SequenceNum };
		});
		const discoverDrivers = this.formatDrivers(this.form.value.discoverDrivers);
		const manualDrivers = this.formatDrivers(this.form.value.manualDrivers);

		this.actionApiService
			.updateDriver({
				Drivers: { Drivers: primaryDriver, discoverDrivers, manualDrivers },
			})
			.subscribe((serverRes) => {
				if (serverRes) {
					this.quoteDataService.serverQuoteErrors.next(serverRes);
					if (
						serverRes.validationErrors &&
						serverRes.validationErrors.length &&
						this.quoteDataService.hasErrorsFromServerForThisPage.value
					) {
						this.nextButtonComponent.loading = false;
						this.setReproducibleErrors(serverRes, this.getAllValidDrivers());
						this.handleValidation(serverRes.validationErrors);
					} else {
						//update all discovered drivers default values
						const allDriversPD = serverRes.data.Drivers;
						const DiscoveredDrivers = this.form.get('discoverDrivers').value;

						const updatedActiveDiscoveredDrivers = DiscoveredDrivers.map((activeDriver) => {
							const matchingDriver = allDriversPD.find((driver) => driver.Id === activeDriver.Id);
							return matchingDriver ? { ...matchingDriver, ...activeDriver } : activeDriver;
						});

						this.store.dispatch(
							UpdateQuoteData({
								data: {
									discoverDrivers: updatedActiveDiscoveredDrivers,
								},
							})
						);
						this.store.dispatch(NavigateToNextPage(serverRes));
					}
				}
			});
	}

	setServerErrors(res) {
		super.setServerErrors(res);
		res.validationErrors.forEach((qError) => {
			const field = this.getDriverFormControlById(qError.id);
			if (field) {
				field.setErrors({ serverError: qError.errors[0] });
				field.markAsTouched();
			}
		});
	}

	getDriverFormControlById(id: string): FormControl | null {
		const reproducibleId = this.getReproducibleControlIdFromFieldId(id);
		const controlName = this.getReproducibleControlNameFromFieldId(id);
		const driverForm = this.getAllDrivers().find((control: FormControl) => control.value.Id === reproducibleId);

		return reproducibleId && controlName && driverForm ? driverForm.controls[controlName] : null;
	}

	addDriver() {
		this.store.dispatch(
			showPopupAction({
				componentName: 'driver-popup',
				payload: {
					text: 'popups.titles.driver-popup-add',
					type: 'add',
					max: this.numActiveDrivers === this.MAX_NUM_ACTIVE_DRIVERS,
					customClass: 'add-edit-vehicle',
					SequenceNum: this.SequenceNum,
					gtmLabel: 'CoveragePopUp',
					primaryDriverName: this.applicant,
					autoStateSpecificRules: this.d2CFeatures?.autoStateSpecificRules,
				},
			})
		);
		this.eventsService.addEvent(this.EventActions.BUTTON_CLICK, {
			label: 'add-driver',
			button: 'add-driver',
			module: 'additional-drivers-module',
		});
	}

	editDriver(item) {
		if (item.source === 'manualDrivers') {
			this.store.dispatch(EditManualDriver({ data: { ...item.data, SequenceNum: this.SequenceNum } }));
		} else {
			this.store.dispatch(EditDiscoveredDriver({ data: { ...item.data, SequenceNum: this.SequenceNum } }));
		}
	}

	ngAfterViewInit(): void {
		this.baseNgAfterViewInit();
	}

	formatDrivers(res) {
		return res.map((i) => {
			let driver = { ...i };
			delete driver.type;
			return { ...driver, DOB: this.formattedDateService.formatDateIfItIsNotMasked(i.DOB, this.DATE_FORMAT) };
		});
	}

	checkValid() {
		let ret;
		this.getActiveDrivers({ controls: this.getValidDrivers(this.discoverDriversFormControl) })
			.concat(this.getActiveDrivers(this.manualDriversFormControl))
			.map((i) => {
				return i.valid;
			})
			.includes(false)
			? (ret = true)
			: (ret = false);

		return ret;
	}

	private registerChange(form: FormGroup): void {
		// after any change mark as not new
		form.valueChanges.pipe(first()).subscribe(() => {
			form.patchValue({ newListing: false });
		});
	}

	private getAllDrivers() {
		return this.getActiveDrivers(this.discoverDriversFormControl).concat(
			this.getActiveDrivers(this.manualDriversFormControl)
		);
	}

	private getAllValidDrivers() {
		return this.getValidDrivers(this.discoverDriversFormControl).concat(
			this.getValidDrivers(this.manualDriversFormControl)
		);
	}
}
