import { AfterViewInit, Component, Injector, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Fields } from 'src/app/constants/fields';
import { ActionApiService } from 'src/app/services/action-api.service';
import { QuoteDataService } from 'src/app/services/quote-data.service';
import {
	selectDiscoverVehicles,
	selectManualVehicles,
	selectPersonalVehicles,
	selectQuoteData,
} from 'src/app/store/selectors/quote-data.selectors';
import { IAppState } from 'src/app/store/states/app.state';
import { BasePageComponent } from '../../base-page.component';
import { first, skip } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { untilDestroyed } from '@ngneat/until-destroy';
import { duplicateObjectWithoutReadOnly } from '../../../../../utils/general.utils';

const FilteredStates = ['TX', 'MN', 'NC', 'UT', 'VA'];

@Component({
	selector: 'app-auto-coverages',
	templateUrl: './auto-coverages.component.html',
	styleUrls: ['./auto-coverages.component.scss'],
})
export class AutoCoveragesComponent extends BasePageComponent implements OnInit, AfterViewInit {
	phoneNumberMask = '999-999-9999';
	actionApiService: ActionApiService;
	internalStep: number = 1;
	typeOptions = 'DefaultBIOptions';
	SelectPriorLiabilityLimitsAutoChanged: boolean = false;
	first = true;

	constructor(
		actionApiService: ActionApiService,
		injector: Injector,
		protected quoteDataService: QuoteDataService,
		protected store: Store<IAppState>
	) {
		super(injector, store);
		this.actionApiService = actionApiService;
	}

	get PersonalVehiclesFormArray(): FormArray {
		return this.form.controls['PersonalVehicles'] as FormArray;
	}

	get vehiclesFormArray(): FormArray {
		return this.form.controls['manualVehicles'] as FormArray;
	}

	get discoverdFormArray(): FormArray {
		return this.form.controls['discoverVehicles'] as FormArray;
	}

	ngAfterViewInit(): void {
		this.baseNgAfterViewInit();
	}

	ngOnInit(): void {
		super.ngOnInit();
		this.initForm();
		this.patchData(this.form);
		this.registerOnChange(this.form, true);

		combineLatest([
			this.store.select(selectPersonalVehicles),
			this.store.select(selectDiscoverVehicles),
			this.store.select(selectManualVehicles),
		])
			.pipe(untilDestroyed(this))
			.subscribe(([PersonalVehicles, discoverVehicles, manualVehicles]) => {
				if (this.first) {
					// don't display vehicles from PersonalVehicles, if they were added manually by user, or discovered
					PersonalVehicles = duplicateObjectWithoutReadOnly(PersonalVehicles);

					PersonalVehicles = PersonalVehicles.filter((personalVehicle) => {
						return (
							!discoverVehicles?.find((vehicle) => personalVehicle.Id === vehicle.Id) &&
							!manualVehicles?.find((vehicle) => personalVehicle.Id === vehicle.Id)
						);
					});

					PersonalVehicles?.forEach((item) => {
						if (item && item.SequenceNum !== -1) {
							const formGroupItem = this.createFormGroup(item);
							this.PersonalVehiclesFormArray.push(formGroupItem);
							this.registerChange(formGroupItem);
						}
					});

					discoverVehicles?.forEach((item) => {
						if (item && item.SequenceNum !== -1) {
							const formGroupItem = this.createFormGroup(item);
							this.discoverdFormArray.push(formGroupItem);
							this.registerChange(formGroupItem);
						}
					});

					manualVehicles?.forEach((item) => {
						if (item && item.SequenceNum !== -1) {
							const formGroupItem = this.createFormGroup(item);
							this.vehiclesFormArray.push(formGroupItem);
							this.registerChange(formGroupItem);
						}
					});
				} else {
					this.PersonalVehiclesFormArray.patchValue(PersonalVehicles);
					this.discoverdFormArray.patchValue(discoverVehicles);

					if (manualVehicles.length !== this.vehiclesFormArray.controls.length) {
						const formGroupItem = this.createFormGroup(manualVehicles[manualVehicles.length - 1]);
						this.vehiclesFormArray.push(formGroupItem);
						this.vehiclesFormArray.markAllAsTouched();
						this.registerChange(formGroupItem);
					} else {
						this.vehiclesFormArray.patchValue(manualVehicles);
					}
				}

				this.first = false;
			});

		this.store
			.select(selectQuoteData)
			.pipe(first())
			.subscribe((res) => {
				if (FilteredStates.includes(res.PropertyAddress.State)) {
					this.typeOptions = 'FilteredBIOptions';
				}
			});
	}

	initForm(): void {
		this.form = new FormGroup({
			PersonalVehicles: new FormArray([]),
			discoverVehicles: new FormArray([]),
			manualVehicles: new FormArray([]),
			[Fields.BodilyInjuryliability.name]: new FormControl({ value: null, disabled: false }, [Validators.required]),
		});

		this.form.controls[Fields.BodilyInjuryliability.name].valueChanges.pipe(skip(1)).subscribe((value) => {
			this.SelectPriorLiabilityLimitsAutoChanged = true;
		});
	}

	createFormGroup(item) {
		let data;
		let formGroupItem;

		data = {
			Id: item['Id'],
			SequenceNum: item['SequenceNum'],
			PLYear: [item['PLYear'], [Validators.required]],
			PLMake: [item['PLMake'], [Validators.required]],
			PLModel: [item['PLModel'], [Validators.required]],
			newListing: [item.newListing],
			enabled: [item.enabled],
		};

		if (item.enabled) {
			data.CompDeductible = [item['CompDeductible'] || null, [Validators.required]];
			data.CollDeductible = [item['CollDeductible'] || null, [Validators.required]];
		}

		formGroupItem = this.formBuilder.group(data) as FormGroup;
		return formGroupItem;
	}

	navigateInternal() {
		if (this.internalStep == 1) {
			this.internalStep = 2;
		} else {
			this.internalStep = 1;
		}
		this.eventsService.addEvent(this.EventActions.BUTTON_CLICK, {
			label: 'go-back',
			button: 'go-back-auto-coverage',
			module: 'auto-coverage-module',
		});
	}

	handleContinue() {
		//get only the relevant
		let PersonalVehicles = [];
		let discoverVehicles = [];
		let manualVehicles = [];

		this.form.value.PersonalVehicles.forEach((item) => {
			if (item.enabled) {
				PersonalVehicles.push(
					this.addDefaults({
						Id: item.Id,
						CollDeductible: item.CollDeductible,
						CompDeductible: item.CompDeductible,
						SequenceNum: item.SequenceNum,
					})
				);
			}
		});

		this.form.value.discoverVehicles.forEach((item) => {
			if (item.enabled) {
				discoverVehicles.push(
					this.addDefaults({
						Id: item.Id,
						CollDeductible: item.CollDeductible,
						CompDeductible: item.CompDeductible,
						SequenceNum: item.SequenceNum,
					})
				);
			}
		});

		this.form.value.manualVehicles.forEach((item) => {
			if (item.enabled) {
				manualVehicles.push(
					this.addDefaults({
						Id: item.Id,
						CollDeductible: item.CollDeductible,
						CompDeductible: item.CompDeductible,
						SequenceNum: item.SequenceNum,
					})
				);
			}
		});

		let data = {
			BI: this.form.value.BI,
			UIM: null,
			UM: null,
			PD: null,
			PersonalVehicles,
			discoverVehicles,
			manualVehicles,
		};

		//add SelectPriorLiabilityLimitsAuto default to null once BI value changed
		if (this.SelectPriorLiabilityLimitsAutoChanged) {
			data['SelectPriorLiabilityLimitsAuto'] = null;
		}

		this.actionApiService.updateApplication(data).subscribe((res) => {
			this.progressMeterService.goToNextPage();
		});
	}

	addDefaults(obj): any {
		return {
			...obj,
			TowingAndLabor: null,
			TransportationExpense: null,
			FullGlass: null,
		};
	}

	private registerChange(form: FormGroup): void {
		// after any change mark as not new
		form.valueChanges.pipe(first()).subscribe(() => {
			form.patchValue({ newListing: false });
		});
	}
}
