import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, RoutesRecognized } from '@angular/router';

import { Store } from '@ngrx/store';
import { first } from 'rxjs/operators';

import { selectProgressMeter, selectProgressMeterSteps } from '../store/selectors/progress-meter.selector';
import { IProgressMeter, IStep } from '../store/states/progress-meter.state';
import { IQuoteDataState } from '../store/states/quote-data.state';
import { selectQuoteData } from '../store/selectors/quote-data.selectors';
import { SessionStorageService } from './session-storage.service';
import { selectIsQuoteCompleted } from '../store/selectors/result-data.selectors';
import { RoutingService } from './routing.service';
import { AppService } from './app.service';
import { selectIsConfirmationEmailSentDisplayed } from '../store/selectors/interview-metadata.selector';
import { StepsEnum } from '../enums/steps.enum';
import { ProgressMeterService } from './progress-meter.service';
import { LobService } from './lob.service';
import { ActivateStep } from '../store/actions/progress-meter.actions';
import { setIsQuoteCompleted } from '../store/actions/result-data.actions';

@Injectable({
	providedIn: 'root',
})
export class AuthGuardService  {
	steps: IStep[];
	progressMeterState: IProgressMeter;
	quoteData: IQuoteDataState;
	isQuoteCompleted: boolean;
	isConfirmationEmailSentDisplayed: boolean;

	constructor(
		public router: Router,
		private store: Store,
		private sessionStorage: SessionStorageService,
		private routingService: RoutingService,
		private appService: AppService,
		private progressMeterService: ProgressMeterService,
		private lobService: LobService,
	) {
		this.store
			.select(selectProgressMeter)
			.pipe()
			.subscribe((progressMeterState) => {
				this.progressMeterState = progressMeterState;
			});

		this.store.select(selectQuoteData).subscribe((quoteData) => {
			this.quoteData = quoteData;
		});

		this.store.select(selectIsQuoteCompleted).subscribe((isQuoteCompleted) => {
			this.isQuoteCompleted = isQuoteCompleted;
		});

		this.store.select(selectIsConfirmationEmailSentDisplayed).subscribe((isConfirmationEmailSentDisplayed) => {
			this.isConfirmationEmailSentDisplayed = isConfirmationEmailSentDisplayed;
		});
	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		if (this.isQuoteCompleted) {
			this.appService.clearData();
			this.store.dispatch(setIsQuoteCompleted({ data: false }));
			this.routingService.navigateToHomePage();
			return false;
		}

		if (!this.sessionStorage.getItem('state')) {
			this.routingService.navigateToHomePage();
			return false;
		}

		let currRoute = state.url.toLowerCase().replace('/', '').split('?')[0];
		const routeParams = currRoute.split('/');
		const lob = routeParams[routeParams.length - 2];
		if (lob && this.progressMeterService.isLobValid(lob)) {
			this.steps = this.progressMeterState.flowOptions[this.progressMeterService.getFlowType(lob)];
		} else {
			this.steps = this.progressMeterState.flowOptions[this.progressMeterState.flowType || 'init'];
		}

		if (!currRoute) {
			currRoute = this.steps[0].name;
		}

		if (this.isConfirmationEmailSentDisplayed && currRoute !== this.steps[StepsEnum.CONFIRMATION_EMAIL_SENT]) {
			this.navigateConfirmationEmailSent();
			return false;
		}

		const stepRequested = this.steps.find((st) => st.name === currRoute.split('/')[currRoute.split('/').length - 1]);

		if (stepRequested && !stepRequested.visited) {
			const visitedSteps = this.steps.filter((st) => st.visited);
			const updatedRoute = visitedSteps.length > 0 ? visitedSteps[visitedSteps.length - 1].name : currRoute;
			// if step is without in routing don't pass it ie /home/your-address
			const route = lob && this.progressMeterState.flowOptions.init.some((option) => option.name === updatedRoute) ? updatedRoute : `${lob}/${updatedRoute}`;
			this.routingService.navigateToRoute(route);
			return false;
		}

		return true;
	}

	navigateConfirmationEmailSent() {
		this.store.dispatch(
			ActivateStep({
				stepName: StepsEnum.CONFIRMATION_EMAIL_SENT,
			})
		);

		this.lobService
			.getLob$()
			.pipe(first())
			.subscribe((lob) => {
				this.routingService.navigateToRoute(
					this.progressMeterService.getRouteName(lob) +
					'/' +
					StepsEnum.CONFIRMATION_EMAIL_SENT
				);
			});
	}
}
