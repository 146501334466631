import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';

import { Store } from '@ngrx/store';
import { first } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';

import { Validations } from 'src/app/utils/validation';
import { QuoteDataService } from 'src/app/services/quote-data.service';
import { IAppState } from 'src/app/store/states/app.state';
import {
	selectDiscoverVehicles,
	selectManualVehicles,
	selectPersonalVehicles,
} from 'src/app/store/selectors/quote-data.selectors';
import { ActionApiService } from 'src/app/services/action-api.service';
import { inOutAnimation } from 'src/app/animations';
import { BasePageComponent } from '../../base-page.component';
import { showPopupAction } from '../../../../../store/actions/popup.actions';
import { untilDestroyed } from '@ngneat/until-destroy';
import { NextButtonComponent } from '../../../../next-button/next-button.component';
import { combineLatest } from 'rxjs';
import { duplicateObjectWithoutReadOnly } from 'src/app/utils/general.utils';
import { MergeQuoteData } from '../../../../../store/actions/quote-data.actions';

const MAX_NUMBER_OF_VEHICLES = 4;

@Component({
	selector: 'app-vehicles',
	templateUrl: './vehicles.component.html',
	styleUrls: ['./vehicles.component.scss'],
	animations: [inOutAnimation],
})
export class VehiclesPageComponent extends BasePageComponent implements OnInit {
	@ViewChild(NextButtonComponent, { static: true }) nextButtonComponent: NextButtonComponent;
	actionApiService: ActionApiService;
	SequenceNum = 0;
	fieldsNames = {
		AddressLine1: 'PersonalVehicleGarageAddress.AddressLine1',
		AddressLine2: 'PersonalVehicleGarageAddress.AddressLine2',
		City: 'PersonalVehicleGarageAddress.City',
		State: 'PersonalVehicleGarageAddress.State',
		ZipCode: 'PersonalVehicleGarageAddress.ZipCode',
		County: 'PersonalVehicleGarageAddress.County',
	};
	primaryVehicleFlag = false;
	isLoading = false;
	showMaxVehiclesError = false;
	first = true;
	allVins = [];

	constructor(
		injector: Injector,
		protected quoteDataService: QuoteDataService,
		protected store: Store<IAppState>,
		actionApiService: ActionApiService
	) {
		super(injector, store, quoteDataService);
		this.actionApiService = actionApiService;
	}

	get oemEndorsement(): boolean {
		return this.d2CFeatures?.oemEndorsement || null;
	}

	get vehiclesFormControl(): FormArray {
		return this.form.controls.PersonalVehicles as FormArray;
	}

	get discoverVehiclesFormControl(): FormArray {
		return this.form.controls.discoverVehicles as FormArray;
	}

	get manualVehiclesFormControl(): FormArray {
		return this.form.controls.manualVehicles as FormArray;
	}

	ngOnInit(): void {
		super.ngOnInit();
		this.initForm();

		combineLatest([
			this.store.select(selectPersonalVehicles),
			this.store.select(selectDiscoverVehicles),
			this.store.select(selectManualVehicles),
		])
			.pipe(untilDestroyed(this))
			.subscribe(([PersonalVehicles, discoverVehicles, manualVehicles]) => {
				// don't display vehicles from PersonalVehicles, if they were added manually by user, or discovered
				PersonalVehicles = duplicateObjectWithoutReadOnly(PersonalVehicles);

				PersonalVehicles = PersonalVehicles.filter((personalVehicle) => {
					return (
						!discoverVehicles?.find((vehicle) => personalVehicle.Id === vehicle.Id) &&
						!manualVehicles?.find((vehicle) => personalVehicle.Id === vehicle.Id)
					);
				});
				if (this.first) {
					this.primaryVehicleFlag = this.d2CFeatures?.primaryVehicle;
					PersonalVehicles?.forEach((item, index) => {
						if (this.primaryVehicleFlag && index === 0 && item.VIN) {
							item.primary = true;
						}
						const formGroupItem = this.createFormControl(item, 'PersonalVehicles');
						this.vehiclesFormControl.push(formGroupItem);
						this.vehiclesFormControl.markAllAsTouched();
						this.registerChange(formGroupItem);
					});

					discoverVehicles?.forEach((item) => {
						const formGroupItem = this.createFormControl(item, 'discoverVehicles');
						this.discoverVehiclesFormControl.push(formGroupItem);
						this.discoverVehiclesFormControl.markAllAsTouched();
						this.registerChange(formGroupItem);
					});

					manualVehicles?.forEach((item) => {
						const formGroupItem = this.createFormControl(item, 'manualVehicles');
						this.manualVehiclesFormControl.push(formGroupItem);
						this.manualVehiclesFormControl.markAllAsTouched();
						this.registerChange(formGroupItem);
					});

					if (this.primaryVehicleFlag && !!PersonalVehicles[0]?.VIN && !PersonalVehicles[0]?.NumberOfMiles) {
						this.openPrimaryVehiclePopup(PersonalVehicles[0]);
					}
				} else {
					this.vehiclesFormControl.patchValue(PersonalVehicles);
					this.discoverVehiclesFormControl.patchValue(discoverVehicles);

					if (manualVehicles.length !== this.manualVehiclesFormControl.controls.length) {
						const formGroupItem = this.createFormControl(manualVehicles[manualVehicles.length - 1], 'manualVehicles');
						this.manualVehiclesFormControl.push(formGroupItem);
						this.manualVehiclesFormControl.markAllAsTouched();
						this.registerChange(formGroupItem);
					} else {
						this.manualVehiclesFormControl.patchValue(manualVehicles);
					}
				}
			});

		this.first = false;
	}

	initForm(): void {
		this.form = new FormGroup({
			PersonalVehicles: new FormArray([]),
			discoverVehicles: new FormArray([]),
			manualVehicles: new FormArray([]),
		});
	}

	registerChange(form: FormGroup) {
		onChangeMilesToWork(form.value.PrimaryUseOfVehicle);

		function onChangeMilesToWork(useOfVehicle) {
			if (useOfVehicle === 'To_FromWork' || useOfVehicle === 'To_FromSchool') {
				form.addControl(
					'MilesToWork',
					new FormControl({ value: null, disabled: false }, [Validators.required, Validations.numbersdDigits])
				);
			} else {
				form.removeControl('MilesToWork');
			}
		}

		form.controls.PrimaryUseOfVehicle?.valueChanges.subscribe((useOfVehicle) => {
			onChangeMilesToWork(useOfVehicle);
		});

		// after any change mark as not new
		form.valueChanges.pipe(first()).subscribe(() => {
			form.patchValue({ newListing: false });
			this.showMaxVehiclesError = false;
		});
	}

	getActiveFormErrors() {
		const errors = [];
		this.getActiveFields(this.vehiclesFormControl).forEach((item) => {
			const error = this.getAllErrors(item);
			if (error) {
				errors.push({ error });
			}
		});
		this.getActiveFields(this.discoverVehiclesFormControl).forEach((item) => {
			const error = this.getAllErrors(item);
			if (error) {
				errors.push({ error });
			}
		});
		this.getActiveFields(this.manualVehiclesFormControl).forEach((item) => {
			const error = this.getAllErrors(item);
			if (error) {
				errors.push({ error });
			}
		});
		return errors;
	}

	isNoVehiclesFound(): boolean {
		return (
			!this.getActiveFields(this.vehiclesFormControl).length &&
			!this.getActiveFields(this.discoverVehiclesFormControl).length &&
			!this.getActiveFields(this.manualVehiclesFormControl).length
		);
	}

	isNoDiscoverVehiclesFound(): boolean {
		return !this.getActiveFields(this.discoverVehiclesFormControl).length;
	}

	createFormControl(item, type): FormGroup {
		let data;
		// create empty item for the server to delete
		if (item.SequenceNum === -1) {
			data = {
				Id: item.Id,
				SequenceNum: -1,
				VIN: null,
				PLYear: null,
				PLMake: null,
				PLModel: null,
				OEMCoverage: this.oemEndorsement,
				OwnershipType: null,
				WasTheCarNew: null,
				DateVehiclePurchased: null,
				PrimaryUseOfVehicle: null,
				MilesToWork: null,
				NumberOfMiles: null,
				newListing: false,
				BodyStyle: '',
				CompDeductible: '',
				CollDeductible: '',
			};
			return this.formBuilder.group(data);
		}

		const id = uuidv4();

		// if adding field to this need to update auto-coverages.component.ts createFormGroup too
		data = {
			Id: item.Id || id,
			SequenceNum: this.SequenceNum++,
			VIN: [item.VIN || '', [Validations.validateVINduplicate]],
			PLYear: [item.PLYear, [Validators.required]],
			PLMake: [item.PLMake, [Validators.required]],
			PLModel: [item.PLModel, [Validators.required]],
			OEMCoverage: [this.oemEndorsement || item.OEMCoverage],
			DateVehiclePurchased: [{ value: '', disabled: true }],
			NumberOfMiles: [item.NumberOfMiles, [Validators.required]],
			MilesToWork: [{ value: '', disabled: true }],
			newListing: true,
			BodyStyle: [item.BodyStyle, [Validators.required]],
			enabled: [item.enabled],
			primary: [item.primary],
			CompDeductible: [item.CompDeductible || null],
			CollDeductible: [item.CollDeductible || null],
			type: [type],
			OwnershipType: [item.OwnershipType || null, [Validators.required]],
		};

		this.allVins.push(item.VIN);

		if (item.ExternalVINPrefix) {
			data.ExternalVINPrefix = item.ExternalVINPrefix;
		}

		if (item.PrimaryUseOfVehicle === 'To_FromWork' || item.PrimaryUseOfVehicle === 'To_FromSchool') {
			data.MilesToWork = [item.MilesToWork, [Validators.required, Validations.numbersdDigits]];
		}

		const formGroupItem = this.formBuilder.group(data) as FormGroup;
		if (!item.newListing) {
			formGroupItem.markAllAsTouched();
		}

		return formGroupItem;
	}

	getActiveFields(form) {
		if (form.controls.length === 0) {
			return [];
		}
		return form.controls?.filter((item) => item.value.SequenceNum > -1);
	}

	cantAdd(): boolean {
		return (
			[
				...this.getActiveFields(this.vehiclesFormControl),
				...this.getActiveFields(this.discoverVehiclesFormControl),
				...this.getActiveFields(this.manualVehiclesFormControl),
			].filter((vehicleFormGroup) => vehicleFormGroup.value.enabled).length >= MAX_NUMBER_OF_VEHICLES
		);
	}

	addVehicle() {
		if (this.cantAdd()) {
			this.showMaxVehiclesError = true;
			return;
		} else {
			this.showMaxVehiclesError = false;
		}
		this.eventsService.addEvent(this.EventActions.BUTTON_CLICK, {
			label: 'add-another-car',
			button: 'add-another-car',
			module: 'vehicles-module',
		});
		this.store.dispatch(
			showPopupAction({
				componentName: 'add-edit-vehicle',
				payload: {
					text: 'popups.titles.add-edit-vehicle-add',
					customClass: 'add-edit-vehicle',
					SequenceNum: this.SequenceNum,
				},
			})
		);
	}

	continue() {
		this.isLoading = true;
		const errors = this.getActiveFormErrors();
		if (errors && Object.keys(errors).length > 0) {
			const errorElement: any = document.querySelector('.error');
			if (errorElement) {
				// scroll to element
				errorElement.closest('.accordion-block').dataset.status = 'closed';
				this.handleEdit(errorElement.closest('.accordion-block'));
				errorElement.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
			}
			this.isLoading = false;
		}
	}

	handleEdit(elem) {
		const currentState = elem.dataset.status || null;
		const list = document.getElementsByClassName('accordion-block');
		Array.from(list).forEach((el: any) => {
			el.classList.remove('show');
			el.dataset.status = 'closed';
		});

		if (elem.dataset.status === null || currentState === 'closed') {
			elem.classList.add('show');
			elem.dataset.status = 'open';
		} else {
			elem.classList.remove('show');
			elem.dataset.status = 'closed';
		}
	}

	getAllErrors(form: FormGroup | FormArray): { [key: string]: any } | null {
		let hasError = false;
		const result = Object.keys(form.controls).reduce((acc, key) => {
			const control = form.controls[key];
			const errors =
				control instanceof FormGroup || control instanceof FormArray ? this.getAllErrors(control) : control.errors;
			// will show tooltip if there is errors
			if (key === 'newListing') {
				control.patchValue(false);
			}
			if (errors) {
				control.markAllAsTouched();
				acc[key] = errors;
				hasError = true;
			}
			return acc;
		}, {} as { [key: string]: any });
		return hasError ? result : null;
	}

	isAtLeastOneVehicleEnabled(): boolean {
		for (const formArrayName of Object.keys(this.form.controls)) {
			const formArray = this.form.controls[formArrayName] as FormArray;
			for (const key of Object.keys(formArray.controls)) {
				if (formArray.controls[key].value.enabled === true) {
					return true;
				}
			}
		}
		return false;
	}

	handleContinue() {
		const PersonalVehicles = this.modifyVehiclesPayload([
			...this.vehiclesFormControl.value,
			...this.manualVehiclesFormControl.value,
			...this.discoverVehiclesFormControl.value,
		]);

		// Updates the quote
		this.actionApiService
			// .updateApplication({ PersonalVehicles, prefillIndication: this.getPrefillIndicationPayload(PersonalVehicles) })
			.updateApplication({ PersonalVehicles })
			.pipe(first())
			.subscribe((serverRes) => {
				if (serverRes) {
					this.quoteDataService.serverQuoteErrors.next(serverRes);
					this.onUpdateApplicationResult(serverRes);
					this.store.dispatch(MergeQuoteData({ data: serverRes['data'], deleteOnEmpty: false }));
				}
			});
	}

	modifyVehiclesPayload(vehiclesData) {
		return vehiclesData.map(({ enabled, newListing, ...vehicleDataItem }) => {
			if (!enabled) {
				vehicleDataItem.SequenceNum = -1;
			}

			if (vehicleDataItem.VIN) {
				vehicleDataItem.ExternalVINPrefix = null;
			}

			delete vehicleDataItem.type;
			return vehicleDataItem;
		});
	}

	getPrefillIndicationPayload(vehiclesData) {
		return vehiclesData.flatMap((vehicleDataItem) => {
			const prefillIndicationKeys = ['OwnershipType', 'PrimaryUseOfVehicle'];

			return prefillIndicationKeys.map((prefillKey) => ({
				id: `PersonalVehicles[?(@Id=='${vehicleDataItem.Id}')].${prefillKey}`,
				source: 'Defaults',
			}));
		});
	}

	checkValid() {
		const per = this.vehiclesFormControl.controls;
		const per2 = this.discoverVehiclesFormControl.controls;
		const per3 = this.manualVehiclesFormControl.controls;
		const allVehicles = [].concat(per2).concat(per3).concat(per);
		const filter = allVehicles.filter((i) => {
			if (i.value.enabled && !i.valid) {
				return i;
			}
		});

		return !filter.length;
	}

	openPrimaryVehiclePopup(vehicle) {
		this.store.dispatch(
			showPopupAction({
				componentName: 'primary-vehicle',
				payload: {
					text: `Tell us about your ${vehicle.PLMake}`,
					customClass: 'primary-vehicle',
					gtmLabel: 'PrimaryVehiclePopUp',
					Id: vehicle.Id,
					value: vehicle,
				},
			})
		);
	}
}
