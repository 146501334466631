import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import moment from 'moment';
import { untilDestroyed } from '@ngneat/until-destroy';
import { IDropdownOption } from 'src/app/components/form-controls/form-control-interfaces';
import { Dictionary } from 'src/entities/key-value.interface';
import { Location } from '@angular/common';
import { Validations } from 'src/app/utils/validation';
import { UpdateQuoteData } from 'src/app/store/actions/quote-data.actions';
import { NextButtonComponent } from 'src/app/components/next-button/next-button.component';
import { ActionApiService } from 'src/app/services/action-api.service';
import { BasePageComponent } from '../../../../../base-page.component';
import { QuoteDataService } from 'src/app/services/quote-data.service';
import { IAppState } from 'src/app/store/states/app.state';
import { selectQuoteData } from 'src/app/store/selectors/quote-data.selectors';
import { StepsEnum } from 'src/app/enums/steps.enum';
import { combineLatest } from 'rxjs';
import { CarriersEnum } from 'src/app/enums/carriers.enum';
import { LobsEnum } from 'src/app/enums/lobs.enum';

@Component({
	selector: 'app-safeco-drivers',
	templateUrl: './safeco-drivers.component.html',
	styleUrls: ['./safeco-drivers.component.scss'],
})
export class SafecoDriversComponent extends BasePageComponent implements OnInit {
	@ViewChild(NextButtonComponent) nextButtonComponent: NextButtonComponent;
	actionApiService: ActionApiService;
	isLoading: any;
	apiPrebindInterval;

	drivers = [];
	activeTabIndex = 0;
	index = 0;
	StepsEnum = StepsEnum;
	preBindProcess: boolean;
	showPreBindLoader = false;

	yearBeforeDate = moment().subtract(1, 'year').format('YYYY-MM-DD');

	constructor(
		injector: Injector,
		protected quoteDataService: QuoteDataService,
		protected store: Store<IAppState>,
		actionApiService: ActionApiService,
		private location: Location
	) {
		super(injector, store, quoteDataService);
		this.actionApiService = actionApiService;
	}

	get driversFormControl(): FormArray {
		return this.form.controls.additionalQuestionsSafecoDrivers as FormArray;
	}



	ngOnInit(): void {
		super.ngOnInit();
		this.initForm();

		setTimeout(() => {
			this.patchData(this.form);
			this.registerOnChange(this.form);

			this.store
				.select(selectQuoteData)
				.pipe(first())
				.subscribe((quoteData) => {
					const driverHistory =
						quoteData.additionalQuestionsSafecoDrivers && quoteData.additionalQuestionsSafecoDrivers.length
							? quoteData.additionalQuestionsSafecoDrivers
							: [];

					const discoverDrivers =
						quoteData.discoverDrivers && quoteData.discoverDrivers.length
							? quoteData.discoverDrivers.filter((driver) => driver.active == true)
							: [];

					const manualDrivers =
						quoteData.manualDrivers && quoteData.manualDrivers.length
							? quoteData.manualDrivers.filter((driver) => driver.active == true)
							: [];

					this.drivers = [...quoteData.Drivers, ...discoverDrivers, ...manualDrivers];
					this.drivers.forEach((driver, index) => {
						const driverHistoryItem = driverHistory.find((item) => item.Id === driver.Id);
						this.driversFormControl.push(
							this.createDriverForm(
								driverHistoryItem ? driverHistoryItem : driver,
								this.getDOBforDriver(driver.Id, quoteData)
							)
						);
					});
				});
		});

		this.interviewMetadata$.pipe(untilDestroyed(this)).subscribe((data) => {
			this.showPreBindLoader = data.preBindProcess;
		});
	}

	isTabActive(index: number): boolean {
		return index === this.activeTabIndex;
	}

	createDriverForm(driver, DOB): FormGroup {
		let formGroup = this.formBuilder.group({
			Id: driver.Id,
			SequenceNum: driver.SequenceNum ? driver.SequenceNum : 0,
			FirstName: driver.FirstName,
			LastName: driver.LastName,
			DriverLicenseNumber: [
				driver.DriverLicenseNumber ? driver.DriverLicenseNumber : null,
				[Validators.required, Validations.alphabetAndDigitsAnd],
			],
			DriversLicenseBeenSuspendedOrRevoked: [
				typeof driver.DriversLicenseBeenSuspendedOrRevoked == 'boolean'
					? driver.DriversLicenseBeenSuspendedOrRevoked
					: null,
				[Validators.required],
			],
			DriverStateLicensed: [driver.DriverStateLicensed ? driver.DriverStateLicensed : null, [Validators.required]],
			DriverLicenseStatus: [driver.DriverLicenseStatus ? driver.DriverLicenseStatus : null],
			SR22OrFinancialResponsibilityStatement: [
				typeof driver.SR22OrFinancialResponsibilityStatement == 'boolean'
					? driver.SR22OrFinancialResponsibilityStatement
					: null,
				[Validators.required],
			],

			confirmed: driver.confirmed || false,
		}) as FormGroup;


		//add DateFirstLicensed
		if (formGroup.controls.DriverLicenseStatus.value !== 'NotLicensed') {
			formGroup.addControl(
				'DriverDateLicensed',
				new FormControl(driver.DriverDateLicensed ? moment(driver.DriverDateLicensed).format('MM/DD/YYYY') : null, [
					Validators.required,
					Validations.dateFormat,
				])
			);
		}


		return formGroup;
	}

	getDriverHistoryForm(index: number) {
		return this.driversFormControl.controls[index] as FormGroup;
	}

	initForm(): void {
		this.form = new FormGroup({
			additionalQuestionsSafecoDrivers: new FormArray([]),
		});
	}

	activateNextTab() {
		const nextTabIndex = this.activeTabIndex + 1;
		if (nextTabIndex < this.drivers.length) {
			this.activeTabIndex = nextTabIndex;
			this.eventsService.addEvent(this.EventActions.BUTTON_CLICK, {
				label: 'go-forward',
				button: 'go-forward-driver-history',
				module: 'driver-history-module',
			});
		}
	}

	goBack() {
		if (this.activeTabIndex > 0) {
			this.activeTabIndex = this.activeTabIndex - 1;
			this.eventsService.addEvent(this.EventActions.BUTTON_CLICK, {
				label: 'go-back',
				button: 'go-back-safeco-drivers',
				module: 'safeco-drivers-module',
			});
		} else {
			this.location.back();
		}
	}

	activateTab(index: number) {
		this.activeTabIndex = index;
	}

	isLastTabActive(): boolean {
		return this.activeTabIndex === this.drivers.length - 1;
	}

	handleContinue() {
		//check if SR22OrFinancialResponsibilityStatement = true for any driver,
		//if it is, contact agent page is displayed
		if (this.getDriverHistoryForm(this.activeTabIndex).value.SR22OrFinancialResponsibilityStatement) {
			this.navigateToContactAgent();
			return;
		}

		const drivers = this.driversFormControl.value.map((driver) => {
			delete driver.confirmed;
			return driver;
		});

		combineLatest([
			this.interviewMetadata$,
			this.actionApiService.updateApplication(
				{ Drivers: drivers },
				[LobsEnum.PERSONAL_AUTO],
				CarriersEnum.SafecoInsurance
			),
		])
			.pipe(untilDestroyed(this))
			.subscribe(([interviewMetadata, serverRes]) => {
				this.store.dispatch(UpdateQuoteData({ data: { additionalQuestionsSafecoDrivers: serverRes['data'].Drivers } }));

				this.showPreBindLoader = false;
				this.onUpdateApplicationResult(serverRes, this.driversFormControl.controls as FormGroup[], true)
					.pipe(first())
					.subscribe((invalidReproducibleIndex) => {
						if (this.quoteDataService.hasErrorsFromServerForThisPage.value) {
							this.activeTabIndex = invalidReproducibleIndex;
						} else if (!interviewMetadata.preBindProcess) {
							this.progressMeterService.goToNextPage();
						}
					});
			});
	}

	getDOBforDriver(Id, quoteData) {
		let foundDriver = quoteData.Drivers.concat(quoteData.manualDrivers)
			.concat(quoteData.discoverDrivers)
			.find((item) => item.Id == Id);
		return foundDriver.DOB;
	}





	private navigateToContactAgent() {
		this.routingService.navigateToRoute(this.StepsEnum.CARRIER_KICKOUT);
	}
}
