<section class="main-content">
	<app-page-heading subtitleKey="safetyAlarms.subTitle" titleKey="safetyAlarms.title"></app-page-heading>

	<form [formGroup]="form" class="all-questions-wrapper bigger">
		<div [class.with-sprinkler]="form.controls['SprinklerSystem'].enabled" class="questions-in-row">
			<app-checkbox-image
				[label]="'safetyAlarms.smokeAlarm' | translate"
				[value]="form.controls['SmokeDetector'].value"
				image="smokeAlarm"
				name="SmokeDetector"
				selectedImage="smokeAlarmChecked"
			></app-checkbox-image>

			<app-checkbox-image
				[label]="'safetyAlarms.deadBolt' | translate"
				[value]="form.controls['DeadBoltLocks'].value"
				image="deadBolt"
				name="DeadBoltLocks"
				selectedImage="deadBoltChecked"
			></app-checkbox-image>

			<app-checkbox-image
				[label]="'safetyAlarms.fireExtinguisher' | translate"
				[value]="form.controls['FireExtinguisher'].value"
				image="fireExtinguisher"
				name="FireExtinguisher"
				selectedImage="fireExtinguisherChecked"
			></app-checkbox-image>

			<app-checkbox-image
				[label]="'safetyAlarms.fireDetectionDevice' | translate"
				[value]="form.controls['FireDetection'].value"
				image="fireDetectionDevice"
				name="FireDetection"
				selectedImage="fireDetectionDeviceChecked"
			></app-checkbox-image>

			<div
				*ngIf="form.controls['FireDetectionType'].enabled"
				[@inOutAnimation]
				class="question-container question-full-width fireDetectionDevice"
			>
				<div class="question-wrapper show">
					<label id="FireDetectionType">
						<span class="h3">{{ "safetyAlarms.fireDetectionDevice" | translate }}</span>
						{{ "safetyAlarms.fireAlarmReportsToCentralStation" | translate }}
					</label>
					<app-yes-no name="FireDetectionType"></app-yes-no>
				</div>
			</div>

			<app-checkbox-image
				[label]="'safetyAlarms.burglarAlarm' | translate"
				[value]="form.controls['BurglarAlarm'].value"
				image="burglarAlarm"
				name="BurglarAlarm"
				selectedImage="burglarAlarmChecked"
			></app-checkbox-image>

			<div
				*ngIf="form.controls['BurglarAlarmType'].enabled"
				[@inOutAnimation]
				class="question-container question-full-width"
			>
				<div class="question-wrapper show">
					<label id="BurglarAlarmType">
						<span class="h3">{{ "safetyAlarms.burglarAlarm" | translate }}</span>
						{{ "safetyAlarms.burglarAlarmReportToCentralStation" | translate }}
					</label>
					<app-yes-no name="BurglarAlarmType"></app-yes-no>
				</div>
			</div>

			<ng-container *ngIf="form.controls['SprinklerSystem'].enabled">
				<app-checkbox-image
					[label]="'safetyAlarms.sprinklerSystem' | translate"
					[value]="form.controls['SprinklerSystem'].value"
					image="sprinklerSystem"
					name="SprinklerSystem"
					selectedImage="sprinklerSystemChecked"
				></app-checkbox-image>
			</ng-container>

			<div
				*ngIf="form.controls['SprinklerSystemType'].enabled"
				[@inOutAnimation]
				class="question-container question-full-width"
			>
				<div class="question-wrapper show">
					<label id="SprinklerSystemType">
						<span class="h3">{{ "safetyAlarms.sprinklerSystem" | translate }}</span>
						{{ "safetyAlarms.sprinklerSystemAvailability" | translate }}
					</label>
					<app-yes-no name="SprinklerSystemType"></app-yes-no>
				</div>
			</div>
		</div>
	</form>

	<next-button
		(validationFailed)="handleValidation($event)"
		[disabled]="!form.valid"
		[myForm]="formDirective"
		[title]="'buttons.continue' | translate"
	>
	</next-button>
</section>
