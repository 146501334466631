import { AfterViewInit, Component, Injector, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Validations } from 'src/app/utils/validation';
import { QuoteDataService } from 'src/app/services/quote-data.service';
import { Store } from '@ngrx/store';
import { IAppState } from 'src/app/store/states/app.state';
import { BasePageComponent } from '../../base-page.component';
import { Fields } from 'src/app/constants/fields';
import { EventActions } from '../../../../../models/event-data.model';
import { first } from 'rxjs/operators';
import { LobsEnum } from '../../../../../enums/lobs.enum';
import { DriversService } from '../../../../../services/drivers.service';
import { selectInterviewTexts } from '../../../../../store/selectors/interview-texts.selectors';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map } from 'rxjs';
import { LobService } from 'src/app/services/lob.service';
import { selectAddress, selectQuoteData } from 'src/app/store/selectors/quote-data.selectors';
import { UpdateInterviewMetadata } from 'src/app/store/actions/interview-metadata.actions';
import { UpsellBoxComponent } from 'src/app/components/shared/upsell-box/components/upsell-box/upsell-box.component';
import { UpdateQuoteData } from 'src/app/store/actions/quote-data.actions';
import { selectInterviewMetadata } from 'src/app/store/selectors/interview-metadata.selector';
import { toLowerCaseOccupation } from 'src/app/utils/general.utils';
import { IDropdownOption } from 'src/app/components/form-controls/form-control-interfaces';
import { IndustryModel } from 'src/entities/industry-api-response.interface';
import { Dictionary } from 'src/entities/key-value.interface';
import { ActionApiService } from 'src/app/services/action-api.service';
import { updateApplicant } from '../../../../../store/actions/api.actions';

@UntilDestroy()
@Component({
	selector: 'app-personal-details',
	templateUrl: './personal-details.component.html',
	styleUrls: ['./personal-details.component.scss'],
})
export class PersonalDetailsHomeownersComponent extends BasePageComponent implements OnInit, AfterViewInit {
	@ViewChild(UpsellBoxComponent) upsellBoxComponent;
	phoneNumberMask = '999-999-9999';
	eventActions = EventActions;
	isAgreementEnabled = true;
	isLegalConsentEnabled = false;
	lob: string;
	getLobService: LobService;
	firstNameInit: string;
	lastNameInit: string;
	isMultiLob = false;
	upsellLob = LobsEnum.PERSONAL_AUTO;
	upsellSelected = false;
	LobsEnum = LobsEnum;
	lobsInitialized: string[];
	typeProperty: string;
	actionApiService: ActionApiService;
	DriverEmploymentIndustryEmployedOptions: IDropdownOption[];
	industries: IndustryModel[];
	occupationsOptions: IDropdownOption[];
	selectedState: string;

	private fieldsFirstStep = [
		Fields.FirstName.name,
		Fields.LastName.name,
		Fields.DateOfBirth.name,
		Fields.PersonalLineGender.name,
		Fields.MaritalStatus.name,
		Fields.DriverLicenseStatus.name,
		Fields.TypeOfResidence.name,
	];

	constructor(
		injector: Injector,
		private driversService: DriversService,
		protected quoteDataService: QuoteDataService,
		actionApiService: ActionApiService,
		protected store: Store<IAppState>
	) {
		super(injector, store);
		this.actionApiService = actionApiService;
	}

	get customFields(): FormGroup {
		return this.form.get('CustomFields') as FormGroup;
	}

	get legalConsentControl(): FormControl {
		return this.customFields.get(this.Fields.D2CAgreeToTerms.name) as FormControl;
	}

	get autoStateSpecificRules(): boolean {
		return this.d2CFeatures?.autoStateSpecificRules;
	}

	get creditFraudDisclosuresKey(): string {
		if (
			[
				'DE',
				'FL',
				'NY',
				'OR',
				'VA',
				'WV',
				'AR',
				'DC',
				'KY',
				'LA',
				'MD',
				'ME',
				'NJ',
				'NM',
				'OH',
				'OK',
				'PA',
				'RI',
				'TN',
				'WA',
				'TX',
				'UT',
				'VT',
			].includes(this.selectedState)
		) {
			return `CreditFraudDisclosures.${this.selectedState}`;
		}

		return `CreditFraudDisclosures.OTHER`;
	}

	ngAfterViewInit(): void {
		this.baseNgAfterViewInit();
	}

	ngOnInit(): void {
		super.ngOnInit();
		this.store
			.select(selectInterviewMetadata)
			.pipe(first())
			.subscribe((interviewMetadata) => {
				this.lobsInitialized = interviewMetadata.lobsInitialized;
				this.isMultiLob = interviewMetadata.isMultiLob;
				this.typeProperty = interviewMetadata._typeProperty;
				this.upsellSelected = interviewMetadata.crossSellTo.includes(this.upsellLob);
				this.initform();
				this.onUpsellToggled(this.upsellSelected);
				this.initIndustries();
				this.patchData(this.form);
				this.customPatchData();
				this.registerOnChange(this.form);
			});

		this.store
			.select(selectInterviewTexts)
			.pipe(
				untilDestroyed(this),
				map((stepTexts) => stepTexts['tspa'])
			)
			.subscribe((tspa) => {
				this.isAgreementEnabled = tspa.tcpaOn;
				this.isLegalConsentEnabled = tspa.legalConsentOn;

				this.Fields.IAgreeToReceiveEmailsByBolt = {
					...this.Fields.IAgreeToReceiveEmailsByBolt,
					labelKey: 'tspa.tcpaText',
				};

				if (this.isLegalConsentEnabled && tspa.legalConsent.length) {
					this.Fields.D2CAgreeToTerms = {
						...this.Fields.D2CAgreeToTerms,
						labelKey: 'tspa.legalConsent',
					};

					this.form.addControl(
						'CustomFields',
						new FormGroup({
							[this.Fields.D2CAgreeToTerms.name]: new FormControl({ value: null, disabled: false }, [
								Validators.requiredTrue,
							]),
						})
					);

					setTimeout(() => {
						if (
							this.quoteData.hasOwnProperty('CustomFields') &&
							this.quoteData.CustomFields.hasOwnProperty(this.Fields.D2CAgreeToTerms.name)
						) {
							this.legalConsentControl.setValue(
								this.quoteData.CustomFields[this.Fields.D2CAgreeToTerms.name] === 'True' ||
									this.quoteData.CustomFields[this.Fields.D2CAgreeToTerms.name]
							);
						}
					});
				}
			});

		this.store
			.select(selectQuoteData)
			.pipe(first())
			.subscribe((res) => {
				this.firstNameInit = res.FirstName;
				this.lastNameInit = res.LastName;
			});

		this.store
			.select(selectAddress)
			.pipe(untilDestroyed(this))
			.subscribe(({ State }) => {
				this.selectedState = State;
			});
	}

	initform(): void {
		this.form = new FormGroup(
			{
				CreditCheckPermission: new FormControl(true),
				IHerebyConfirm: new FormControl(true),
			},
			null,
			null
		);

		this.form.addControl(
			Fields.FirstName.name,
			new FormControl({ value: null, disabled: false }, [
				Validators.required,
				Validators.maxLength(50),
				Validations.firstNameOneChar,
				Validations.alphabet,
			])
		);

		this.form.addControl(
			Fields.LastName.name,
			new FormControl({ value: null, disabled: false }, [
				Validators.required,
				Validators.maxLength(30),
				Validations.lastNameOneChar,
				Validations.alphabet,
			])
		);

		this.form.addControl(
			Fields.PersonalLineGender.name,
			new FormControl({ value: null, disabled: false }, [Validators.required])
		);

		this.form.addControl(
			Fields.MaritalStatus.name,
			new FormControl({ value: null, disabled: false }, [Validators.required])
		);

		this.form.addControl(
			Fields.Email.name,
			new FormControl({ value: null, disabled: false }, [
				Validators.required,
				Validators.maxLength(60),
				Validations.emailValid,
			])
		);

		this.form.addControl(
			Fields.PrimaryPhoneNumber.name,
			new FormControl({ value: null, disabled: false }, [Validators.required, Validations.validPhoneNumber])
		);

		this.form.addControl(
			Fields.DateOfBirth.name,
			new FormControl({ value: null, disabled: false }, [
				Validators.required,
				Validations.isDOBpastDate,
				Validations.dateFormat,
				Validations.isMoreThen18,
				Validations.isEarlierThan1900,
			])
		);

		this.form.addControl(Fields.DriverLicenseStatus.name, new FormControl(null, [Validators.required]));
		this.form.addControl(Fields.PersonalLineGender.name, new FormControl(null, [Validators.required]));

		this.form.addControl(
			Fields.DriverEmploymentIndustry.name,
			new FormControl({ value: null, disabled: false }, [Validators.required])
		);

		this.form.addControl(
			Fields.DriverOccupationStr.name,
			new FormControl({ value: null, disabled: false }, [Validators.required])
		);

		this.form.addControl(
			Fields.DriverEducation.name,
			new FormControl({ value: null, disabled: false }, [Validators.required])
		);

		this.form.controls.DriverEmploymentIndustry?.valueChanges.subscribe((currentIndustry) => {
			if (!currentIndustry) {
				this.occupationsOptions = [];
			} else {
				this.form.controls.DriverOccupationStr.setValue(null);
				this.form.controls.DriverOccupationStr.markAsUntouched();
				this.occupationsOptions = this.mapToDropdownValues(
					this.industries?.find((i) => i.key === currentIndustry).occupations
				);
			}
		});

		// show DriverLicenseStatus on bundle(homeAuto)
		if (this.isMultiLob || this.upsellSelected) {
			this.form.controls[Fields.DriverLicenseStatus.name]?.enable();
		} else {
			this.form.controls[Fields.DriverLicenseStatus.name]?.disable();
		}
		this.form.addControl('IAgreeToReceiveEmailsByBolt', new FormControl({ value: null, disabled: false }));
	}

	customPatchData() {
		this.form.patchValue(
			{
				[Fields.DriverLicenseStatus.name]:
					this.quoteData?.Drivers[0]?.DriverLicenseStatus || this.quoteData.DriverLicenseStatus
						? this.quoteData?.Drivers[0]?.DriverLicenseStatus || this.quoteData.DriverLicenseStatus
						: null,

				[Fields.DriverEmploymentIndustry.name]:
					this.quoteData?.Drivers[0]?.DriverEmploymentIndustry || this.quoteData.DriverEmploymentIndustry
						? this.quoteData?.Drivers[0]?.DriverEmploymentIndustry || this.quoteData.DriverEmploymentIndustry
						: null,

				[Fields.DriverOccupationStr.name]:
					this.quoteData?.Drivers[0]?.DriverOccupationStr || this.quoteData.DriverOccupationStr
						? this.quoteData?.Drivers[0]?.DriverOccupationStr || this.quoteData.DriverOccupationStr
						: null,

				[Fields.DriverEducation.name]:
					this.quoteData?.Drivers[0]?.DriverEducation || this.quoteData.DriverEducation
						? this.quoteData?.Drivers[0]?.DriverEducation || this.quoteData.DriverEducation
						: null,
			},
			{ onlySelf: true, emitEvent: false }
		);
	}

	handleContinue() {
		this.store.dispatch(updateApplicant());

		const formValue: any = { ...this.form.value };
		let isFloodCrossSellActive = this.d2CFeatures?.floodCoverage;

		let selectedLobs;
		if (this.upsellBoxComponent.featureOn && !this.isMultiLob) {
			if (this.upsellBoxComponent.isSelected) {
				if (this.lobsInitialized.indexOf(LobsEnum.PERSONAL_AUTO) === -1) {
					selectedLobs = [
						...this.lobsInitialized.filter((lobInitialized) => lobInitialized !== LobsEnum.FLOOD),
						LobsEnum.PERSONAL_AUTO,
					];
				} else {
					selectedLobs = [...this.lobsInitialized];
				}
			} else {
				// when cross sell toggle off or not selected remove the cross sell lob
				selectedLobs = [...this.lobsInitialized];
				isFloodCrossSellActive && selectedLobs.indexOf(LobsEnum.FLOOD) === -1 && selectedLobs.push(LobsEnum.FLOOD);
			}
		}
		if (this.isMultiLob || this.upsellSelected) {
			// need to add driver ID with DriverLicenseStatus
			if (this.firstNameInit !== formValue.FirstName || this.lastNameInit !== formValue.LastName) {
				this.store.dispatch(UpdateInterviewMetadata({ data: { prefillCall: true } }));
			}

			formValue.TypeOfResidence = this.quoteData.PLTypeOfDwelling === 'Condominium' ? 'OwnCondo' : 'OwnHome';
		}

		this.driversService
			.updatePolicyInfoWithInitialDriversData(formValue, selectedLobs || false)
			.pipe(first())
			.subscribe((res) => {
				!this.isMultiLob && this.upsellBoxComponent.updateFlowType();
				if (res['data']) {
					this.store.dispatch(UpdateQuoteData({ data: res['data'] }));
				}
				setTimeout(() => {
					this.progressMeterService.goToNextPage();
				}, 0); //update crossSellTo before going to nextPage
			});
	}

	onUpsellToggled(upsellSelected: boolean) {
		this.upsellSelected = upsellSelected;

		if (upsellSelected) {
			this.form.controls[Fields.DriverLicenseStatus.name]?.enable();
			this.form.controls[Fields.DriverEmploymentIndustry.name]?.enable({ emitEvent: false });
			this.form.controls[Fields.DriverOccupationStr.name]?.enable();
			this.form.controls[Fields.DriverEducation.name]?.enable();
		} else {
			this.form.controls[Fields.DriverLicenseStatus.name]?.disable();
			this.form.controls[Fields.DriverEmploymentIndustry.name]?.disable({ emitEvent: false });
			this.form.controls[Fields.DriverOccupationStr.name]?.disable();
			this.form.controls[Fields.DriverEducation.name]?.disable();
		}
	}

	isDriverStatusEnable() {
		return this.form.get(Fields.DriverLicenseStatus.name)?.status !== 'DISABLED';
	}

	checkFieldsFirstStepValidity(): boolean {
		for (const fieldName of this.fieldsFirstStep) {
			const field = this.form.get(fieldName);
			if (!field || !field.valid) {
				return false;
			}
		}
		return true;
	}

	private initIndustries(): void {
		this.actionApiService
			.getIndustriesAndOccupations()
			.pipe(untilDestroyed(this))
			.subscribe((data) => {
				this.industries = data.industries;
				if (this.industries) {
					this.DriverEmploymentIndustryEmployedOptions = this.mapToDropdownValues(
						this.industries.map((i) => ({ key: i.key, value: i.value }))
					);
				}

				if (this.form.controls.DriverEmploymentIndustry.value) {
					this.occupationsOptions = this.mapToDropdownValues(
						this.industries?.find((i) => i.key === this.form.controls.DriverEmploymentIndustry.value).occupations
					);
				}
			});
	}

	private mapToDropdownValues(dataArray: Dictionary<string>[]): IDropdownOption[] {
		if (dataArray) {
			return dataArray.map(
				(data, index) =>
					({
						id: ++index,
						title: toLowerCaseOccupation(data.value),
						value: data.key,
					} as IDropdownOption)
			);
		}
	}
}
