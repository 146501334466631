import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { BasePageComponent } from '../../base-page.component';
import { QuoteDataService } from 'src/app/services/quote-data.service';
import { Store } from '@ngrx/store';
import { IAppState } from 'src/app/store/states/app.state';
import { ActionApiService } from 'src/app/services/action-api.service';
import { LobService } from 'src/app/services/lob.service';
import { combineLatest, first, Observable, Subscription } from 'rxjs';
import { ILoaderInformationState } from 'src/app/store/states/loader-information.state';
import { selectLoaderInformation } from 'src/app/store/selectors/loader-information.selector';
import { LoaderActions } from 'src/app/store/actions';
import { getSelectedRate } from 'src/app/store/selectors/result-data.selectors';
import { selectApplicationId, selectInterviewMetadata } from 'src/app/store/selectors/interview-metadata.selector';
import { untilDestroyed } from '@ngneat/until-destroy';
import { SetFQReportsHandled, UpdateInterviewMetadata } from 'src/app/store/actions/interview-metadata.actions';
import { paymentPlanSelect, setResultData } from 'src/app/store/actions/result-data.actions';
import { StepsEnum } from 'src/app/enums/steps.enum';
import { NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
	selector: 'app-quote-confirmation',
	templateUrl: './quote-confirmation.component.html',
	styleUrls: ['./quote-confirmation.component.scss'],
})
export class QuoteConfirmationComponent extends BasePageComponent implements OnInit, OnDestroy {
	public loaderInformation$: Observable<ILoaderInformationState>;

	subscription$: Subscription;
	finishFetch = false;
	apiInterval: NodeJS.Timer;

	selectedCarrier: string;
	buttonDisclaimer: string;
	rate: any;

	constructor(
		injector: Injector,
		protected quoteDataService: QuoteDataService,
		protected store: Store<IAppState>,
		private actionApi: ActionApiService,
		private lobService: LobService,
		private router: Router
	) {
		super(injector, store);
		this.loaderInformation$ = this.store.select(selectLoaderInformation);
		this.router.events
			.pipe(
				untilDestroyed(this),
				filter((e) => e instanceof NavigationStart)
			)
			.subscribe(() => {
				// if user navigate while fetching result stop fetching
				this.stopFetchingResults();
			});
	}

	ngOnInit(): void {
		super.ngOnInit();
		this.store.dispatch(LoaderActions.ShowLoader());

		this.subscription$ = combineLatest([this.store.select(getSelectedRate), this.lobService.getLob$()]).subscribe(
			([selectedRate, lob]) => {
				if (!selectedRate) {
					// no selected rate
					return;
				} else {
					if (this.subscription$) {
						this.subscription$.unsubscribe();
					}

					if (selectedRate.carrier.toLowerCase() === 'stillwater') {
						this.buttonDisclaimer = 'labels.carrierDisclaimer.Stillwater';
					}

					// check if need to go to submission again
					this.store
						.select(selectInterviewMetadata)
						.pipe(first())
						.subscribe((data) => {
							const carrierSelected = selectedRate.carrier;
							if (carrierSelected) {
								this.selectedCarrier = carrierSelected;
							}

							// need to call to submission FQ
							const Context = {
								Carrier: this.selectedCarrier,
								Product: lob,
							};

							if (!data.FQSubmission) {
								//get submission results, if submission did not return any rates - this method will redirect user to kickout page
								this.submissionFQ({ ...Context, Type: 'fullquote' });
							} else {
								if (
									this.quoteDataService.serverQuoteErrors.value &&
									this.quoteDataService.serverQuoteErrors.value.validationErrors &&
									this.quoteDataService.serverQuoteErrors.value.validationErrors.length &&
									!this.quoteDataService.hasErrorsFromServerForThisPage.value
								) {
									// If there are errors, it means that the user was not able to fix them in previous steps
									// should not make a submission with invalid data, so redirect to error page.
									this.routingService.navigateToErrorPage(StepsEnum.TECHNICAL_ERROR);
								} else {
									this.actionService
										.singleSubmitQuote({ Context: { ...Context, Scope: 'fullquote' } })
										.subscribe(() => {
											this.store.dispatch(SetFQReportsHandled({ data: false }));
											this.submissionFQ({ ...Context, Type: 'fullquote' });
										});
								}
							}
						});
				}
			}
		);
	}

	submissionFQ(context) {
		this.store
			.select(selectApplicationId)
			.pipe(first())
			.subscribe((applicationId) => {
				const intervalFunction = (applicationId) => {
					this.actionApi
						.post({ action: 'api/submissionResults', endpoint: `applications`, applicationId, ...context })
						.pipe(untilDestroyed(this))
						.subscribe((result: any) => {
							if (!result) {
								console.error('applications request error');
								return;
							}

							const res = Object.assign({}, result);
							if (res.status === 'Completed') {
								this.finishFetch = true;
								this.store.dispatch(UpdateInterviewMetadata({ data: { FQSubmission: false } }));
								clearInterval(this.apiInterval);
								this.subscription$.unsubscribe();
								this.store.dispatch(LoaderActions.Hideloader());
								const selectedQuoteIndex = res.quotes.findIndex((quote) => quote.carrier === this.selectedCarrier);
								if (selectedQuoteIndex !== -1) {
									res.quotes[selectedQuoteIndex].selected = true;
								}
								if (res.quotes.length !== 0) {
									res.rates = res.quotes;
									res.quotes = null;
									this.store.dispatch(setResultData({ data: res }));

									if (selectedQuoteIndex !== -1) {
										this.rate = res.rates[selectedQuoteIndex];
									}
								} else {
									this.stopFetchingResults();
									return;
								}

								if (this.isEscrowFlow && this.rate) {
									this.handleSelectPaymentPlan(this.rate.paymentPlans[0]);
								}
							}
						});
				};

				intervalFunction(applicationId);

				this.apiInterval = setInterval(() => {
					intervalFunction(applicationId);
				}, 5000);
			});
	}

	handleSelectPaymentPlan(data) {
		this.store.dispatch(paymentPlanSelect({ selectedPaymentPlan: data }));
	}

	ngOnDestroy() {
		this.stopFetchingResults();
		super.ngOnDestroy();
	}

	stopFetchingResults() {
		clearInterval(this.apiInterval);
		this.store.dispatch(LoaderActions.Hideloader());

		if (this.subscription$) {
			this.subscription$.unsubscribe();
		}
	}
}
