import { IField } from 'src/entities/field.interface';

export const Fields: IField = {
	// First page
	PurchaseDate: {
		name: 'PurchaseDate',
	},
	YearsAtAddress: {
		name: 'YearsAtAddress',
	},
	isMailAddress: {
		name: 'IsMailAddress',
	},
	MailingAddress: {
		name: 'MailingAddress',
	},
	MultiFamilyUnit: {
		name: 'FQData.StillWaterPersonalHome.MultiFamilyUnit',
	},
	PreviousAddress: {
		name: 'PreviousAddress',
	},
	PL_NumOfFamilies: {
		name: 'PL_NumOfFamilies',
	},
	ExistingDamageOnDwelling: {
		name: 'ExistingDamageOnDwelling',
	},
	RoadAccess: {
		name: 'FQData.StillWaterPersonalHome.RoadAccess',
	},

	// Second page
	BusinessOrDaycare: {
		name: 'BusinessOrDaycare',
	},
	FarmOrRanch: {
		name: 'FQData.StillWaterPersonalHome.FarmOrRanch',
	},
	ConvertedResidency: {
		name: 'FQData.StillWaterPersonalHome.ConvertedResidency',
	},
	ConstructionPermit: {
		name: 'FQData.StillWaterPersonalHome.ConstructionPermit',
	},
	RoofCondition: {
		name: 'FQData.StillWaterPersonalHome.RoofCondition',
	},
	DistanceToOcean: {
		name: 'FQData.StillWaterPersonalHome.DistanceToOcean',
	},
	NumberOfDogsOnPremises: {
		name: 'NumberOfDogsOnPremises',
	},
	ViciousExoticAnimals: {
		name: 'ViciousExoticAnimals',
	},
	DwellUseCd: {
		name: 'DwellUseCd',
	},
	UnusualVehicleStorage: {
		name: 'FQData.StillWaterPersonalHome.UnusualVehicleStorage',
	},
	UnusualConst: {
		name: 'FQData.StillWaterPersonalHome.UnusualConst',
	},
	MonthsUnoccupiedYesNo: {
		name: 'MonthsUnoccupiedYesNo',
	},
	MonthsUnoccupiedDD: {
		name: 'MonthsUnoccupiedDD',
	},
	ExtendedVacancy: {
		name: 'FQData.StillWaterPersonalHome.ExtendedVacancy',
	},

	// Third page
	DistanceToFireHydrant: {
		name: 'DistanceToFireHydrant',
	},
	DistanceToFireStation: {
		name: 'DistanceToFireStation',
	},
	LimitedAccessLocation: {
		name: 'FQData.StillWaterPersonalHome.LimitedAccessLocation',
	},
	PLNumberOfUnitsInFirewall: {
		name: 'PLNumberOfUnitsInFirewall',
	},
	PermanentFoundation: {
		name: 'FQData.StillWaterPersonalHome.PermanentFoundation',
	},
	AltWaterSource: {
		name: 'AltWaterSource',
	},

	// Fourth page
	ExteriorWallsConstruction: {
		name: 'ExteriorWallsConstruction',
	},
	NumberOfFireplaces: {
		name: 'FQData.StillWaterPersonalHome.NumberOfFireplaces',
	},
	NumberOfKitchens: {
		name: 'FQData.StillWaterPersonalHome.NumOfKitchens',
	},
	KitchenConstructionQuality: {
		name: 'FQData.StillWaterPersonalHome.KitchenConstructionQuality',
	},
	BathroomInformation: {
		name: 'FQData.StillWaterPersonalHome.BathroomInformation',
	},
	BathroomType: {
		name: 'BathroomType',
	},
	NumberOfBathrooms: {
		name: 'NumberOfBathrooms',
	},
	BathroomConstructionQuality: {
		name: 'BathroomConstructionQuality',
	},

	// Fifth page
	RoofSprayPoly: {
		name: 'FQData.StillWaterPersonalHome.RoofPolySpray',

		typeOfInput: 'YES/NO',
	},
	RoofSprayPolyLast3: {
		name: 'FQData.StillWaterPersonalHome.RoofPolySprayLast3',

		typeOfInput: 'YES/NO',
	},
	RoofSlopeValid: {
		name: 'FQData.StillWaterPersonalHome.RoofSlopeValid',
		typeOfInput: 'YES/NO',
	},
	SW_PL_AdditionalStructures_Pool: {
		name: 'PL_AdditionalStructures_Pool',

		typeOfInput: 'YES/NO',
	},
	SW_PL_PoolFeatures_DivingBoard: {
		name: 'PL_PoolFeatures_DivingBoard',

		typeOfInput: 'YES/NO',
	},
	SW_PL_PoolFeatures_Slide: {
		name: 'PL_PoolFeatures_Slide',

		typeOfInput: 'YES/NO',
	},
	SW_PL_PoolFeatures_4ftFence: {
		name: 'PL_PoolFeatures_4ftFence',

		typeOfInput: 'YES/NO',
	},
	SW_PoolUnfenced: {
		name: 'FQData.StillWaterPersonalHome.PoolUnfenced',
		typeOfInput: 'YES/NO',
	},
	TrampolineSafety: {
		name: 'FQData.StillWaterPersonalHome.TrampolineSafety',

		typeOfInput: 'YES/NO',
	},
	PL_AdditionalStructures_Garage: {
		name: 'PL_AdditionalStructures_Garage',
		typeOfInput: 'YES/NO',
	},
	TypeGarageCarport: {
		name: 'TypeGarageCarport',

		typeOfInput: 'DD',
	},
	PL_NumberCarSpace: {
		name: 'PL_NumberCarSpace',

		typeOfInput: 'DD',
	},
	BasementFinishType: {
		name: 'PL_BasementFinish',

		typeOfInput: 'DD',
	},

	// Sixth page - Stillwater Utilities
	PLHeatingType: {
		name: 'PLHeatingType',
	},
	SolidFuel: {
		name: 'FQData.StillWaterPersonalHome.SolidFuel',
	},
	StoveInstall: {
		name: 'FQData.StillWaterPersonalHome.StoveInstall',
	},
	ElectricCircuitBreaker: {
		name: 'ElectricCircuitBreaker',
	},
	PlumbingType: {
		name: 'PolicyData.FQData.StillWaterPersonalHome.PolyPlumbingType',
	},
};
