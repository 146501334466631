import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { ActionApiService } from '../../../../../../../../services/action-api.service';
import { QuoteDataService } from '../../../../../../../../services/quote-data.service';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../../../../../../store/states/app.state';
import { selectQuoteData } from '../../../../../../../../store/selectors/quote-data.selectors';
import { first } from 'rxjs/operators';
import { BasePageComponent } from '../../../../../base-page.component';
import { FormArray, FormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
	PrimaryUseOfVehicleOptions,
	WasTheCarNewOptions,
} from '../../../../../../../../constants/vehicle-options.data';
import { Validations } from '../../../../../../../../utils/validation';
import { NextButtonComponent } from '../../../../../../../next-button/next-button.component';
import { selectInterviewMetadata } from 'src/app/store/selectors/interview-metadata.selector';
import { Location } from '@angular/common';
import { combineLatest } from 'rxjs';
import { CarriersEnum } from 'src/app/enums/carriers.enum';
import { LobsEnum } from 'src/app/enums/lobs.enum';
import moment from 'moment';
import { getSelectedRate } from '../../../../../../../../store/selectors/result-data.selectors';
import { PrebindService } from '../../../../../../../../services/pre-bind.service';

const NOT_ALLOWED_OEM_PARTS_STATES = ['FL', 'IN', 'MN', 'NC', 'NY', 'TN', 'WV', 'WY'];

@UntilDestroy()
@Component({
	selector: 'app-safeco-vehicles',
	templateUrl: './safeco-vehicles.component.html',
	styleUrls: ['./safeco-vehicles.component.scss'],
})
export class SafecoVehiclesComponent extends BasePageComponent implements OnInit {
	@ViewChild(NextButtonComponent) nextButtonComponent: NextButtonComponent;
	preBindProcess: boolean;
	WasTheCarNewOptions = WasTheCarNewOptions;
	PrimaryUseOfVehicleOptions = PrimaryUseOfVehicleOptions;
	internalStep = 0;
	actionApiService: ActionApiService;
	vehicles = [];
	vehiclesFormArray = new FormArray([]);
	effectiveDate: string;
	showPreBindLoader = false;
	dynamicallyAddedValidators = {
		CostNewValue: [Validators.required, Validations.costNewValueMoreThan0],
		MilesToWork: [Validators.required, Validations.fieldMoreThan0],
	};
	isNextDisabled = true;
	preBindSucceed: boolean;
	isOem = false;
	state: string;

	constructor(
		actionApiService: ActionApiService,
		injector: Injector,
		protected quoteDataService: QuoteDataService,
		private location: Location,
		protected store: Store<IAppState>,
		private preBindService: PrebindService
	) {
		super(injector, store);
		this.actionApiService = actionApiService;
	}

	ngOnInit(): void {
		super.ngOnInit();
		this.initForm();

		combineLatest([
			this.store.select(selectQuoteData),
			this.store.select(getSelectedRate),
			this.store.select(selectInterviewMetadata),
		])
			.pipe(first())
			.subscribe(([quoteData, rate, data]) => {
				let isHasSpecificCoverage = false;

				// check is OEM
				// if TRUE than find specific coverage in the rate and add this rate to the oem group
				const autoCoverages = rate.coverages?.personalAuto?.entityCoverages?.personalAuto;

				Object.keys(autoCoverages).forEach((key) => {
					if (autoCoverages[key].filter((c) => c.coverage === 'OEMCoverage').length) {
						isHasSpecificCoverage = true;
					}
				});

				this.preBindProcess = data.preBindProcess;
				this.preBindSucceed = data.preBindSucceed;

				this.isOem =
					data.lobSelection?.filter((lobSel) => lobSel !== LobsEnum.FLOOD)[0] === LobsEnum.PERSONAL_AUTO &&
					this.d2CFeatures?.oemEndorsement &&
					isHasSpecificCoverage;

				this.patchData(this.form);
				this.registerOnChange(this.form);

				this.effectiveDate = quoteData.EffectiveDate;
				this.vehicles = [...quoteData.PersonalVehicles]; //TODO: when primary vehicle need to change quoteData.PersonalVehicles ownership Type should be unchanged

				if (quoteData.discoverVehicles) {
					this.vehicles = [...this.vehicles, ...quoteData.discoverVehicles];
				}

				if (quoteData.manualVehicles) {
					this.vehicles = [...this.vehicles, ...quoteData.manualVehicles];
				}

				const additionalQuestionsSafecoVehicles =
					quoteData.additionalQuestionsSafecoVehicles && quoteData.additionalQuestionsSafecoVehicles.length
						? quoteData.additionalQuestionsSafecoVehicles
						: [];
				this.vehicles.forEach((vehicle) => {
					const additionalQuestionsSafecoVehiclesItem =
						additionalQuestionsSafecoVehicles.find((item) => item.Id === vehicle.Id) || {};
					const formGroupItem = this.createFormGroup(additionalQuestionsSafecoVehiclesItem, vehicle);
					this.vehiclesFormArray.push(formGroupItem);

					this.additionalQuestionsSafecoVehicles().push(formGroupItem);

					this.registerChange(formGroupItem);
					this.state = quoteData?.PropertyAddress?.State;
					this.oemPartsFieldToggle(vehicle);
				});
			});

		this.form.statusChanges.pipe(untilDestroyed(this)).subscribe(() => {
			if (this.getActiveFields()[this.internalStep]) {
				this.isNextDisabled = !this.getActiveFields()[this.internalStep].valid;
			}
		});
	}

	initForm(): void {
		this.form = new FormGroup({
			additionalQuestionsSafecoVehicles: new FormArray([]),
		});
	}

	additionalQuestionsSafecoVehicles(): FormArray {
		return this.form.controls.additionalQuestionsSafecoVehicles as FormArray;
	}

	createFormGroup(itemFormValue, vehicle) {
		const data = {
			Id: vehicle.Id,
			SequenceNum: vehicle.enabled ? vehicle.SequenceNum : -1,
			DateVehiclePurchased: [
				{
					value: itemFormValue.DateVehiclePurchased
						? moment(itemFormValue.DateVehiclePurchased).format('MM/DD/YYYY')
						: vehicle.DateVehiclePurchased
							? moment(vehicle.DateVehiclePurchased).format('MM/DD/YYYY')
							: null,
					disabled: false,
				},
				[Validators.required, Validations.dateFormat],
			],
			VIN: [itemFormValue.VIN || vehicle.VIN, { validators: [Validators.required, Validations.alphabetAndDigits] }],
			ExternalVINPrefix: [itemFormValue.ExternalVINPrefix || vehicle.ExternalVINPrefix],
			enabled: [{ value: vehicle.enabled, disabled: false }],
			PLYear: [{ value: vehicle.PLYear, disabled: false }],
			PLMake: [{ value: vehicle.PLMake, disabled: false }],
			PLModel: [{ value: vehicle.PLModel, disabled: false }],
			BodyStyle: [{ value: vehicle.BodyStyle, disabled: false }],
			WasTheCarNew: [
				itemFormValue.WasTheCarNew !== undefined ? itemFormValue.WasTheCarNew : null,
				[Validators.required],
			],
			CostNewValue: [
				{
					value: itemFormValue.CostNewValue || null,
					disabled: this.isCostNewValueDisabled(itemFormValue.WasTheCarNew),
				},
				this.isCostNewValueDisabled(itemFormValue.WasTheCarNew) ? [] : this.dynamicallyAddedValidators.CostNewValue,
			],
			PrimaryUseOfVehicle: [itemFormValue.PrimaryUseOfVehicle || null, [Validators.required]],
			OEMCoverage: [this.getOEMCoverage(itemFormValue.OEMCoverage), [Validators.required]],
			MilesToWork: [
				{
					value: itemFormValue.MilesToWork || null,
					disabled: this.isMilesToWorkDisabled(itemFormValue.PrimaryUseOfVehicle),
				},
				this.isMilesToWorkDisabled(itemFormValue.PrimaryUseOfVehicle)
					? []
					: this.dynamicallyAddedValidators.CostNewValue,
			],
		};

		const formGroupItem = this.formBuilder.group(data) as FormGroup;
		if (vehicle.ExternalVINPrefix) {
			formGroupItem.controls.VIN.addValidators(Validations.ValidateVinPrefix(vehicle.ExternalVINPrefix));
		}

		formGroupItem.controls.WasTheCarNew.valueChanges
			.pipe(untilDestroyed(this))
			.subscribe((value) => this.onWasTheCarNewChange(formGroupItem, value));
		formGroupItem.controls.PrimaryUseOfVehicle.valueChanges
			.pipe(untilDestroyed(this))
			.subscribe((value) => this.onPrimaryUseOfVehicleChange(formGroupItem, value));

		// async validator added with a delay, in order to prevent multiple endpoint calls
		setTimeout(() => {
			if (vehicle.enabled) {
				formGroupItem.controls.VIN.addAsyncValidators([Validations.validateVINAsync(this.actionService)]);
			}
		});
		return formGroupItem;
	}

	getOEMCoverage(value: boolean): boolean {
		if (value === null || value === undefined) {
			value = this.isOem;
		}

		return value;
	}

	getActiveFields() {
		return this.additionalQuestionsSafecoVehicles().controls.filter(
			(control) => control.value.SequenceNum !== -1
		) as Array<FormGroup>;
	}

	handleContinue() {
		if (!this.preBindSucceed) {
			this.preBindService.navigateToNoResult();
			return;
		}

		if (!this.isLastInternalStep()) {
			this.nextButtonComponent.loading = false;
			this.navigateInternal(true);
		} else {
			const PersonalVehicles = this.additionalQuestionsSafecoVehicles().value.map(
				({ enabled, ...vehicleDataItem }) => ({ ...vehicleDataItem })
			);
			this.actionApiService
				.updateApplication({ PersonalVehicles }, [LobsEnum.PERSONAL_AUTO], CarriersEnum.SafecoInsurance)
				.subscribe((serverRes) => {
					this.showPreBindLoader = false;
					this.onUpdateApplicationResult(serverRes, this.getActiveFields() as FormGroup[])
						.pipe(first())
						.subscribe((invalidReproducibleIndex) => {
							if (this.quoteDataService.hasErrorsFromServerForThisPage.value) {
								this.navigateInternalByIndex(invalidReproducibleIndex);
							}
						});
				});
		}
	}

	isLastInternalStep() {
		return this.internalStep === this.getActiveFields().length - 1;
	}

	navigateInternal(forward: boolean) {
		this.internalStep += 1 * (forward ? 1 : -1);
	}

	navigateInternalByIndex(index: number) {
		this.internalStep = index;
		this.isNextDisabled = !this.getActiveFields()[this.internalStep].valid;
	}

	getProgressbarWidth(): string {
		return ((this.internalStep + 1) / this.getActiveFields().length) * 100 + '%';
	}

	onWasTheCarNewChange(formGroupItem, value) {
		if (!this.isCostNewValueDisabled(value)) {
			formGroupItem.controls.CostNewValue.enable();
			formGroupItem.controls.CostNewValue.addValidators(this.dynamicallyAddedValidators.CostNewValue);
		} else {
			formGroupItem.controls.CostNewValue.disable();
			formGroupItem.controls.CostNewValue.removeValidators(this.dynamicallyAddedValidators.CostNewValue);
		}

		formGroupItem.controls.CostNewValue.updateValueAndValidity();
	}

	isCostNewValueDisabled(wasTheCarNew: boolean): boolean {
		return !wasTheCarNew;
	}

	onPrimaryUseOfVehicleChange(formGroupItem, value) {
		if (!this.isMilesToWorkDisabled(value)) {
			formGroupItem.controls.MilesToWork.enable();
			formGroupItem.controls.MilesToWork.addValidators(this.dynamicallyAddedValidators.MilesToWork);
		} else {
			formGroupItem.controls.MilesToWork.disable();
			formGroupItem.controls.MilesToWork.removeValidators(this.dynamicallyAddedValidators.MilesToWork);
		}
		formGroupItem.controls.MilesToWork.updateValueAndValidity();
	}

	isMilesToWorkDisabled(primaryUseOfVehicle): boolean {
		return primaryUseOfVehicle !== 'To_FromWork' && primaryUseOfVehicle !== 'To_FromSchool';
	}

	goBack() {
		if (this.internalStep > 0) {
			this.navigateInternal(false);
		} else {
			this.location.back();
		}
		this.eventsService.addEvent(this.EventActions.BUTTON_CLICK, {
			label: 'go-forward',
			button: 'go-forward-driver-history',
			module: 'driver-history-module',
		});
	}

	oemPartsFieldToggle(vehicle) {
		let isOemEndorsementActive = this.d2CFeatures?.oemEndorsement;
		let control: any = this.additionalQuestionsSafecoVehicles().controls.find(
			(control) => control.value.Id === vehicle.Id
		);
		if (
			isOemEndorsementActive &&
			vehicle.PLYear &&
			new Date().getFullYear() - vehicle.PLYear < 10 &&
			NOT_ALLOWED_OEM_PARTS_STATES.indexOf(this.state) === -1
		) {
			control.controls['OEMCoverage'].enable();
		} else {
			control.controls['OEMCoverage'].disable();
		}
	}

	private registerChange(form: FormGroup): void {
		// after any change mark as not new
		form.valueChanges.pipe(first()).subscribe(() => {
			form.patchValue({ newListing: false });
		});
	}
}
