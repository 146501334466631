import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { FormArray, FormGroup, Validators } from '@angular/forms';
import { first, map } from 'rxjs/operators';
import { untilDestroyed } from '@ngneat/until-destroy';
import { IDropdownOption } from 'src/app/components/form-controls/form-control-interfaces';
import { IndustryModel } from 'src/entities/industry-api-response.interface';
import { Dictionary } from 'src/entities/key-value.interface';
import { Location } from '@angular/common';
import { NextButtonComponent } from 'src/app/components/next-button/next-button.component';
import { ActionApiService } from 'src/app/services/action-api.service';
import { BasePageComponent } from '../../../../../base-page.component';
import { QuoteDataService } from 'src/app/services/quote-data.service';
import { IAppState } from 'src/app/store/states/app.state';
import { selectQuoteData } from 'src/app/store/selectors/quote-data.selectors';
import { StepsEnum } from 'src/app/enums/steps.enum';
import { convertJsonToArray, maskingLicenseNumber, toLowerCaseOccupation } from 'src/app/utils/general.utils';
import { Fields } from 'src/app/constants/fields';
import { Validations } from 'src/app/utils/validation';
import moment from 'moment';
import { FormattedDateService } from 'src/app/services/formatted-date.service';
import { UpdateQuoteData } from 'src/app/store/actions/quote-data.actions';
import { CarriersEnum } from 'src/app/enums/carriers.enum';
import { showPopupAction } from 'src/app/store/actions/popup.actions';
import { DriversService } from 'src/app/services/drivers.service';
import { merge } from 'rxjs';

@Component({
	selector: 'app-progressive-additional-drivers',
	templateUrl: './progressive-additional-drivers.component.html',
	styleUrls: ['./progressive-additional-drivers.component.scss'],
})
export class ProgressiveAdditionalDriversComponent extends BasePageComponent implements OnInit {
	@ViewChild(NextButtonComponent, { static: true }) nextButtonComponent: NextButtonComponent;
	actionApiService: ActionApiService;
	isLoading: any;

	drivers = [];
	activeTabIndex = 0;
	index = 0;
	DriverEmploymentIndustryEmployedOptions: IDropdownOption[];
	industries: IndustryModel[];
	occupationsOptions: IDropdownOption[];
	StepsEnum = StepsEnum;
	numberOfSteps = 0;
	FieldsPerPage = {
		firstPage: [
			Fields.DOB.name,
			Fields.Gender.name,
			Fields.MaritalStatus.name,
			Fields.DriverRelationshipToDriver1.name,
			Fields.DriverLicenseStatus.name,
		],
		secondPage: [
			Fields.DriverEmploymentIndustry.name,
			Fields.DriverOccupationStr.name,
			Fields.FQMatriculaCard.name,
			Fields.FQMatriculaQualifiedToDrive.name,
			Fields.DriverLicenseNumber.name,
			Fields.DriverStateLicensed.name,
			Fields.DriverEducation.name,
			Fields.FQLicenseClass.name,
			Fields.DriverDateLicensed.name,
			Fields.SR22OrFinancialResponsibility.name,
			Fields.DriversLicenseBeenSuspendedOrRevoked.name,
			Fields.FQIsStudent100Miles.name,
			Fields.SnapshotPhoneNumber.name,
			Fields.IsGoodStudent.name,
			Fields.FQDriverRatingType.name,
			Fields.IsDefensiveDriver.name,
			Fields.DefensiveDriverCourseDate.name,
			Fields.Filings.name,
		],
	};
	phoneNumberMask = '999-999-9999';
	firstTabQuestions = true;

	constructor(
		injector: Injector,
		protected quoteDataService: QuoteDataService,
		protected store: Store<IAppState>,
		actionApiService: ActionApiService,
		private location: Location,
		private formattedDateService: FormattedDateService,
		private driversService: DriversService
	) {
		super(injector, store, quoteDataService);
		this.actionApiService = actionApiService;
	}

	get driversFormControl(): FormArray {
		return this.form.controls.additionalQuestionsProgressiveMissingDrivers as FormArray;
	}

	get driverOccupation(): IDropdownOption[] {
		if (
			this.industries &&
			this.form.value.primaryDriver &&
			this.activeTabIndex == 0 &&
			this.form.value.primaryDriver[0]
		) {
			const currentIndustry = this.form.value.primaryDriver[0].DriverEmploymentIndustry;
			if (!currentIndustry) {
				return [];
			}
			return this.mapToDropdownValues(this.industries?.find((i) => i.key === currentIndustry).occupations);
		}
	}

	ngOnInit(): void {
		super.ngOnInit();
		this.initForm();
		this.initIndustries();

		setTimeout(() => {
			this.patchData(this.form);
			this.registerOnChange(this.form);

			this.store
				.select(selectQuoteData)
				.pipe(first())
				.subscribe((quoteData) => {
					const driversToAdd = quoteData.missingDrivers.filter((i) => i.DriverAction == 'Add');
					const driverHistory =
						quoteData.additionalQuestionsProgressiveMissingDrivers &&
							quoteData.additionalQuestionsProgressiveMissingDrivers.length
							? quoteData.additionalQuestionsProgressiveMissingDrivers
							: [];
					this.drivers = [...driversToAdd];

					let allDrivers = [
						...this.quoteData.Drivers,
						...this.quoteData.manualDrivers,
						...this.quoteData.discoverDrivers,
						...driverHistory,
					];
					allDrivers.sort((a, b) => a.SequenceNum - b.SequenceNum);
					let sequenceNumArray = allDrivers.map((driver) => driver.SequenceNum);

					driversToAdd.forEach((driver, index) => {
						let nextSequenceNum = 1;
						while (sequenceNumArray.includes(nextSequenceNum)) {
							nextSequenceNum++;
						}
						const driverHistoryItem = driverHistory.find((item) => item.Id === driver.Id);
						this.driversFormControl.push(
							this.createDriverForm(
								driverHistoryItem
									? {
										...driverHistoryItem,
										sequenceNum: nextSequenceNum,
									}
									: driver
							)
						);
						this.numberOfSteps = this.numberOfSteps + 2;
					});
				});
		});
	}

	createDriverForm(driver): FormGroup {
		let DOB = driver.DOB && driver.DOB.match(/\d{4}/) ? driver.DOB.match(/\d{4}/)[0] : null;
		let formGroup = this.formBuilder.group({
			Id: driver.Id,
			SequenceNum: driver.SequenceNum,
			FirstName: driver.FirstName,
			LastName: driver.LastName,
			// First page questions
			DOB: [
				{ value: driver.DOB !== null ? this.formattedDateService.findYearOnDate(driver.DOB) : null, disabled: false },
				[Validators.required, Validations.dateFormat, Validations.isDOBpastDate, Validations.unmaskedDOB],
			],
			Gender: [{ value: driver.Gender || null, disabled: false }, [Validators.required]],
			MaritalStatus: [{ value: driver.MaritalStatus || null, disabled: false }, [Validators.required]],
			DriverRelationshipToDriver1: [
				{ value: driver.DriverRelationshipToDriver1 || null, disabled: false },
				[Validators.required],
			],
			DriverLicenseStatus: [{ value: driver.DriverLicenseStatus || null, disabled: false }, [Validators.required]],

			//Second page
			DriverEmploymentIndustry: [
				driver.DriverEmploymentIndustry ? driver.DriverEmploymentIndustry : null,
				[Validators.required, Validations.notEqualesTo('Other')],
			],
			FQLicenseClass: [{ value: driver.FQLicenseClass || null, disabled: false }, [Validators.required]],
			DriverOccupationStr: [driver.DriverOccupationStr ? driver.DriverOccupationStr : null, [Validators.required]],
			FQMatriculaCard: [
				{
					value: typeof driver.FQMatriculaCard == 'boolean' ? driver.FQMatriculaCard : null,
					disabled: driver.DriverStateLicensed !== 'Mexico',
				},
				[Validators.required],
			],
			FQMatriculaQualifiedToDrive: [
				{
					value: typeof driver.FQMatriculaQualifiedToDrive == 'boolean' ? driver.FQMatriculaQualifiedToDrive : null,
					disabled: driver.DriverStateLicensed !== 'Mexico' || !driver.FQMatriculaCard,
				},
				[Validators.required],
			],
			DriverLicenseNumber: [
				{
					value: driver.DriverLicenseNumber ? maskingLicenseNumber(driver.DriverLicenseNumber) : null,
					disabled: driver.DriverLicenseStatus == 'NotLicensed',
				},
				[Validators.required],
			],
			DriverStateLicensed: [
				{
					value: driver.DriverStateLicensed ? driver.DriverStateLicensed : null,
					disabled: driver.DriverLicenseStatus == 'NotLicensed',
				},
				[Validators.required],
			],
			DriverEducation: [
				{
					value: driver.DriverEducation ? driver.DriverEducation : null,
					disabled: false,
				},
				[Validators.required],
			],
			DriverDateLicensed: [
				{
					value: driver.DriverDateLicensed ? moment(driver.DriverDateLicensed).format('MM/DD/YYYY') : null,
					disabled: driver.DriverLicenseStatus == 'NotLicensed',
				},
				[Validators.required, Validations.dateFormat],
			],

			DriversLicenseBeenSuspendedOrRevoked: [
				{
					value:
						typeof driver.DriversLicenseBeenSuspendedOrRevoked == 'boolean'
							? driver.DriversLicenseBeenSuspendedOrRevoked
							: null,
					disabled: false,
				},

				[Validators.required],
			],
			[Fields.SnapshotPhoneNumber.name]: [
				{
					value: driver[Fields.SnapshotPhoneNumber.name] ? driver[Fields.SnapshotPhoneNumber.name] : null,
					disabled: false,
				},
				[Validators.required, Validations.validSnapshotNumber],
			],
			FQDriverRatingType: [
				driver.DriverLicenseStatus == 'NotLicensed' ? 'Excluded' : driver.FQDriverRatingType || null,
				[Validators.required],
			],
			IsGoodStudent: [
				{
					value: typeof driver.IsGoodStudent == 'boolean' ? driver.IsGoodStudent : null,
					disabled: true,
				},
				[Validators.required],
			],
			FQIsStudent100Miles: [
				{
					value: typeof driver.FQIsStudent100Miles == 'boolean' ? driver.FQIsStudent100Miles : null,
					disabled: true,
				},
				[Validators.required],
			],
			IsDefensiveDriver: [
				{
					value: typeof driver.IsDefensiveDriver == 'boolean' ? driver.IsDefensiveDriver : null,
					disabled: driver.DriverLicenseStatus !== 'NotLicensed',
				},
				[Validators.required],
			],
			DefensiveDriverCourseDate: [
				{
					value: driver.DefensiveDriverCourseDate ? driver.DefensiveDriverCourseDate : null,
					disabled: driver.DriverLicenseStatus !== 'NotLicensed' || !driver.IsDefensiveDriver,
				},
				[Validators.required, Validations.dateFormat, Validations.isDefensiveFutureDate],
			],
			active: true,
		}) as FormGroup;

		formGroup.addControl('Filings', convertJsonToArray(driver.Filings ? driver.Filings : []));

		this.driversService.editFilings.pipe(untilDestroyed(this)).subscribe((index) => {
			if (index) {
				(<FormGroup>(
					(<FormArray>this.form.controls.additionalQuestionsProgressiveMissingDrivers).at(index['index'])
				)).setControl('Filings', convertJsonToArray(index['Filings']));
			}
		});

		this.store
			.select(selectQuoteData)
			.pipe(first())
			.subscribe((data) => {
				if (data['FQData.ProgressivePersonalAuto.UBIExperienceEnrollment'] !== 'DNP') {
					formGroup.controls[Fields.SnapshotPhoneNumber.name].enable();
				} else {
					formGroup.controls[Fields.SnapshotPhoneNumber.name].disable();
				}
			});

		this.registerValuesChanges(formGroup, driver);

		return formGroup;
	}

	registerValuesChanges(formGroup: FormGroup, driver) {
		formGroup.controls.DriverEmploymentIndustry?.valueChanges.subscribe((currentIndustry) => {
			if (!currentIndustry) {
				this.occupationsOptions = [];
			} else {
				formGroup.controls.DriverOccupationStr.setValue(null);
				formGroup.controls.DriverOccupationStr.markAsUntouched();
				this.occupationsOptions = this.mapToDropdownValues(
					this.industries?.find((i) => i.key === currentIndustry).occupations
				);
			}
		});

		formGroup.controls.IsDefensiveDriver?.valueChanges.subscribe((isDefensiveDriver) => {
			isDefensiveDriver
				? formGroup.controls.DefensiveDriverCourseDate.enable()
				: formGroup.controls.DefensiveDriverCourseDate.disable();
		});

		formGroup.controls.DriverStateLicensed?.valueChanges.subscribe((state) => {
			state == 'Mexico' ? formGroup.controls.FQMatriculaCard.enable() : formGroup.controls.FQMatriculaCard.disable();
			state == 'Mexico' && formGroup.controls.FQMatriculaCard.value
				? formGroup.controls.FQMatriculaQualifiedToDrive.enable()
				: formGroup.controls.FQMatriculaQualifiedToDrive.disable();
		});

		formGroup.controls.FQMatriculaCard?.valueChanges.subscribe((matriculaCard) => {
			matriculaCard
				? formGroup.controls.FQMatriculaQualifiedToDrive.enable()
				: formGroup.controls.FQMatriculaQualifiedToDrive.disable();
		});

		formGroup.controls.DriverLicenseStatus?.valueChanges.subscribe((res) => {
			if (res == 'NotLicensed') {
				formGroup.controls.DriverLicenseNumber.disable();
				formGroup.controls.IsDefensiveDriver.enable();
				formGroup.controls.DriverDateLicensed.disable();
				formGroup.controls.DriverStateLicensed.disable();
				formGroup.controls.FQDriverRatingType.setValue('Excluded');
			} else {
				formGroup.controls.DriverLicenseNumber.enable();
				formGroup.controls.IsDefensiveDriver.disable();
				formGroup.controls.DriverDateLicensed.enable();
				formGroup.controls.DriverStateLicensed.enable();
				formGroup.controls.FQDriverRatingType.setValue(null);
				formGroup.controls.FQDriverRatingType.markAsUntouched();
			}
		});

		merge(
			formGroup.controls.DriverEmploymentIndustry.valueChanges,
			formGroup.controls.DriverLicenseStatus.valueChanges,
			formGroup.controls.DOB.valueChanges
		).subscribe(() => {
			if (
				formGroup.controls.DriverEmploymentIndustry.value == 'Student' &&
				formGroup.controls.DOB.value &&
				formGroup.controls.DOB.value.match(/\d{4}/) &&
				formGroup.controls.DOB.value.match(/\d{4}/)[0] &&
				new Date().getFullYear() - Number(formGroup.controls.DOB.value.match(/\d{4}/)[0]) < 23 &&
				formGroup.controls.DriverLicenseStatus.value !== 'NotLicensed'
			) {
				if (driver.SequenceNum !== 0 && formGroup.controls.DriverRelationshipToDriver1.value !== 'Spouse') {
					formGroup.controls.IsGoodStudent.enable();
					formGroup.controls.FQIsStudent100Miles.enable();
				} else {
					formGroup.controls.IsGoodStudent.disable();
					formGroup.controls.FQIsStudent100Miles.disable();
				}
			} else {
				formGroup.controls.FQIsStudent100Miles.disable();
				formGroup.controls.IsGoodStudent.disable();
			}
		});
	}

	getDriverHistoryForm() {
		return this.driversFormControl?.controls[Math.floor(this.activeTabIndex / 2)] as FormGroup;
	}

	initForm(): void {
		this.form = new FormGroup({
			additionalQuestionsProgressiveMissingDrivers: new FormArray([]),
		});
	}

	activateNextTab() {
		const nextTabIndex = this.activeTabIndex + 1;
		this.firstTabQuestions = !this.firstTabQuestions;
		if (nextTabIndex < this.drivers.length * 2) {
			this.activeTabIndex = nextTabIndex;
		}
	}

	goBack() {
		this.firstTabQuestions = !this.firstTabQuestions;
		if (this.activeTabIndex > 0) {
			this.activeTabIndex = this.activeTabIndex - 1;
		} else {
			this.location.back();
		}
		this.eventsService.addEvent(this.EventActions.BUTTON_CLICK, {
			label: 'go-back',
			button: 'go-back-progressive-additional-drivers',
			module: 'progressive-additional-drivers-module',
		});
	}

	isLastTabActive(): boolean {
		return this.activeTabIndex === this.drivers.length * 2 - 1;
	}

	handleContinue() {
		// need to update the policy data and update the quote data.
		// In addtion need to remove the added driver from missing driver array on quote data and also from discovered drivers
		this.store
			.select(selectQuoteData)
			.pipe(first())
			.subscribe((res) => {
				const discoverDriversToAdd = this.form.controls.additionalQuestionsProgressiveMissingDrivers.value
					? this.form.controls.additionalQuestionsProgressiveMissingDrivers.value.map((driver, index) => ({
						...this.driversService.updateDriverFieldsByFilings(driver),
						SequenceNum: res.discoverDrivers.concat(res.Drivers).concat(res.manualDrivers).length + index,
					}))
					: [];

				//Adding the rest of the discovered drivers
				const restDrivers = res.discoverDrivers.filter((driver) => {
					return !discoverDriversToAdd.map((drv) => drv.Id).includes(driver.Id);
				});

				let manualDrivers = [...res.manualDrivers].map((driver) => {
					const manualDriver = res.additionalQuestionsProgressiveDrivers.find((drv) => driver.Id === drv.Id);
					return { ...driver, ...manualDriver };
				});

				manualDrivers = manualDrivers.map((driver) => this.driversService.updateDriverFieldsByFilings(driver));

				this.actionApiService
					.updateDriverFQ(
						{
							Drivers: {
								Drivers: [],
								discoverDrivers: discoverDriversToAdd.concat(restDrivers),
								manualDrivers,
							},
						},
						CarriersEnum.Progressive
					)
					.subscribe((serverRes) => {
						//Need to add to quote data the additional drivers
						this.store.dispatch(
							UpdateQuoteData({
								data: { discoverDrivers: this.form.value.additionalQuestionsProgressiveMissingDrivers },
							})
						);
						this.onUpdateApplicationResult(serverRes, this.driversFormControl.controls as FormGroup[])
							.pipe(first())
							.subscribe((invalidReproducibleIndex) => {
								if (this.quoteDataService.hasErrorsFromServerForThisPage.value) {
									this.handleValidation(serverRes.validationErrors);
									let fieldNameError = Object.keys(this.getControls(invalidReproducibleIndex).controls)[
										Object.values(this.getControls(invalidReproducibleIndex).controls).findIndex(
											(control) => control.errors
										)
									];
									let firstPage = this.FieldsPerPage.firstPage.includes(fieldNameError);
									let indexToNavigate = invalidReproducibleIndex * 2;
									if (!firstPage) {
										indexToNavigate = indexToNavigate++;
									}
									this.activeTabIndex = indexToNavigate;
									this.firstTabQuestions = firstPage;
								}
							});
					});
			});
	}

	showQuestion(field) {
		return (
			(this.firstTabQuestions && this.FieldsPerPage.firstPage.includes(field)) ||
			(!this.firstTabQuestions && this.FieldsPerPage.secondPage.includes(field))
		);
	}

	isTabActive(index: number): boolean {
		return index === Math.floor(this.activeTabIndex / 2);
	}

	checkValidityCurrentFields() {
		let currentDriverIndex = Math.floor(this.activeTabIndex / 2);
		if (this.getControls(currentDriverIndex) && this.getControls(currentDriverIndex)?.controls) {
			let notValid = Object.keys(this.getControls(currentDriverIndex)?.controls).filter((key) =>
				this.firstTabQuestions
					? this.FieldsPerPage.firstPage.includes(key) &&
					!this.getControls(currentDriverIndex).controls[key].valid &&
					this.getControls(currentDriverIndex).controls[key].enabled
					: this.FieldsPerPage.secondPage.includes(key) &&
					!this.getControls(currentDriverIndex).controls[key].valid &&
					this.getControls(currentDriverIndex).controls[key].enabled
			);
			return notValid.length > 0 ? true : false;
		}
	}

	getControls(index) {
		return (this.form.get('additionalQuestionsProgressiveMissingDrivers') as FormArray).controls[index] as FormGroup;
	}

	openStateFilingPopup(index) {
		this.store.dispatch(
			showPopupAction({
				componentName: 'state-filing',
				payload: {
					text: `popups.titles.state-filing`,
					originPage: 'additionalQuestionsProgressiveMissingDrivers',
					index: index,
					customClass: 'state-filing',
				},
			})
		);
	}

	getActiveFilingsLength(index: number) {
		return this.driversFormControl.controls[index].get('Filings').value.filter((i) => i.SequenceNum !== -1).length;
	}

	private initIndustries(): void {
		this.actionApiService
			.getIndustriesAndOccupations()
			.pipe(untilDestroyed(this))
			.subscribe((data) => {
				this.industries = data.industries;

				if (this.industries) {
					this.DriverEmploymentIndustryEmployedOptions = this.mapToDropdownValues(
						this.industries.map((i) => ({ key: i.key, value: i.value }))
					);
				}

				if (this.form.value.additionalQuestionsProgressiveMissingDrivers[0].DriverEmploymentIndustry) {
					this.occupationsOptions = this.mapToDropdownValues(
						this.industries?.find(
							(i) => i.key === this.form.value.additionalQuestionsProgressiveMissingDrivers[0].DriverEmploymentIndustry
						).occupations
					);
				}
			});
	}

	private mapToDropdownValues(dataArray: Dictionary<string>[]): IDropdownOption[] {
		if (dataArray) {
			return dataArray.map(
				(data, index) =>
				({
					id: ++index,
					title: toLowerCaseOccupation(data.value),
					value: data.key,
				} as IDropdownOption)
			);
		}
	}
}
