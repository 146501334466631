import { Component, Injector, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { Store } from '@ngrx/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { IAppState } from '../../../../../../../../store/states/app.state';
import { BasePageComponent } from '../../../../../base-page.component';
import { SYSTEM_UPDATES } from './steps-fileds';
import { QuoteDataService } from '../../../../../../../../services/quote-data.service';

@UntilDestroy()
@Component({
	selector: 'app-stillwater-utilities',
	templateUrl: './stillwater-utilities.component.html',
	styleUrls: ['./stillwater-utilities.component.scss', '../stillwater.component.scss'],
})
export class StillwaterUtilitiesComponent extends BasePageComponent implements OnInit {
	stepTwoFields = SYSTEM_UPDATES;
	vertical = true;
	isPlumbingTypeVisible = false;

	constructor(
		protected injector: Injector,
		protected store: Store<IAppState>,
		private fb: FormBuilder,
		protected quoteDataService: QuoteDataService
	) {
		super(injector, store);
	}

	ngOnInit(): void {
		super.ngOnInit();

		this.form = this.fb.group({
			[this.Fields.PLHeatingType.name]: [null, Validators.required],
			[this.Fields.SolidFuel.name]: [null, Validators.required],
			[this.Fields.StoveInstall.name]: [null],
			[this.Fields.SW_ElectricCircuitBreaker.name]: [null, Validators.required],
			[this.Fields.SW_PlumbingType.name]: [null],
			[this.Fields.HeatingUpdateYN.name]: [null, Validators.required],
			[this.Fields.SW_PLHeatingUpdate.name]: [null],
			[this.Fields.SW_HeatingUpdateYear.name]: [null],
			[this.Fields.ElectricalUpdateYN.name]: [null, Validators.required],
			[this.Fields.SW_PLElectricalUpdated.name]: [null],
			[this.Fields.SW_ElectricalUpdatedYear.name]: [null],
			[this.Fields.PlumbingUpdateYN.name]: [null, Validators.required],
			[this.Fields.SW_PLPlumbingUpdated.name]: [null],
			[this.Fields.SW_PlumbingUpdatedYear.name]: [null],
		});

		this.isPlumbingTypeVisible = +this.quoteData[this.Fields.PLYearBuilt.name] < 1999;

		if (this.isPlumbingTypeVisible) {
			this.form.controls[this.Fields.SW_PlumbingType.name].addValidators(Validators.required);
		}

		this.patchData(this.form);
		this.onFormChange();
	}

	private onFormChange(): void {
		// transform values for the systems related fields
		this.stepTwoFields.forEach((field) => {
			this.form.controls[field.name].valueChanges.pipe(untilDestroyed(this)).subscribe((fieldValue) => {
				if (fieldValue !== null) {
					field.children.forEach((child) => {
						const childValue = eval(eval(child.rule));

						this.form.controls[child.name]?.setValue(childValue);
					});
				}
			});
		});

		this.form[this.Fields.SolidFuel.name]?.valueChanges.pipe(untilDestroyed(this)).subscribe((fieldValue) => {
			if (fieldValue) {
				this.form[this.Fields.StoveInstall.name].enable();
				this.form[this.Fields.StoveInstall.name].addValidators(Validators.required);
			} else {
				this.form[this.Fields.StoveInstall.name].disable();
				this.form[this.Fields.StoveInstall.name].removeValidators(Validators.required);
			}

			this.form.updateValueAndValidity();
		});

		super.registerOnChange(this.form);
	}
}
