import { StepsEnum } from '../../enums/steps.enum';
import { LobsEnum } from 'src/app/enums/lobs.enum';
import { FlowType, StepGroup } from '../../enums/step-groups.enum';
import { StepsGroup } from '../../models/step-groups.model';
import { CarriersEnum } from '../../enums/carriers.enum';

export interface IProgressMeter {
	activeStep: number;
	flowType: string;
	isStepsProgressVisible?: boolean;
	isDefaultProgressVisible?: boolean;
	isMultiLob?: boolean;
	flowOptions: { [key: string]: IStep[] };
	groupedSteps?: StepsGroup[];
	steps?: IStep[];
}

export interface IStep {
	name?: string;
	visited?: boolean;
	pmTitle?: string;
	active?: boolean;
	isCrossSell?: LobsEnum[];
	stepProperties?: IStepProperties;
	/*
	 * required fields for the step
	 * childQuestions are the fields that are required if the parent field is true
	 * relevantLobs is the lobs that the field is required for
	 */
	requiredFields?: {
		name: string;
		isReproducible?: string;
		childQuestions?: any[];
		relevantLobs?: string[];
		conditions?: string;
	}[];
	checkCondition?: boolean;
}

export interface IStepProperties {
	phoneVisible?: boolean;
	progressMeter: {
		visible: boolean; // if we need to show Step progress inside the header
		flowType: FlowType; // defines is FullQuote or QuickQuote
		carrier?: CarriersEnum; // needed for additional question pages on FQ
		group?: StepGroup; // defines in which group should be the step for in One Lob
		multiLobGroup?: StepGroup; // defines in which group should be the step for in MultiLob/CrossSel
	};
}

export const GeneralSteps: { [key: string]: IStep } = {
	START_DATE: {
		name: StepsEnum.POLICY_START_DATE,
		visited: false,
		stepProperties: {
			progressMeter: {
				visible: true,
				group: StepGroup.startDate,
				flowType: FlowType.qq,
			},
		},
		requiredFields: [{ name: 'EffectiveDate' }],
	},
	CONFIRMATION_EMAIL_SENT: {
		name: StepsEnum.CONFIRMATION_EMAIL_SENT,
		checkCondition: true,
		visited: false,
		stepProperties: {
			progressMeter: {
				visible: false,
				flowType: FlowType.qq,
			},
		},
		requiredFields: [{ name: 'EffectiveDate' }],
	},
	RESULT: {
		name: StepsEnum.RESULT,
		visited: false,
		stepProperties: {
			phoneVisible: true,
			progressMeter: {
				visible: true,
				group: StepGroup.quotes,
				flowType: FlowType.qq,
			},
		},
	},
};

let init: IStep[] = [
	{
		name: StepsEnum.YOUR_ADDRESS,
		visited: true,
		stepProperties: {
			phoneVisible: false,
			progressMeter: {
				visible: false,
				flowType: FlowType.qq,
			},
		},
		requiredFields: [
			{ name: 'PropertyAddress.AddressLine1' },
			{ name: 'PropertyAddress.City' },
			{ name: 'PropertyAddress.State' },
			{ name: 'PropertyAddress.ZipCode' },
		],
	},
	{
		name: StepsEnum.LOB_SELECTION,
		checkCondition: true,
		visited: false,
		stepProperties: {
			phoneVisible: false,
			progressMeter: {
				visible: false,
				flowType: FlowType.qq,
			},
		},
	},
];

let stillwater_steps: IStep[] = [
	{
		name: StepsEnum.ADDITIONAL_QUESTIONS_STILLWATER,
		checkCondition: true,
		visited: false,
		stepProperties: {
			phoneVisible: true,
			progressMeter: {
				visible: true,
				flowType: FlowType.fq,
				group: StepGroup.addQuestions,
				carrier: CarriersEnum.stillwater,
			},
		},
	},
	{
		name: StepsEnum.ADDITIONAL_QUESTIONS_STILLWATER_SECOND_PAGE,
		checkCondition: true,
		visited: false,
		stepProperties: {
			phoneVisible: true,
			progressMeter: {
				visible: true,
				flowType: FlowType.fq,
				group: StepGroup.addQuestions,
				carrier: CarriersEnum.stillwater,
			},
		},
	},
];

let stillwater_home_steps = [
	{
		name: StepsEnum.ADDITIONAL_QUESTIONS_PROPERTY_INFORMATION_STILLWATER_HOME,
		checkCondition: true,
		visited: false,
		stepProperties: {
			phoneVisible: true,
			progressMeter: {
				visible: true,
				flowType: FlowType.fq,
				group: StepGroup.addQuestions,
				carrier: CarriersEnum.stillwater,
			},
		},
	},
	{
		name: StepsEnum.ADDITIONAL_QUESTIONS_ADDITIONAL_PROPERTY_INFORMATION_STILLWATER_HOME,
		checkCondition: true,
		visited: false,
		stepProperties: {
			phoneVisible: true,
			progressMeter: {
				visible: true,
				flowType: FlowType.fq,
				group: StepGroup.addQuestions,
				carrier: CarriersEnum.stillwater,
			},
		},
	},
	{
		name: StepsEnum.ADDITIONAL_QUESTIONS_PROPERTY_SAFETY_STILLWATER_HOME,
		checkCondition: true,
		visited: false,
		stepProperties: {
			phoneVisible: true,
			progressMeter: {
				visible: true,
				flowType: FlowType.fq,
				group: StepGroup.addQuestions,
				carrier: CarriersEnum.stillwater,
			},
		},
	},
	{
		name: StepsEnum.ADDITIONAL_QUESTIONS_PROPERTY_FEATURES_STILLWATER_HOME,
		checkCondition: true,
		visited: false,
		stepProperties: {
			phoneVisible: true,
			progressMeter: {
				visible: true,
				flowType: FlowType.fq,
				group: StepGroup.addQuestions,
				carrier: CarriersEnum.stillwater,
			},
		},
	},
	{
		name: StepsEnum.ADDITIONAL_QUESTIONS_ADDITIONAL_PROPERTY_FEATURES_STILLWATER_HOME,
		checkCondition: true,
		visited: false,
		stepProperties: {
			phoneVisible: true,
			progressMeter: {
				visible: true,
				flowType: FlowType.fq,
				group: StepGroup.addQuestions,
				carrier: CarriersEnum.stillwater,
			},
		},
	},
	{
		name: StepsEnum.ADDITIONAL_QUESTIONS_UTILITIES_STILLWATER_HOME,
		checkCondition: true,
		visited: false,
		stepProperties: {
			phoneVisible: true,
			progressMeter: {
				visible: true,
				flowType: FlowType.fq,
				group: StepGroup.addQuestions,
				carrier: CarriersEnum.stillwater,
			},
		},
	},

	{
		name: StepsEnum.CURRENT_INSURANCE_STILLWATER,
		checkCondition: true,
		visited: false,
		stepProperties: {
			phoneVisible: true,
			progressMeter: {
				visible: true,
				flowType: FlowType.fq,
				group: StepGroup.addQuestions,
				carrier: CarriersEnum.stillwater,
			},
		},
	},
	{
		name: StepsEnum.CARRIER_DISCLOSURE_STILLWATER,
		checkCondition: true,
		visited: false,
		stepProperties: {
			phoneVisible: true,
			progressMeter: {
				visible: true,
				flowType: FlowType.fq,
				group: StepGroup.addQuestions,
				carrier: CarriersEnum.stillwater,
			},
		},
	},
];

let lemonade_steps: IStep[] = [
	{
		name: StepsEnum.ADDITIONAL_QUESTIONS_LEMONADE,
		checkCondition: true,
		visited: false,
		stepProperties: {
			phoneVisible: true,
			progressMeter: {
				visible: true,
				flowType: FlowType.fq,
				group: StepGroup.addQuestions,
				carrier: CarriersEnum.lemonade,
			},
		},
	},
];

let safeco_steps: IStep[] = [
	{
		name: StepsEnum.ADDITIONAL_QUESTIONS_SAFECO_POLICY,
		checkCondition: true,
		visited: false,
		stepProperties: {
			phoneVisible: true,
			progressMeter: {
				visible: true,
				flowType: FlowType.fq,
				group: StepGroup.addQuestions,
				carrier: CarriersEnum.SafecoInsurance,
			},
		},
	},
	{
		name: StepsEnum.ADDITIONAL_QUESTIONS_SAFECO_DRIVERS,
		checkCondition: true,
		visited: false,
		stepProperties: {
			phoneVisible: true,
			progressMeter: {
				visible: true,
				flowType: FlowType.fq,
				group: StepGroup.addQuestions,
				carrier: CarriersEnum.SafecoInsurance,
			},
		},
	},
	{
		name: StepsEnum.ADDITIONAL_QUESTIONS_MISSING_DRIVERS,
		checkCondition: true,
		visited: false,
		stepProperties: {
			phoneVisible: true,
			progressMeter: {
				visible: true,
				flowType: FlowType.fq,
				group: StepGroup.addQuestions,
				carrier: CarriersEnum.SafecoInsurance,
			},
		},
	},
	{
		name: StepsEnum.ADDITIONAL_QUESTIONS_SAFECO_VEHICLES,
		checkCondition: true,
		visited: false,
		stepProperties: {
			phoneVisible: true,
			progressMeter: {
				visible: true,
				flowType: FlowType.fq,
				group: StepGroup.addQuestions,
				carrier: CarriersEnum.SafecoInsurance,
			},
		},
	},
];

let progressive_steps: IStep[] = [
	{
		name: StepsEnum.PROGRESSIVE_DISCLOSURE,
		checkCondition: true,
		visited: false,
		stepProperties: {
			phoneVisible: true,
			progressMeter: {
				visible: true,
				flowType: FlowType.fq,
				group: StepGroup.addQuestions,
				carrier: CarriersEnum.Progressive,
			},
		},
	},
	{
		name: StepsEnum.PROGRESSIVE_SNAPSHOT,
		checkCondition: true,
		visited: false,
		stepProperties: {
			phoneVisible: true,
			progressMeter: {
				visible: true,
				flowType: FlowType.fq,
				group: StepGroup.addQuestions,
				carrier: CarriersEnum.Progressive,
			},
		},
	},
	{
		name: StepsEnum.PROGRESSIVE_VEHICLES,
		checkCondition: true,
		visited: false,
		stepProperties: {
			phoneVisible: true,
			progressMeter: {
				visible: true,
				flowType: FlowType.fq,
				group: StepGroup.addQuestions,
				carrier: CarriersEnum.Progressive,
			},
		},
	},
	{
		name: StepsEnum.PROGRESSIVE_DRIVERS,
		checkCondition: true,
		visited: false,
		stepProperties: {
			phoneVisible: true,
			progressMeter: {
				visible: true,
				flowType: FlowType.fq,
				group: StepGroup.addQuestions,
				carrier: CarriersEnum.Progressive,
			},
		},
	},
	{
		name: StepsEnum.PROGRESSIVE_ADDITIONAL_QUESTIONS_MISSING_DRIVERS,
		checkCondition: true,
		visited: false,
		stepProperties: {
			phoneVisible: true,
			progressMeter: {
				visible: true,
				flowType: FlowType.fq,
				group: StepGroup.addQuestions,
				carrier: CarriersEnum.Progressive,
			},
		},
	},
	{
		name: StepsEnum.PROGRESSIVE_ADDITIONAL_DRIVERS,
		checkCondition: true,
		visited: false,
		stepProperties: {
			phoneVisible: true,
			progressMeter: {
				visible: true,
				flowType: FlowType.fq,
				group: StepGroup.addQuestions,
				carrier: CarriersEnum.Progressive,
			},
		},
	},
	{
		name: StepsEnum.PROGRESSIVE_ADDITIONAL_QUESTIONS,
		checkCondition: true,
		visited: false,
		stepProperties: {
			phoneVisible: true,
			progressMeter: {
				visible: true,
				flowType: FlowType.fq,
				group: StepGroup.addQuestions,
				carrier: CarriersEnum.Progressive,
			},
		},
	},
	{
		name: StepsEnum.PROGRESSIVE_COVERAGES,
		checkCondition: true,
		visited: false,
		stepProperties: {
			phoneVisible: true,
			progressMeter: {
				visible: true,
				flowType: FlowType.fq,
				group: StepGroup.addQuestions,
				carrier: CarriersEnum.Progressive,
			},
		},
	},
];

const home_cross_sale_stage: IStep[] = [
	{
		name: StepsEnum.ALMOST_THERE,
		isCrossSell: [LobsEnum.PERSONAL_HOME, LobsEnum.CONDOMINIUM, LobsEnum.DWELLING_FIRE],
		visited: false,
		stepProperties: {
			phoneVisible: false,
			progressMeter: {
				visible: true,
				flowType: FlowType.qq,
				multiLobGroup: StepGroup.home,
			},
		},
	},
	{
		name: StepsEnum.PROPERTY_USAGE,
		checkCondition: true,
		isCrossSell: [LobsEnum.PERSONAL_HOME, LobsEnum.CONDOMINIUM, LobsEnum.DWELLING_FIRE],
		visited: false,
		stepProperties: {
			phoneVisible: false,
			progressMeter: {
				visible: true,
				flowType: FlowType.qq,
				multiLobGroup: StepGroup.home,
			},
		},
	},
	{
		name: StepsEnum.HOUSE_DETAILS,
		isCrossSell: [LobsEnum.PERSONAL_HOME, LobsEnum.CONDOMINIUM, LobsEnum.DWELLING_FIRE],
		visited: false,
		stepProperties: {
			phoneVisible: false,
			progressMeter: {
				visible: true,
				flowType: FlowType.qq,
				multiLobGroup: StepGroup.home,
			},
		},
	},
	{
		name: StepsEnum.ROOF_REPLACEMENT,
		isCrossSell: [LobsEnum.PERSONAL_HOME, LobsEnum.CONDOMINIUM, LobsEnum.DWELLING_FIRE],
		visited: false,
		checkCondition: true,
		stepProperties: {
			phoneVisible: false,
			progressMeter: {
				visible: true,
				flowType: FlowType.qq,
				multiLobGroup: StepGroup.home,
			},
		},
	},
	{
		name: StepsEnum.SAFETY_ALARMS,
		isCrossSell: [LobsEnum.PERSONAL_HOME, LobsEnum.CONDOMINIUM, LobsEnum.DWELLING_FIRE],
		visited: false,
		stepProperties: {
			phoneVisible: false,
			progressMeter: {
				visible: true,
				flowType: FlowType.qq,
				multiLobGroup: StepGroup.home,
			},
		},
		requiredFields: [
			{ name: 'BurglarAlarm', childQuestions: [{ name: 'BurglarAlarmType' }] },
			{ name: 'DeadBoltLocks' },
			{ name: 'FireDetection', childQuestions: [{ name: 'FireDetectionType' }] },
			{ name: 'FireExtinguisher' },
			{ name: 'SmokeDetector' },
			{
				name: 'SprinklerSystem',
				relevantLobs: [LobsEnum.PERSONAL_HOME],
				childQuestions: [{ name: 'SprinklerSystemType' }],
			},
		],
	},
];

const auto_cross_sale_stage: IStep[] = [
	{
		name: StepsEnum.ALMOST_THERE,
		isCrossSell: [LobsEnum.PERSONAL_AUTO],
		visited: false,
		stepProperties: {
			phoneVisible: false,
			progressMeter: {
				visible: true,
				flowType: FlowType.qq,
				multiLobGroup: StepGroup.auto,
			},
		},
	},
	{
		name: StepsEnum.DRIVERS,
		isCrossSell: [LobsEnum.PERSONAL_AUTO],
		visited: false,
		stepProperties: {
			phoneVisible: false,
			progressMeter: {
				visible: true,
				flowType: FlowType.qq,
				multiLobGroup: StepGroup.auto,
			},
		},
		requiredFields: [
			{ name: 'FirstName', isReproducible: 'Drivers' },
			{ name: 'LastName', isReproducible: 'Drivers' },
			{ name: 'Gender', isReproducible: 'Drivers' },
			{ name: 'MaritalStatus', isReproducible: 'Drivers' },
			{ name: 'DriverLicenseStatus', isReproducible: 'Drivers' },
			{ name: 'DOB', isReproducible: 'Drivers' },
			{ name: 'DriverEmploymentIndustry', isReproducible: 'Drivers' },
			{ name: 'DriverOccupationStr', isReproducible: 'Drivers' },
			{ name: 'DriverEducation', isReproducible: 'Drivers' },
		],
	},
	{
		name: StepsEnum.VEHICLES,
		isCrossSell: [LobsEnum.PERSONAL_AUTO],
		visited: false,
		stepProperties: {
			phoneVisible: false,
			progressMeter: {
				visible: true,
				flowType: FlowType.qq,
				multiLobGroup: StepGroup.auto,
			},
		},
		requiredFields: [
			{ name: 'PLYear', isReproducible: 'PersonalVehicles' },
			{ name: 'PLMake', isReproducible: 'PersonalVehicles' },
			{ name: 'PLModel', isReproducible: 'PersonalVehicles' },
			{ name: 'BodyStyle', isReproducible: 'PersonalVehicles' },
			{ name: 'NumberOfMiles', isReproducible: 'PersonalVehicles' },
			{ name: 'OwnershipType', isReproducible: 'PersonalVehicles' },
		],
	},
	{
		name: StepsEnum.DRIVER_HISTORY,
		isCrossSell: [LobsEnum.PERSONAL_AUTO],
		visited: false,
		stepProperties: {
			phoneVisible: false,
			progressMeter: {
				visible: true,
				flowType: FlowType.qq,
				multiLobGroup: StepGroup.auto,
			},
		},
		requiredFields: [
			{
				name: 'HasAccidents',
				isReproducible: 'Drivers',
				childQuestions: [
					{ name: 'Description', isReproducible: 'Accidents' },
					{ name: 'DateOfAccident', isReproducible: 'Accidents' },
				],
			},
			{
				name: 'HasViolations',
				isReproducible: 'Drivers',
				childQuestions: [
					{ name: 'Description', isReproducible: 'Violations' },
					{ name: 'DateOfViolation', isReproducible: 'Violations' },
				],
			},
			{
				name: 'HasAutoLosses',
				isReproducible: 'Drivers',
				childQuestions: [
					{ name: 'AutoLossesDescription', isReproducible: 'Losses' },
					{ name: 'AutoLossesDate', isReproducible: 'Losses' },
				],
			},
		],
	},
	{
		name: StepsEnum.AUTO_COVERAGES,
		isCrossSell: [LobsEnum.PERSONAL_AUTO],
		visited: false,
		stepProperties: {
			phoneVisible: false,
			progressMeter: {
				visible: true,
				flowType: FlowType.qq,
				multiLobGroup: StepGroup.auto,
			},
		},
		requiredFields: [
			{ name: 'BI' },
			{ name: 'PersonalVehicles.0.CollDeductible' },
			{ name: 'PersonalVehicles.0.CompDeductible' },
		],
	},
];

export const initialProgressMeter: IProgressMeter = {
	activeStep: 0,
	flowType: 'init',
	flowOptions: {
		init,
		[LobsEnum.RENTERS]: [
			...init,
			{
				name: StepsEnum.PROPERTIES,
				visited: false,
				stepProperties: {
					phoneVisible: false,
					progressMeter: {
						visible: false,
						flowType: FlowType.qq,
					},
				},
				requiredFields: [{ name: 'PLTypeOfDwelling' }],
			},
			{
				name: StepsEnum.PERSONAL_DETAILS,
				visited: false,
				stepProperties: {
					phoneVisible: false,
					progressMeter: {
						visible: false,
						flowType: FlowType.qq,
					},
				},
				requiredFields: [
					{ name: 'CreditCheckPermission' },
					{ name: 'PersonalLineGender' },
					{ name: 'MaritalStatus' },
					{ name: 'Email' },
					{ name: 'PrimaryPhoneNumber' },
					{ name: 'DateOfBirth' },
					{ name: 'IAgreeToReceiveEmailsByBolt' },
				],
			},
			{
				name: StepsEnum.POLICY_DATEPICKER,
				visited: false,
				stepProperties: {
					phoneVisible: false,
					progressMeter: {
						visible: false,
						flowType: FlowType.qq,
					},
				},
				requiredFields: [{ name: 'EffectiveDate' }],
			},
			GeneralSteps.CONFIRMATION_EMAIL_SENT,
			{
				...GeneralSteps.RESULT,
				stepProperties: {
					...GeneralSteps.RESULT.stepProperties,
					progressMeter: {
						visible: false,
						flowType: FlowType.qq,
					},
				},
			},
			...stillwater_steps,
			{
				name: StepsEnum.QUOTE_CONFIRMATION,
				visited: false,
				stepProperties: {
					phoneVisible: true,
					progressMeter: {
						visible: true,
						flowType: FlowType.fq,
						group: StepGroup.confirmation,
					},
				},
			},
			{
				name: StepsEnum.PAYMENT_PLAN,
				visited: false,
				stepProperties: {
					phoneVisible: true,
					progressMeter: {
						visible: true,
						flowType: FlowType.fq,
						group: StepGroup.payment,
					},
				},
			},
			{
				name: StepsEnum.PAYMENT,
				checkCondition: true,
				visited: false,
				stepProperties: {
					phoneVisible: true,
					progressMeter: {
						visible: true,
						flowType: FlowType.fq,
						group: StepGroup.payment,
					},
				},
			},
			{
				name: StepsEnum.PAYMENT_ESCROW,
				checkCondition: true,
				visited: false,
				stepProperties: {
					phoneVisible: true,
					progressMeter: {
						visible: true,
						flowType: FlowType.fq,
						group: StepGroup.payment,
					},
				},
			},
			{
				name: StepsEnum.PAYMENT_RESULT,
				visited: false,
				stepProperties: {
					phoneVisible: true,
					progressMeter: {
						visible: true,
						flowType: FlowType.fq,
						group: StepGroup.complete,
					},
				},
			},
			{
				name: StepsEnum.SUCCESS_PAGE_ESCROW,
				visited: false,
				stepProperties: {
					phoneVisible: true,
					progressMeter: {
						visible: true,
						flowType: FlowType.fq,
						group: StepGroup.complete,
					},
				},
			},
		],
		[LobsEnum.PERSONAL_HOME]: [
			...init,
			{
				name: StepsEnum.PROPERTIES,
				visited: false,
				stepProperties: {
					phoneVisible: false,
					progressMeter: {
						visible: true,
						group: StepGroup.residence,
						flowType: FlowType.qq,
						multiLobGroup: StepGroup.home,
					},
				},
				requiredFields: [{ name: 'PLTypeOfDwelling' }],
			},
			{
				name: StepsEnum.PRIMARY_RESIDENCE,
				visited: false,
				stepProperties: {
					phoneVisible: false,
					progressMeter: {
						visible: true,
						group: StepGroup.residence,
						flowType: FlowType.qq,
						multiLobGroup: StepGroup.home,
					},
				},
				requiredFields: [{ name: 'OccupancyType' }],
			},
			{
				name: StepsEnum.PROPERTY_USAGE,
				checkCondition: true,
				visited: false,
				stepProperties: {
					phoneVisible: false,
					progressMeter: {
						visible: true,
						group: StepGroup.residence,
						flowType: FlowType.qq,
						multiLobGroup: StepGroup.home,
					},
				},
			},
			{
				name: StepsEnum.HOUSE_DETAILS,
				visited: false,
				stepProperties: {
					phoneVisible: false,
					progressMeter: {
						visible: true,
						group: StepGroup.property,
						flowType: FlowType.qq,
						multiLobGroup: StepGroup.home,
					},
				},
			},
			{
				name: StepsEnum.ROOF_REPLACEMENT,
				checkCondition: true,
				visited: false,
				stepProperties: {
					phoneVisible: false,
					progressMeter: {
						visible: true,
						group: StepGroup.property,
						flowType: FlowType.qq,
						multiLobGroup: StepGroup.home,
					},
				},
			},
			{
				name: StepsEnum.SAFETY_ALARMS,
				visited: false,
				stepProperties: {
					phoneVisible: false,
					progressMeter: {
						visible: true,
						group: StepGroup.property,
						flowType: FlowType.qq,
						multiLobGroup: StepGroup.home,
					},
				},
				requiredFields: [
					{ name: 'BurglarAlarm', childQuestions: [{ name: 'BurglarAlarmType' }] },
					{ name: 'DeadBoltLocks' },
					{ name: 'FireDetection', childQuestions: [{ name: 'FireDetectionType' }] },
					{ name: 'FireExtinguisher' },
					{ name: 'SmokeDetector' },
					{
						name: 'SprinklerSystem',
						childQuestions: [{ name: 'SprinklerSystemType' }],
						relevantLobs: [LobsEnum.PERSONAL_HOME],
					},
				],
			},
			{
				name: StepsEnum.PERSONAL_DETAILS_HOMEOWNERS,
				visited: false,
				stepProperties: {
					phoneVisible: false,
					progressMeter: {
						visible: true,
						group: StepGroup.policyholder,
						flowType: FlowType.qq,
						multiLobGroup: StepGroup.home,
					},
				},
				requiredFields: [
					{ name: 'CreditCheckPermission' },
					{ name: 'PersonalLineGender' },
					{ name: 'MaritalStatus' },
					{ name: 'Email' },
					{ name: 'PrimaryPhoneNumber' },
					{ name: 'DateOfBirth' },
					{ name: 'IAgreeToReceiveEmailsByBolt' },
				],
			},
			...auto_cross_sale_stage,
			{
				...GeneralSteps.START_DATE,
				stepProperties: {
					...GeneralSteps.START_DATE.stepProperties,
					progressMeter: {
						...GeneralSteps.START_DATE?.stepProperties?.progressMeter,
						multiLobGroup: StepGroup.auto,
						flowType: FlowType.qq,
					},
				},
			},
			GeneralSteps.CONFIRMATION_EMAIL_SENT,
			{
				name: StepsEnum.RESULT,
				visited: false,
				stepProperties: {
					phoneVisible: true,
					progressMeter: {
						visible: true,
						group: StepGroup.quotes,
						multiLobGroup: StepGroup.quotes,
						flowType: FlowType.qq,
					},
				},
			},
			...stillwater_home_steps,
			...lemonade_steps,
			...safeco_steps,
			{
				name: StepsEnum.QUOTE_CONFIRMATION,
				visited: false,
				stepProperties: {
					phoneVisible: true,
					progressMeter: {
						visible: true,
						flowType: FlowType.fq,
						group: StepGroup.confirmation,
					},
				},
			},
			{
				name: StepsEnum.PAYMENT_PLAN,
				visited: false,
				stepProperties: {
					phoneVisible: true,
					progressMeter: {
						visible: true,
						flowType: FlowType.fq,
						group: StepGroup.payment,
					},
				},
			},
			{
				name: StepsEnum.PAYMENT,
				checkCondition: true,
				visited: false,
				stepProperties: {
					phoneVisible: true,
					progressMeter: {
						visible: true,
						flowType: FlowType.fq,
						group: StepGroup.payment,
					},
				},
			},
			{
				name: StepsEnum.PAYMENT_ESCROW,
				checkCondition: true,
				visited: false,
				stepProperties: {
					phoneVisible: true,
					progressMeter: {
						visible: true,
						flowType: FlowType.fq,
						group: StepGroup.payment,
					},
				},
			},
			{
				name: StepsEnum.PAYMENT_RESULT,
				visited: false,
				stepProperties: {
					phoneVisible: true,
					progressMeter: {
						visible: true,
						flowType: FlowType.fq,
						group: StepGroup.complete,
					},
				},
			},
			{
				name: StepsEnum.SUCCESS_PAGE_ESCROW,
				visited: false,
				stepProperties: {
					phoneVisible: true,
					progressMeter: {
						visible: true,
						flowType: FlowType.fq,
						group: StepGroup.complete,
					},
				},
			},
		],
		[LobsEnum.PERSONAL_AUTO]: [
			...init,
			{
				name: StepsEnum.PRIMARY_DRIVER,
				visited: false,
				stepProperties: {
					phoneVisible: false,
					progressMeter: {
						visible: true,
						group: StepGroup.drivers,
						flowType: FlowType.qq,
						multiLobGroup: StepGroup.auto,
					},
				},
				requiredFields: [
					{ name: 'Email' },
					{ name: 'PrimaryPhoneNumber' },
					{ name: 'IAgreeToReceiveEmailsByBolt' },
					{ name: 'Drivers.0.FirstName' },
					{ name: 'Drivers.0.LastName' },
					{ name: 'Drivers.0.Gender' },
					{ name: 'Drivers.0.MaritalStatus' },
					{ name: 'Drivers.0.DriverLicenseStatus' },
					{ name: 'Drivers.0.DOB' },
					{ name: 'Drivers.0.DriverEmploymentIndustry' },
					{ name: 'Drivers.0.DriverOccupationStr' },
					{ name: 'Drivers.0.DriverEducation' },
				],
			},
			{
				name: StepsEnum.DRIVERS,
				visited: false,
				stepProperties: {
					phoneVisible: false,
					progressMeter: {
						visible: true,
						group: StepGroup.drivers,
						flowType: FlowType.qq,
						multiLobGroup: StepGroup.auto,
					},
				},
				requiredFields: [
					{ name: 'FirstName', isReproducible: 'Drivers' },
					{ name: 'LastName', isReproducible: 'Drivers' },
					{ name: 'Gender', isReproducible: 'Drivers' },
					{ name: 'MaritalStatus', isReproducible: 'Drivers' },
					{ name: 'DriverLicenseStatus', isReproducible: 'Drivers' },
					{ name: 'DOB', isReproducible: 'Drivers' },
					{ name: 'DriverEmploymentIndustry', isReproducible: 'Drivers' },
					{ name: 'DriverOccupationStr', isReproducible: 'Drivers' },
					{ name: 'DriverEducation', isReproducible: 'Drivers' },
					{ name: 'DriverRelationshipToDriver1', isReproducible: 'Drivers', conditions: 'data.SequenceNum != 0' },
				],
			},
			{
				name: StepsEnum.VEHICLES,
				visited: false,
				stepProperties: {
					phoneVisible: false,
					progressMeter: {
						visible: true,
						group: StepGroup.vehicles,
						multiLobGroup: StepGroup.auto,
						flowType: FlowType.qq,
					},
				},
				requiredFields: [
					{ name: 'PLYear', isReproducible: 'PersonalVehicles' },
					{ name: 'PLMake', isReproducible: 'PersonalVehicles' },
					{ name: 'PLModel', isReproducible: 'PersonalVehicles' },
					{ name: 'BodyStyle', isReproducible: 'PersonalVehicles' },
					{ name: 'NumberOfMiles', isReproducible: 'PersonalVehicles' },
					{ name: 'OwnershipType', isReproducible: 'PersonalVehicles' },
				],
			},
			{
				name: StepsEnum.DRIVER_HISTORY,
				visited: false,
				stepProperties: {
					phoneVisible: false,
					progressMeter: {
						visible: true,
						group: StepGroup.history,
						flowType: FlowType.qq,
						multiLobGroup: StepGroup.auto,
					},
				},
				requiredFields: [
					{
						name: 'HasAccidents',
						isReproducible: 'Drivers',
						childQuestions: [
							{ name: 'Description', isReproducible: 'Accidents' },
							{ name: 'DateOfAccident', isReproducible: 'Accidents' },
						],
					},
					{
						name: 'HasViolations',
						isReproducible: 'Drivers',
						childQuestions: [
							{ name: 'Description', isReproducible: 'Violations' },
							{ name: 'DateOfViolation', isReproducible: 'Violations' },
						],
					},
					{
						name: 'HasAutoLosses',
						isReproducible: 'Drivers',
						childQuestions: [
							{ name: 'AutoLossesDescription', isReproducible: 'Losses' },
							{ name: 'AutoLossesDate', isReproducible: 'Losses' },
						],
					},
				],
			},
			{
				name: StepsEnum.AUTO_COVERAGES,
				visited: false,
				stepProperties: {
					phoneVisible: false,
					progressMeter: {
						visible: true,
						group: StepGroup.history,
						multiLobGroup: StepGroup.auto,
						flowType: FlowType.qq,
					},
				},
				requiredFields: [
					{ name: 'BI' },
					{ name: 'PersonalVehicles.0.CollDeductible' },
					{ name: 'PersonalVehicles.0.CompDeductible' },
				],
			},
			...home_cross_sale_stage,
			{
				...GeneralSteps.START_DATE,
				stepProperties: {
					...GeneralSteps.START_DATE.stepProperties,
					progressMeter: {
						...GeneralSteps.START_DATE?.stepProperties?.progressMeter,
						multiLobGroup: StepGroup.home,
						flowType: FlowType.qq,
					},
				},
			},
			GeneralSteps.CONFIRMATION_EMAIL_SENT,
			{
				...GeneralSteps.RESULT,
				stepProperties: {
					phoneVisible: true,
					progressMeter: {
						visible: true,
						group: StepGroup.quotes,
						flowType: FlowType.qq,
						multiLobGroup: StepGroup.quotes,
					},
				},
			},
			...stillwater_steps,
			...lemonade_steps,
			...safeco_steps,
			...progressive_steps,
			{
				name: StepsEnum.QUOTE_CONFIRMATION,
				visited: false,
				stepProperties: {
					phoneVisible: true,
					progressMeter: {
						visible: true,
						flowType: FlowType.fq,
						group: StepGroup.confirmation,
					},
				},
			},
			{
				name: StepsEnum.PAYMENT_PLAN,
				visited: false,
				stepProperties: {
					phoneVisible: true,
					progressMeter: {
						visible: true,
						flowType: FlowType.fq,
						group: StepGroup.payment,
					},
				},
			},
			{
				name: StepsEnum.PAYMENT,
				checkCondition: true,
				visited: false,
				stepProperties: {
					phoneVisible: true,
					progressMeter: {
						visible: true,
						flowType: FlowType.fq,
						group: StepGroup.payment,
					},
				},
			},
			{
				name: StepsEnum.PAYMENT_ESCROW,
				checkCondition: true,
				visited: false,
				stepProperties: {
					phoneVisible: true,
					progressMeter: {
						visible: true,
						flowType: FlowType.fq,
						group: StepGroup.payment,
					},
				},
			},
			{
				name: StepsEnum.PAYMENT_RESULT,
				visited: false,
				stepProperties: {
					phoneVisible: true,
					progressMeter: {
						visible: true,
						flowType: FlowType.fq,
						group: StepGroup.complete,
					},
				},
			},
			{
				name: StepsEnum.SUCCESS_PAGE_ESCROW,
				visited: false,
				stepProperties: {
					phoneVisible: true,
					progressMeter: {
						visible: true,
						flowType: FlowType.fq,
						group: StepGroup.complete,
					},
				},
			},
		],
		[LobsEnum.PETS]: [
			...init,
			{
				name: StepsEnum.PET_DETAILS,
				visited: false,
				stepProperties: {
					phoneVisible: false,
					progressMeter: {
						visible: false,
						flowType: FlowType.qq,
					},
				},
				requiredFields: [
					{ name: 'PLPetSpecies', isReproducible: 'Pets' },
					{ name: 'PLPetName', isReproducible: 'Pets' },
					{ name: 'PLPetBreedType', isReproducible: 'Pets' },
					{ name: 'PLPetBreed', isReproducible: 'Pets' },
					{ name: 'PLPetGender', isReproducible: 'Pets' },
					{ name: 'PetDOB', isReproducible: 'Pets' },
					{ name: 'PLSpayedNeutered', isReproducible: 'Pets' },
					{ name: 'PLHasDisease', isReproducible: 'Pets' },
				],
			},
			{
				name: StepsEnum.PERSONAL_DETAILS,
				visited: false,
				stepProperties: {
					phoneVisible: false,
					progressMeter: {
						visible: false,
						flowType: FlowType.qq,
					},
				},
				requiredFields: [
					{ name: 'CreditCheckPermission' },
					{ name: 'FirstName' },
					{ name: 'LastName' },
					{ name: 'Email' },
					{ name: 'PrimaryPhoneNumber' },
					{ name: 'IAgreeToReceiveEmailsByBolt' },
				],
			},
			{
				name: StepsEnum.POLICY_DATEPICKER,
				visited: false,
				stepProperties: {
					phoneVisible: false,
					progressMeter: {
						visible: false,
						flowType: FlowType.qq,
					},
				},
				requiredFields: [{ name: 'EffectiveDate' }],
			},
			GeneralSteps.CONFIRMATION_EMAIL_SENT,
			{
				...GeneralSteps.RESULT,
				stepProperties: {
					...GeneralSteps.RESULT.stepProperties,
					progressMeter: {
						visible: false,
						flowType: FlowType.qq,
					},
				},
			},
			...stillwater_steps,
			...lemonade_steps,
			...safeco_steps,
			{
				name: StepsEnum.QUOTE_CONFIRMATION,
				visited: false,
				stepProperties: {
					phoneVisible: true,
					progressMeter: {
						visible: true,
						flowType: FlowType.fq,
						group: StepGroup.confirmation,
					},
				},
			},
			{
				name: StepsEnum.PAYMENT_PLAN,
				visited: false,
				stepProperties: {
					phoneVisible: true,
					progressMeter: {
						visible: true,
						flowType: FlowType.fq,
						group: StepGroup.payment,
					},
				},
			},
			{
				name: StepsEnum.PAYMENT,
				checkCondition: true,
				visited: false,
				stepProperties: {
					phoneVisible: true,
					progressMeter: {
						visible: true,
						flowType: FlowType.fq,
						group: StepGroup.payment,
					},
				},
			},
			{
				name: StepsEnum.PAYMENT_ESCROW,
				checkCondition: true,
				visited: false,
				stepProperties: {
					phoneVisible: true,
					progressMeter: {
						visible: true,
						flowType: FlowType.fq,
						group: StepGroup.payment,
					},
				},
			},
			{
				name: StepsEnum.PAYMENT_RESULT,
				visited: false,
				stepProperties: {
					phoneVisible: true,
					progressMeter: {
						visible: true,
						flowType: FlowType.fq,
						group: StepGroup.complete,
					},
				},
			},
			{
				name: StepsEnum.SUCCESS_PAGE_ESCROW,
				visited: false,
				stepProperties: {
					phoneVisible: true,
					progressMeter: {
						visible: true,
						flowType: FlowType.fq,
						group: StepGroup.complete,
					},
				},
			},
		],
		[LobsEnum.HOME_AUTO]: [
			...init,
			{
				name: StepsEnum.PROPERTIES,
				visited: false,
				stepProperties: {
					phoneVisible: false,
					progressMeter: {
						visible: true,
						multiLobGroup: StepGroup.home,
						flowType: FlowType.qq,
					},
				},
				requiredFields: [{ name: 'PLTypeOfDwelling' }],
			},
			{
				name: StepsEnum.PRIMARY_RESIDENCE,
				visited: false,
				stepProperties: {
					phoneVisible: false,
					progressMeter: {
						visible: true,
						multiLobGroup: StepGroup.home,
						flowType: FlowType.qq,
					},
				},
				requiredFields: [{ name: 'OccupancyType' }],
			},
			{
				name: StepsEnum.PROPERTY_USAGE,
				checkCondition: true,
				visited: false,
				stepProperties: {
					phoneVisible: false,
					progressMeter: {
						visible: true,
						flowType: FlowType.qq,
						multiLobGroup: StepGroup.home,
					},
				},
			},
			{
				name: StepsEnum.HOUSE_DETAILS,
				visited: false,
				stepProperties: {
					phoneVisible: false,
					progressMeter: {
						visible: true,
						multiLobGroup: StepGroup.home,
						flowType: FlowType.qq,
					},
				},
			},
			{
				name: StepsEnum.ROOF_REPLACEMENT,
				checkCondition: true,
				visited: false,
				stepProperties: {
					phoneVisible: false,
					progressMeter: {
						visible: true,
						multiLobGroup: StepGroup.home,
						flowType: FlowType.qq,
					},
				},
			},
			{
				name: StepsEnum.SAFETY_ALARMS,
				visited: false,
				stepProperties: {
					phoneVisible: false,
					progressMeter: {
						visible: true,
						multiLobGroup: StepGroup.home,
						flowType: FlowType.qq,
					},
				},
				requiredFields: [
					{ name: 'BurglarAlarm', childQuestions: [{ name: 'BurglarAlarmType' }] },
					{ name: 'DeadBoltLocks' },
					{ name: 'FireDetection', childQuestions: [{ name: 'FireDetectionType' }] },
					{ name: 'FireExtinguisher' },
					{ name: 'SmokeDetector' },
					{
						name: 'SprinklerSystem',
						childQuestions: [{ name: 'SprinklerSystemType' }],
						relevantLobs: [LobsEnum.PERSONAL_HOME],
					},
				],
			},
			{
				name: StepsEnum.PERSONAL_DETAILS_BUNDLE,
				visited: false,
				stepProperties: {
					phoneVisible: false,
					progressMeter: {
						visible: true,
						multiLobGroup: StepGroup.home,
						flowType: FlowType.qq,
					},
				},
				requiredFields: [
					{ name: 'CreditCheckPermission' },
					{ name: 'PersonalLineGender' },
					{ name: 'MaritalStatus' },
					{ name: 'Email' },
					{ name: 'PrimaryPhoneNumber' },
					{ name: 'DateOfBirth' },
					{ name: 'IAgreeToReceiveEmailsByBolt' },
					{ name: 'Drivers.0.DriverLicenseStatus' },
					{ name: 'Drivers.0.DriverEmploymentIndustry' },
					{ name: 'Drivers.0.DriverOccupationStr' },
					{ name: 'Drivers.0.DriverEducation' },
				],
			},
			{
				name: StepsEnum.ALMOST_THERE,
				visited: false,
				stepProperties: {
					phoneVisible: false,
					progressMeter: {
						visible: true,
						multiLobGroup: StepGroup.home,
						flowType: FlowType.qq,
					},
				},
			},
			{
				name: StepsEnum.DRIVERS,
				visited: false,
				stepProperties: {
					phoneVisible: false,
					progressMeter: {
						visible: true,
						flowType: FlowType.qq,
						multiLobGroup: StepGroup.auto,
					},
				},
				requiredFields: [
					{ name: 'FirstName', isReproducible: 'Drivers' },
					{ name: 'LastName', isReproducible: 'Drivers' },
					{ name: 'Gender', isReproducible: 'Drivers' },
					{ name: 'MaritalStatus', isReproducible: 'Drivers' },
					{ name: 'DriverLicenseStatus', isReproducible: 'Drivers' },
					{ name: 'DOB', isReproducible: 'Drivers' },
					{ name: 'DriverEmploymentIndustry', isReproducible: 'Drivers' },
					{ name: 'DriverOccupationStr', isReproducible: 'Drivers' },
					{ name: 'DriverEducation', isReproducible: 'Drivers' },
				],
			},
			{
				name: StepsEnum.VEHICLES,
				visited: false,
				stepProperties: {
					phoneVisible: false,
					progressMeter: {
						visible: true,
						multiLobGroup: StepGroup.auto,
						flowType: FlowType.qq,
					},
				},
				requiredFields: [
					{ name: 'PLYear', isReproducible: 'PersonalVehicles' },
					{ name: 'PLMake', isReproducible: 'PersonalVehicles' },
					{ name: 'PLModel', isReproducible: 'PersonalVehicles' },
					{ name: 'BodyStyle', isReproducible: 'PersonalVehicles' },
					{ name: 'NumberOfMiles', isReproducible: 'PersonalVehicles' },
					{ name: 'OwnershipType', isReproducible: 'PersonalVehicles' },
				],
			},
			{
				name: StepsEnum.DRIVER_HISTORY,
				visited: false,
				stepProperties: {
					phoneVisible: false,
					progressMeter: {
						visible: true,
						multiLobGroup: StepGroup.auto,
						flowType: FlowType.qq,
					},
				},
				requiredFields: [
					{
						name: 'HasAccidents',
						isReproducible: 'Drivers',
						childQuestions: [
							{ name: 'Description', isReproducible: 'Accidents' },
							{ name: 'DateOfAccident', isReproducible: 'Accidents' },
						],
					},
					{
						name: 'HasViolations',
						isReproducible: 'Drivers',
						childQuestions: [
							{ name: 'Description', isReproducible: 'Violations' },
							{ name: 'DateOfViolation', isReproducible: 'Violations' },
						],
					},
					{
						name: 'HasAutoLosses',
						isReproducible: 'Drivers',
						childQuestions: [
							{ name: 'AutoLossesDescription', isReproducible: 'Losses' },
							{ name: 'AutoLossesDate', isReproducible: 'Losses' },
						],
					},
				],
			},
			{
				name: StepsEnum.AUTO_COVERAGES,
				visited: false,
				stepProperties: {
					phoneVisible: false,
					progressMeter: {
						visible: true,
						multiLobGroup: StepGroup.auto,
						flowType: FlowType.qq,
					},
				},
				requiredFields: [
					{ name: 'BI' },
					{ name: 'CollDeductible', isReproducible: 'PersonalVehicles' },
					{ name: 'CompDeductible', isReproducible: 'PersonalVehicles' },
				],
			},
			{
				...GeneralSteps.START_DATE,
				stepProperties: {
					phoneVisible: false,
					progressMeter: {
						visible: true,
						multiLobGroup: StepGroup.auto,
						flowType: FlowType.qq,
					},
				},
				requiredFields: [{ name: 'EffectiveDate' }],
			},
			GeneralSteps.CONFIRMATION_EMAIL_SENT,
			{
				...GeneralSteps.RESULT,
				stepProperties: {
					phoneVisible: true,
					progressMeter: {
						visible: true,
						multiLobGroup: StepGroup.quotes,
						flowType: FlowType.qq,
					},
				},
			},
			...stillwater_steps,
			...lemonade_steps,
			...safeco_steps,
			{
				name: StepsEnum.QUOTE_CONFIRMATION,
				visited: false,
				stepProperties: {
					phoneVisible: true,
					progressMeter: {
						visible: true,
						flowType: FlowType.fq,
						group: StepGroup.confirmation,
					},
				},
			},
			{
				name: StepsEnum.PAYMENT_PLAN,
				visited: false,
				stepProperties: {
					phoneVisible: true,
					progressMeter: {
						visible: true,
						flowType: FlowType.fq,
						group: StepGroup.payment,
					},
				},
			},
			{
				name: StepsEnum.PAYMENT,
				visited: false,
				stepProperties: {
					phoneVisible: true,
					progressMeter: {
						visible: true,
						flowType: FlowType.fq,
						group: StepGroup.payment,
					},
				},
			},
			{
				name: StepsEnum.PAYMENT_RESULT,
				visited: false,
				stepProperties: {
					phoneVisible: true,
					progressMeter: {
						visible: true,
						flowType: FlowType.fq,
						group: StepGroup.complete,
					},
				},
			},
			{
				name: StepsEnum.SUCCESS_PAGE_ESCROW,
				visited: false,
				stepProperties: {
					phoneVisible: true,
					progressMeter: {
						visible: true,
						flowType: FlowType.fq,
						group: StepGroup.complete,
					},
				},
			},
		],
	},
};
