import { Component, OnDestroy, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { LobsEnum } from 'src/app/enums/lobs.enum';
import { ActionApiService } from 'src/app/services/action-api.service';
import { ProgressMeterService } from 'src/app/services/progress-meter.service';
import { LoaderActions } from 'src/app/store/actions';
import { updateApplication } from 'src/app/store/actions/api.actions';
import { UpdateInterviewMetadata, UpdateLobsInitialized } from 'src/app/store/actions/interview-metadata.actions';
import { selectApplicationId, selectInterviewMetadata } from 'src/app/store/selectors/interview-metadata.selector';
import { selectLoaderInformation } from 'src/app/store/selectors/loader-information.selector';
import { selectQuoteData } from 'src/app/store/selectors/quote-data.selectors';
import { IAppState } from 'src/app/store/states/app.state';
import { ILoaderInformationState } from 'src/app/store/states/loader-information.state';
import { LobService } from 'src/app/services/lob.service';
import { UpdateQuoteData } from 'src/app/store/actions/quote-data.actions';
import { getD2CFeatures, isMultiLob } from 'src/app/utils/general.utils';

@UntilDestroy()
@Component({
	selector: 'app-primary-residence',
	templateUrl: './primary-residence.component.html',
	styleUrls: ['./primary-residence.component.scss'],
})
export class PrimaryResidenceComponent implements OnInit, OnDestroy {
	typeProperty = '';
	primaryResidence = '';
	loaderInformation$: Observable<ILoaderInformationState>;
	address;
	lobs: string[] = [];
	flowPerTypeOfDwellingGenerated = false;
	OccupancyType: string;
	selectedLobs: any[] = [];
	lobSelection: any[] = [];

	constructor(
		protected store: Store<IAppState>,
		private progressMeterService: ProgressMeterService,
		private actionApi: ActionApiService,
		private lobService: LobService
	) {
		this.loaderInformation$ = this.store.select(selectLoaderInformation);
	}

	ngOnInit(): void {
		combineLatest([this.store.select(selectInterviewMetadata), this.store.select(selectQuoteData)])
			.pipe(untilDestroyed(this))
			.subscribe(([interviewMetadata, quoteData]) => {
				this.typeProperty = interviewMetadata._typeProperty;
				this.primaryResidence = interviewMetadata._primaryResidence;
				this.lobSelection = interviewMetadata.lobSelection;
				this.OccupancyType = quoteData.OccupancyType;
				this.address = { PropertyAddress: quoteData.PropertyAddress };
				this.lobs = quoteData.Lobs;
			});
	}

	handleSelect(value) {
		if (value !== this.primaryResidence) {
			this.primaryResidence = value;
			this.store.dispatch(UpdateInterviewMetadata({ data: { _primaryResidence: this.primaryResidence } }));
		}
	}

	handleContinue() {
		let selectedLobs = this.lobSelection.filter(
			(lob) => lob === LobsEnum.PERSONAL_HOME || lob === LobsEnum.CONDOMINIUM || lob === LobsEnum.DWELLING_FIRE
		);

		if (selectedLobs[0] === LobsEnum.DWELLING_FIRE && this.primaryResidence === 'yes') {
			selectedLobs[0] = this.typeProperty === 'PersonalHome' ? LobsEnum.PERSONAL_HOME : LobsEnum.CONDOMINIUM;
		}

		let isFloodCrossSellActive = getD2CFeatures()?.floodCoverage;

		this.lobService
			.getLob$()
			.pipe(first())
			.subscribe((lob) => {
				if (lob === LobsEnum.HOME_AUTO) {
					selectedLobs.push(LobsEnum.PERSONAL_AUTO);
				} else {
					isFloodCrossSellActive && selectedLobs.push(LobsEnum.FLOOD);
				}
			});
		/*
		 * for example: previous lob was home and changed to condo and primary Residence selected 'yes'
		 * create new application
		 */
		if (this.primaryResidence === 'yes') {
			const isLobDifferent = this.lobService.checkIsLobDifferent(selectedLobs, this.lobs);

			this.store
				.select(selectApplicationId)
				.pipe(first())
				.subscribe((appId) => {
					// need to check if there is applicationId
					this.store.dispatch(LoaderActions.ShowLoader());
					if (appId) {
						this.actionApi
							.updateApplication(this.addAdditionalData(this.address), isLobDifferent ? selectedLobs : null)
							.subscribe((res) => {
								this.store.dispatch(UpdateQuoteData({ data: res['data'] }));

								if (isLobDifferent) {
									this.store.dispatch(
										UpdateInterviewMetadata({
											data: {
												isMultiLob: isMultiLob(selectedLobs),
												lobsInitialized: selectedLobs.filter((lob) => lob !== LobsEnum.FLOOD),
												lobSelection: selectedLobs,
											},
										})
									);
								}
								this.progressMeterService.hasApatite.next(res['hasApplicationAppetite']);
								// wait until update store is done
								setTimeout(() => {
									this.progressMeterService.goToNextPage();
								}, 0);
							});
					} else {
						// There is no application ID so need to init quote
						this.initApp(selectedLobs);
					}
				});
		} else {
			this.store.dispatch(
				UpdateInterviewMetadata({
					data: { lobSelection: selectedLobs, isMultiLob: isMultiLob(selectedLobs) },
				})
			);
			this.progressMeterService.goToNextPage();
		}
	}

	initApp(selectedLobs: string[]) {
		this.actionApi.initApplication(selectedLobs, this.address.PropertyAddress).subscribe((res) => {
			selectedLobs = selectedLobs.filter((lob) => lob !== LobsEnum.FLOOD);
			this.store.dispatch(
				UpdateInterviewMetadata({
					data: {
						applicationId: res.initial.applicationId,
						friendlyId: res.initial.friendlyId,
						hasApplicationAppetite: res.initial.hasApplicationAppetite,
						isMultiLob: isMultiLob(selectedLobs),
					},
				})
			);
			setTimeout(() => {
				// wait until update store is done and we have applicationId to fire saveFLow in policyAttachment it need the applicationId
				this.store.dispatch(UpdateLobsInitialized({ lobsInitialized: selectedLobs }));
			}, 0);
			// update app with address and this page questions and move to next page
			this.store.dispatch(updateApplication({ formData: this.addAdditionalData(this.address), page: true }));
		});
	}

	addAdditionalData(data?) {
		const additionalData: any = {};

		if (this.primaryResidence === 'yes') {
			additionalData.OccupancyType = 'OwnerPrimary';
		}

		if (this.typeProperty === LobsEnum.CONDOMINIUM) {
			additionalData.PLTypeOfDwelling = LobsEnum.CONDOMINIUM;
		}

		return { ...data, ...additionalData };
	}

	ngOnDestroy(): void {
		this.store.dispatch(LoaderActions.Hideloader());
	}
}
