import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { untilDestroyed } from '@ngneat/until-destroy';
import { IDropdownOption } from 'src/app/components/form-controls/form-control-interfaces';
import { IndustryModel } from 'src/entities/industry-api-response.interface';
import { Dictionary } from 'src/entities/key-value.interface';
import { Location } from '@angular/common';
import { Validations } from 'src/app/utils/validation';
import { NextButtonComponent } from 'src/app/components/next-button/next-button.component';
import { ActionApiService } from 'src/app/services/action-api.service';
import { BasePageComponent } from '../../../../../base-page.component';
import { QuoteDataService } from 'src/app/services/quote-data.service';
import { IAppState } from 'src/app/store/states/app.state';
import { selectQuoteData } from 'src/app/store/selectors/quote-data.selectors';
import { StepsEnum } from 'src/app/enums/steps.enum';
import { Fields } from 'src/app/constants/fields';
import {
	convertJsonToArray,
	getDOBforDriver,
	maskingLicenseNumber,
	sortByProperty,
} from 'src/app/utils/general.utils';
import { PrebindService } from 'src/app/services/pre-bind.service';
import { selectInterviewMetadata } from 'src/app/store/selectors/interview-metadata.selector';
import { CarriersEnum } from 'src/app/enums/carriers.enum';
import { LobsEnum } from 'src/app/enums/lobs.enum';
import { FormattedDateService } from 'src/app/services/formatted-date.service';
import { showPopupAction } from 'src/app/store/actions/popup.actions';
import { DriversService } from 'src/app/services/drivers.service';

@Component({
	selector: 'app-progressive-drivers',
	templateUrl: './progressive-drivers.component.html',
	styleUrls: ['./progressive-drivers.component.scss'],
})
export class ProgressiveDriversComponent extends BasePageComponent implements OnInit {
	@ViewChild(NextButtonComponent, { static: true }) nextButtonComponent: NextButtonComponent;
	actionApiService: ActionApiService;
	isLoading: any;

	drivers = [];
	activeTabIndex = 0;
	index = 0;
	StepsEnum = StepsEnum;
	preBindProcess: boolean;
	showPreBindLoader = false;
	phoneNumberMask = '999-999-9999';

	constructor(
		injector: Injector,
		protected quoteDataService: QuoteDataService,
		protected store: Store<IAppState>,
		actionApiService: ActionApiService,
		private location: Location,
		private preBindService: PrebindService,
		private formattedDateService: FormattedDateService,
		private driversService: DriversService
	) {
		super(injector, store, quoteDataService);
		this.actionApiService = actionApiService;
	}

	get driversFormControl(): FormArray {
		return this.form.controls.additionalQuestionsProgressiveDrivers as FormArray;
	}

	

	ngOnInit(): void {
		super.ngOnInit();
		this.initForm();

		setTimeout(() => {
			this.patchData(this.form);
			this.registerOnChange(this.form);

			this.store
				.select(selectQuoteData)
				.pipe(first())
				.subscribe((quoteData) => {
					const driverHistory =
						quoteData.additionalQuestionsProgressiveDrivers && quoteData.additionalQuestionsProgressiveDrivers.length
							? quoteData.additionalQuestionsProgressiveDrivers
							: [];

					const Drivers =
						quoteData.Drivers && quoteData.Drivers.length
							? quoteData.Drivers.filter((driver) => driver.active == true)
							: [];

					const discoverDrivers =
						quoteData.discoverDrivers && quoteData.discoverDrivers.length
							? quoteData.discoverDrivers.filter((driver) => driver.active == true)
							: [];

					const manualDrivers =
						quoteData.manualDrivers && quoteData.manualDrivers.length
							? quoteData.manualDrivers.filter((driver) => driver.active == true)
							: [];

					this.drivers = sortByProperty([...Drivers, ...discoverDrivers, ...manualDrivers], 'SequenceNum');
					this.drivers.forEach((driver, index) => {
						const driverHistoryItem = driverHistory.find((item) => item.Id === driver.Id);
						this.driversFormControl.push(
							this.createDriverForm(
								driverHistoryItem ? driverHistoryItem : driver,
								getDOBforDriver(driver.Id, quoteData)
							)
						);
					});
				});
		});
	}

	createDriverForm(driver, DOB): FormGroup {
		const formGroup = this.formBuilder.group({
			Id: driver.Id,
			SequenceNum: driver.SequenceNum ? driver.SequenceNum : 0,
			FirstName: driver.FirstName,
			LastName: driver.LastName,
			DriverStateLicensed: [
				{
					value: driver.DriverStateLicensed ? driver.DriverStateLicensed : null,
					disabled: driver.DriverLicenseStatus == 'NotLicensed',
				},
				[Validators.required],
			],
			FQLicenseClass: [{ value: driver.FQLicenseClass || null, disabled: false }, [Validators.required]],
			
			FQMatriculaCard: [
				{
					value: typeof driver.FQMatriculaCard == 'boolean' ? driver.FQMatriculaCard : null,
					disabled: driver.DriverStateLicensed !== 'Mexico',
				},
				[Validators.required],
			],
			FQMatriculaQualifiedToDrive: [
				{
					value: typeof driver.FQMatriculaQualifiedToDrive == 'boolean' ? driver.FQMatriculaQualifiedToDrive : null,
					disabled: driver.DriverStateLicensed !== 'Mexico' || !driver.FQMatriculaCard,
				},
				[Validators.required],
			],

			DriversLicenseBeenSuspendedOrRevoked: [
				typeof driver.DriversLicenseBeenSuspendedOrRevoked == 'boolean'
					? driver.DriversLicenseBeenSuspendedOrRevoked
					: null,
				[Validators.required],
			],
			IsGoodStudent: [
				{
					value: typeof driver.IsGoodStudent == 'boolean' ? driver.IsGoodStudent : null,
					disabled:
						this.disableStudentFields(driver, DOB) ||
						driver.SequenceNum == 0 ||
						(driver.DriverRelationshipToDriver1 && driver.DriverRelationshipToDriver1 == 'Spouse'),
				},
				[Validators.required],
			],
			FQIsStudent100Miles: [
				{
					value: typeof driver.FQIsStudent100Miles == 'boolean' ? driver.FQIsStudent100Miles : null,
					disabled: this.disableStudentFields(driver, DOB),
				},
				[Validators.required],
			],

			IsDefensiveDriver: [
				{
					value: typeof driver.IsDefensiveDriver == 'boolean' ? driver.IsDefensiveDriver : null,
					disabled: driver.DriverLicenseStatus !== 'NotLicensed',
				},
				[Validators.required],
			],
			DefensiveDriverCourseDate: [
				{
					value: driver.DefensiveDriverCourseDate ? driver.DefensiveDriverCourseDate : null,
					disabled: driver.DriverLicenseStatus !== 'NotLicensed' || !driver.IsDefensiveDriver,
				},
				[Validators.required, Validations.dateFormat, Validations.isDefensiveFutureDate],
			],
			DriverLicenseStatus: [{ value: driver.DriverLicenseStatus || null, disabled: true }],
			DriverLicenseNumber: [
				{
					value: driver.DriverLicenseNumber ? maskingLicenseNumber(driver.DriverLicenseNumber) : null,
					disabled: driver.DriverLicenseStatus == 'NotLicensed',
				},
				[Validators.required],
			],
			DriverDateLicensed: [
				{
					value: this.formattedDateService.formatDateIfItIsNotMasked(driver.DriverDateLicensed),
					disabled: driver.DriverLicenseStatus == 'NotLicensed',
				},
				[Validators.required, Validations.dateFormat],
			],
			FQDriverRatingType: [
				driver.DriverLicenseStatus == 'NotLicensed' ? 'Excluded' : driver.FQDriverRatingType || null,
				[Validators.required],
			],
			DriverRelationshipToDriver1: [
				{
					value: driver.DriverRelationshipToDriver1,
					disabled: true,
				},
			],
		}) as FormGroup;

		formGroup.addControl('Filings', convertJsonToArray(driver.Filings ? driver.Filings : []));

		this.driversService.editFilings.pipe(untilDestroyed(this)).subscribe((index) => {
			if (index) {
				(<FormGroup>(
					(<FormArray>this.form.controls.additionalQuestionsProgressiveDrivers).at(index['index'])
				)).setControl('Filings', convertJsonToArray(index['Filings']));
			}
		});

		this.store
			.select(selectQuoteData)
			.pipe(first())
			.subscribe((data) => {
				if (data['FQData.ProgressivePersonalAuto.UBIExperienceEnrollment'] !== 'DNP') {
					// 4.displaying SnapshotMobileNumber field only if UBIExperienceEnrollment is 'Mobile'.
					formGroup.addControl(
						Fields.SnapshotPhoneNumber.name,
						new FormControl(driver[Fields.SnapshotPhoneNumber.name] ? driver[Fields.SnapshotPhoneNumber.name] : null, [
							Validators.required,
							Validations.validSnapshotNumber,
						])
					);
				}
			});

		
		if (driver.SequenceNum == 0) {
			formGroup.controls.FQLicenseClass.addValidators(Validations.LicenseStatusForPrimaryDriver);
		}

		formGroup.controls.DriverStateLicensed?.valueChanges.subscribe((state) => {
			state == 'Mexico' ? formGroup.controls.FQMatriculaCard.enable() : formGroup.controls.FQMatriculaCard.disable();
			state == 'Mexico' && formGroup.controls.FQMatriculaCard.value
				? formGroup.controls.FQMatriculaQualifiedToDrive.enable()
				: formGroup.controls.FQMatriculaQualifiedToDrive.disable();
		});

		formGroup.controls.FQMatriculaCard?.valueChanges.subscribe((matriculaCard) => {
			matriculaCard
				? formGroup.controls.FQMatriculaQualifiedToDrive.enable()
				: formGroup.controls.FQMatriculaQualifiedToDrive.disable();
		});

		formGroup.controls.IsDefensiveDriver?.valueChanges.subscribe((isDefensiveDriver) => {
			isDefensiveDriver
				? formGroup.controls.DefensiveDriverCourseDate.enable()
				: formGroup.controls.DefensiveDriverCourseDate.disable();
		});

		return formGroup;
	}

	getDriverHistoryForm(index: number) {
		return this.driversFormControl.controls[index] as FormGroup;
	}

	initForm(): void {
		this.form = new FormGroup({
			additionalQuestionsProgressiveDrivers: new FormArray([]),
		});
	}

	goBack() {
		if (this.activeTabIndex > 0) {
			this.activeTabIndex = this.activeTabIndex - 1;
		} else {
			this.location.back();
		}
		this.eventsService.addEvent(this.EventActions.BUTTON_CLICK, {
			label: 'go-back',
			button: 'go-back-progressive-drivers',
			module: 'progressive-drivers-module',
		});
	}

	activateTab(index: number) {
		this.activeTabIndex = index;
	}

	isLastTabActive(): boolean {
		return this.activeTabIndex === this.drivers.length - 1;
	}

	handleContinue() {
		if (this.activeTabIndex === this.drivers.length - 1) {
			this.showPreBindLoader = true;

			const drivers = this.driversFormControl.value.map((driver) =>
				this.driversService.updateDriverFieldsByFilings(driver)
			);

			this.actionApiService
				.updateApplication({ Drivers: drivers }, [LobsEnum.PERSONAL_AUTO], CarriersEnum.Progressive)
				.pipe(first())
				.subscribe((serverRes) => {
					this.quoteDataService.serverQuoteErrors.next(serverRes);

					this.onUpdateApplicationResult(serverRes, this.driversFormControl.controls as FormGroup[], true)
						.pipe(first())
						.subscribe((invalidReproducibleIndex) => {
							if (!this.quoteDataService.hasErrorsFromServerForThisPage.value) {
								this.preBindService.startPrebind(CarriersEnum.Progressive, LobsEnum.PERSONAL_AUTO, this.drivers.length);

								setTimeout(() => {
									this.store
										.select(selectInterviewMetadata)
										.pipe(untilDestroyed(this))
										.subscribe((res) => {
											if (!res.preBindProcess && this.showPreBindLoader) {
												this.progressMeterService.goToNextPage();
												this.showPreBindLoader = false;
											}
										});
								});
							} else {
								this.activeTabIndex = invalidReproducibleIndex;
								this.showPreBindLoader = false;
							}
						});
				});
		} else {
			const nextTabIndex = this.activeTabIndex + 1;
			if (nextTabIndex < this.drivers.length) {
				this.activeTabIndex = nextTabIndex;
			}
		}
	}

	convertJsonToArray(data) {
		if (data.length > 0) {
			return this.formBuilder.array(
				data?.map((r) => {
					return this.formBuilder.group(r);
				})
			);
		} else {
			return this.formBuilder.array([]);
		}
	}

	isTabActive(index: number): boolean {
		return index === this.activeTabIndex;
	}

	disableStudentFields(driver, DOB) {
		return (
			driver.DriverLicenseStatus == 'NotLicensed' ||
			new Date().getFullYear() - Number(DOB.match(/\d{4}/)[0]) >= 23
		);
	}

	openStateFilingPopup(index) {
		this.store.dispatch(
			showPopupAction({
				componentName: 'state-filing',
				payload: {
					text: `popups.titles.state-filing`,
					originPage: 'additionalQuestionsProgressiveDrivers',
					fullScreen: true,
					index: index,
					customClass: 'state-filing full-screen-mobile',
				},
			})
		);
	}

	getActiveFilingsLength(index: number) {
		return this.driversFormControl.controls[index].get('Filings').value.filter((i) => i.SequenceNum !== -1).length;
	}

	

	
}
