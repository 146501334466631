import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { QuoteDataService } from 'src/app/services/quote-data.service';
import { IAppState } from 'src/app/store/states/app.state';
import { BasePageComponent } from '../../base-page.component';
import { Store } from '@ngrx/store';
import { getSelectedCarrier, selectRates } from 'src/app/store/selectors/result-data.selectors';
import { animate, AUTO_STYLE, state, style, transition, trigger } from '@angular/animations';
import { EventActions } from 'src/app/models/event-data.model';
import { PaymentPlansEnum } from 'src/app/enums/payment-plans.enum';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { StepsEnum } from '../../../../../enums/steps.enum';
import { paymentPlanSelect } from 'src/app/store/actions/result-data.actions';
import { CarriersEnum } from '../../../../../enums/carriers.enum';
import { combineLatest, Observable, of } from 'rxjs';
import { LobService } from '../../../../../services/lob.service';
import { ActionApiService } from '../../../../../services/action-api.service';
import { selectInterviewMetadata } from '../../../../../store/selectors/interview-metadata.selector';
import { SetFQReportsHandled } from '../../../../../store/actions/interview-metadata.actions';
import { LobsEnum } from '../../../../../enums/lobs.enum';
import { first } from 'rxjs/operators';

@UntilDestroy()
@Component({
	selector: 'app-payment-plan',
	templateUrl: './payment-plan.component.html',
	styleUrls: ['./payment-plan.component.scss'],
	animations: [
		trigger('collapse', [
			state('false', style({ height: '0', visibility: 'hidden' })),
			state('true', style({ height: AUTO_STYLE, visibility: AUTO_STYLE })),
			transition('false => true', animate('250ms linear')),
			transition('true => false', animate('250ms linear')),
		]),
	],
})
export class PaymentPlanComponent extends BasePageComponent implements OnInit, OnDestroy {
	data = [];
	eventActions = EventActions;
	PaymentPlanTypes;
	selectedPaymentType = '';
	PaymentPlansEnum = PaymentPlansEnum;
	apiInterval: NodeJS.Timer;
	applicationId: string;
	isPaymentVisible = false;
	isFQReportsHandled = false;
	isInitialVisible = false;
	noRates = false;

	constructor(
		injector: Injector,
		protected quoteDataService: QuoteDataService,
		protected store: Store<IAppState>,
		private lobService: LobService,
		private actionApiService: ActionApiService
	) {
		super(injector, store);
	}

	ngOnInit(): void {
		super.ngOnInit();

		if (!this.isEscrowFlow) {
			combineLatest([
				this.store.select(getSelectedCarrier),
				this.store.select(selectInterviewMetadata),
				this.lobService.getLob$(),
			])
				.pipe(first())
				.subscribe(([selectedCarrier, { applicationId, FQReportsHandled }, lob]) => {
					this.updatePaymentPlans();

					this.isPaymentVisible = selectedCarrier !== CarriersEnum.stillwater;
					this.isInitialVisible = true;
					this.applicationId = applicationId;
					this.isFQReportsHandled = FQReportsHandled;

					// check if carrier is Stillwater
					if (!this.isPaymentVisible && lob === LobsEnum.PERSONAL_HOME) {
						const Context = {
							Carrier: selectedCarrier,
							Product: lob,
						};

						// make a submission call with FullQuoteReports type
						this.submissionFullQuoteReports({ ...Context });
					} else {
						this.isPaymentVisible = !!this.data.length;
						this.noRates = !this.data.length;
					}
				});
		} else {
			this.isInitialVisible = true;
		}
	}

	fireSingleSubmission(context: any): Observable<any> {
		return this.isFQReportsHandled
			? of(true)
			: this.actionService.singleSubmitQuote({
					Context: {
						...context,
						Scope: 'FullQuoteReports',
					},
			  });
	}

	submissionFullQuoteReports(context: any): void {
		this.fireSingleSubmission(context).subscribe(() => {
			const intervalFunction = () => {
				this.actionApiService
					.post({
						action: 'api/submissionResults',
						endpoint: `applications`,
						applicationId: this.applicationId,
						Type: 'FullQuoteReports',
						...context,
					})
					.pipe(untilDestroyed(this))
					.subscribe((result: any) => {
						if (!result) {
							this.routingService.navigateToErrorPage(StepsEnum.TECHNICAL_ERROR);
							return;
						}

						if (result.status === 'Completed') {
							clearInterval(this.apiInterval);

							// check if FullQuoteReports response returns rates
							if (result.quotes?.length) {
								// show Escrow payment form
								this.isPaymentVisible = true;
							} else {
								// redirect to error page since rates is empty
								this.noRates = true;
							}

							this.store.dispatch(SetFQReportsHandled({ data: true }));
						}
					});
			};

			intervalFunction();

			this.apiInterval = setInterval(() => {
				intervalFunction();
			}, 5000);
		});
	}

	updatePaymentPlans() {
		this.store.select(selectRates).subscribe((res) => {
			const selectedQuoteIndex = res.findIndex((quote) => quote.selected);
			// TODO: replace rates in store to quotes to be the same from the server
			if (selectedQuoteIndex !== -1) {
				//check if payment plan is selected already before
				//gets all the type which are available
				this.PaymentPlanTypes = this.createPaymentPlansAvailable(res, selectedQuoteIndex);
				let paymentPlans = [...res[selectedQuoteIndex].paymentPlans];
				this.data = paymentPlans.sort((a, b) => {
					if (a.type === 'Escrow') {
						return -1;
					} else if (b.type === 'Escrow') {
						return 1;
					} else {
						return a.totalAmount - b.totalAmount;
					}
				});
			} else {
				this.routingService.navigateToErrorPage(StepsEnum.CARRIER_KICKOUT);
				return;
			}
		});
	}

	id(_, item): string {
		return item.id;
	}

	createPaymentPlansAvailable(paymentPlans, selectedQuoteIndex) {
		let paymentPlansTypes = [
			...new Set(
				paymentPlans[selectedQuoteIndex].paymentPlans.map((paymentPlanOption) => {
					return paymentPlanOption.type;
				})
			),
		];
		if (paymentPlansTypes.includes('EFT')) {
			paymentPlansTypes.sort(function (x, y) {
				return x == 'EFT' ? -1 : y == 'EFT' ? 1 : 0;
			});
		}

		if (paymentPlansTypes.includes('Escrow')) {
			paymentPlansTypes.sort(function (x, y) {
				return x == 'Escrow' ? -1 : y == 'Escrow' ? 1 : 0;
			});
		}
		return paymentPlansTypes;
	}

	handleTogglePaymentType(type) {
		this.selectedPaymentType = type;
	}

	isPaymentPlanSelected() {
		return !(
			[...new Set(this.data.map((i) => i.selected))].length == 1 &&
			[...new Set(this.data.map((i) => i.selected))][0] == false
		);
	}

	handleSelectPaymentPlan(data) {
		this.store.dispatch(paymentPlanSelect({ selectedPaymentPlan: data }));
	}
}
