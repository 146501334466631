<section class="main-content main-content-personal-details">
	<app-page-heading [title]="'titles.policyOwnerName' | translate"></app-page-heading>

	<form #myform="ngForm" [formGroup]="form" class="applicant-form stage">
		<div class="questions-container">
			<p class="basic-text">{{ "titles.basicInformation" | translate }}</p>

			<app-question-wrapper
				[show]="form.get(Fields.FirstName.name) && form.get(Fields.FirstName.name).enabled"
				class="first-name"
				icon="person"
			>
				<unmasked-input
					*ngIf="form.get(Fields.FirstName.name) && form.get(Fields.FirstName.name).enabled"
					[name]="Fields.FirstName.name"
					[pattern]="Fields.FirstName.pattern"
					[placeholder]="'personalInfo.firstName' | translate"
					maxlength="50"
					type="text"
				>
				</unmasked-input>
			</app-question-wrapper>

			<app-question-wrapper
				[show]="form.get(Fields.LastName.name) && form.get(Fields.LastName.name).enabled"
				class="last-name"
				icon="person"
			>
				<unmasked-input
					[name]="Fields.LastName.name"
					[pattern]="Fields.LastName.pattern"
					[placeholder]="'personalInfo.lastName' | translate"
					maxlength="30"
					type="text"
				></unmasked-input>
			</app-question-wrapper>

			<b class="contact-text">{{ "labels.contactInformation" | translate }}</b>

			<app-question-wrapper
				[show]="form.get(Fields.Email.name) && form.get(Fields.Email.name).enabled"
				class="email"
				icon="email"
			>
				<unmasked-input
					[name]="Fields.Email.name"
					[pattern]="Fields.Email.pattern"
					[placeholder]="'personalInfo.email' | translate"
					[subLabel]="'labels.emailQuoteAlert' | translate"
					[tabindex]="form.get(Fields.Email.name) && form.get(Fields.Email.name).enabled ? 0 : -1"
					type="email"
				>
				</unmasked-input>
			</app-question-wrapper>

			<app-question-wrapper
				[show]="form.get(Fields.PrimaryPhoneNumber.name) && form.get(Fields.PrimaryPhoneNumber.name).enabled"
				class="phoneNumber"
				icon="call"
			>
				<masked-input
					[inputMask]="phoneNumberMask"
					[name]="Fields.PrimaryPhoneNumber.name"
					[placeholder]="'personalInfo.phone' | translate"
					[tabindex]="
						form.get(Fields.PrimaryPhoneNumber.name) && form.get(Fields.PrimaryPhoneNumber.name).enabled ? 0 : -1
					"
					[topPlaceholder]="true"
					type="tel"
				>
				</masked-input>
			</app-question-wrapper>
		</div>

		<div *ngIf="isAgreementEnabled" class="form-group checkbox-item">
			<div
				[ngClass]="{
					show:
						form.get(Fields.IAgreeToReceiveEmailsByBolt.name) &&
						form.get(Fields.IAgreeToReceiveEmailsByBolt.name).enabled
				}"
			>
				<app-checkbox
					[fieldObj]="Fields.IAgreeToReceiveEmailsByBolt"
					[tabindex]="
						form.get(Fields.IAgreeToReceiveEmailsByBolt.name) &&
						form.get(Fields.IAgreeToReceiveEmailsByBolt.name).enabled
							? 0
							: -1
					"
					[valueChecked]="form.get(Fields.IAgreeToReceiveEmailsByBolt.name).value"
				>
				</app-checkbox>
			</div>
		</div>

		<div
			*ngIf="isLegalConsentEnabled && customFields"
			class="form-group checkbox-item legal-consent"
			formGroupName="CustomFields"
		>
			<app-checkbox
				[fieldObj]="Fields.D2CAgreeToTerms"
				[tabindex]="legalConsentControl && legalConsentControl.enabled ? 0 : -1"
				[valueChecked]="legalConsentControl.value"
			>
			</app-checkbox>
		</div>
	</form>

	<div class="next-button-wrapper">
		<next-button
			(clicked)="handleContinue()"
			(validationFailed)="handleValidation($event)"
			[disabled]="!form.valid"
			[myForm]="myform"
			[title]="'buttons.continue' | translate"
		></next-button>
	</div>

	<app-legal-text
		*ngIf="autoStateSpecificRules"
		[customLegalText]="creditFraudDisclosuresKey | translate"
	></app-legal-text>

	<app-legal-text></app-legal-text>
</section>
