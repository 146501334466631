import { Component, Injector, OnInit, QueryList, ViewChildren } from '@angular/core';
import { BasePageComponent } from '../../base-page.component';
import { QuoteDataService } from '../../../../../services/quote-data.service';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../../../store/states/app.state';
import { ActionApiService } from '../../../../../services/action-api.service';
import { FormArray, FormGroup, Validators } from '@angular/forms';
import { selectQuoteData } from '../../../../../store/selectors/quote-data.selectors';
import { first } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { PrefillDefaultsService } from 'src/app/services/prefill-defaults.service';
import { IDropdownOption } from 'src/app/components/form-controls/form-control-interfaces';
import { NextButtonComponent } from 'src/app/components/next-button/next-button.component';
import { Validations } from 'src/app/utils/validation';

const MAX_ACCIDENT_VALUE = 5;

@Component({
	selector: 'app-driver-history',
	templateUrl: './driver-history.component.html',
	styleUrls: ['./driver-history.component.scss'],
})
export class DriverHistoryComponent extends BasePageComponent implements OnInit {
	@ViewChildren(NextButtonComponent) nextButtonComponents: QueryList<NextButtonComponent>;
	actionApiService: ActionApiService;
	prefillService: PrefillDefaultsService;
	activeTabIndex = 0;
	skipbutton = false;
	maxAccidentValue = MAX_ACCIDENT_VALUE;

	accidents_type: IDropdownOption[] = [
		{ id: 1, value: 'AtFaultWithInjury', title: 'At Fault With Injury', labelKey: 'accidentsType.atFaultWithInjury' },
		{
			id: 2,
			value: 'AtFaultWithNoInjury',
			title: 'At Fault With No Injury',
			labelKey: 'accidentsType.atFaultWithNoInjury',
		},
		{ id: 3, value: 'NotAtFault', title: 'Not At Fault', labelKey: 'accidentsType.notAtFault' },
	];

	violations_type: IDropdownOption[] = [
		{ id: 1, value: 'CarelessDriving', title: 'Careless Driving', labelKey: 'violationsType.carelessDriving' },
		{ id: 2, value: 'DefectiveEquipment', title: 'Defective Equipment', labelKey: 'violationsType.defectiveEquipment' },
		{
			id: 3,
			value: 'DrivingonSusLicense',
			title: 'Driving on Sus. License',
			labelKey: 'violationsType.drivingonSusLicense',
		},
		{ id: 4, value: 'DUI', title: 'DUI', labelKey: 'violationsType.dui' },
		{
			id: 5,
			value: 'FailuretoObeySignal',
			title: 'Failure to Obey Signal',
			labelKey: 'violationsType.failuretoObeySignal',
		},
		{ id: 6, value: 'OtherMajor', title: 'Other Major', labelKey: 'violationsType.otherMajor' },
		{ id: 7, value: 'OtherMinor', title: 'Other Minor', labelKey: 'violationsType.otherMinor' },
		{ id: 8, value: 'Speeding1to5', title: 'Speeding 1-5', labelKey: 'violationsType.speeding1to5' },
		{ id: 9, value: 'Speeding6to10', title: 'Speeding 6-10', labelKey: 'violationsType.speeding6to10' },
		{ id: 10, value: 'Speeding11to15', title: 'Speeding 11-15', labelKey: 'violationsType.speeding11to15' },
		{ id: 11, value: 'Speeding16to20', title: 'Speeding 16-20', labelKey: 'violationsType.speeding16to20' },
		{ id: 12, value: 'Speeding21plus', title: 'Speeding 21+', labelKey: 'violationsType.speeding21plus' },
		{
			id: 13,
			value: 'SpeedingViolatioMajor',
			title: 'Speeding Violation-Major',
			labelKey: 'violationsType.speedingViolatioMajor',
		},
		{
			id: 14,
			value: 'SpeedingViolationMinor',
			title: 'Speeding Violation-Minor',
			labelKey: 'violationsType.speedingViolationMinor',
		},
		{
			id: 15,
			value: 'TicketViolationNotListed',
			title: 'Ticket Violation Not Listed',
			labelKey: 'violationsType.ticketViolationNotListed',
		},
	];

	losses_type: IDropdownOption[] = [
		{ id: 1, value: 'Fire', title: 'Fire', labelKey: 'lossesType.fire' },
		{ id: 2, value: 'HitAnimal', title: 'Hit Animal', labelKey: 'lossesType.hitAnimal' },
		{ id: 3, value: 'Theft', title: 'Theft', labelKey: 'lossesType.theft' },
		{ id: 4, value: 'Towing', title: 'Towing', labelKey: 'lossesType.towing' },
		{ id: 5, value: 'Vandalism', title: 'Vandalism', labelKey: 'lossesType.vandalism' },
		{ id: 6, value: 'Glass', title: 'Glass', labelKey: 'lossesType.glass' },
		{ id: 7, value: 'TornadoHurricane', title: 'Tornado / Hurricane', labelKey: 'lossesType.tornadoHurricane' },
		{ id: 8, value: 'Flood', title: 'Flood', labelKey: 'lossesType.flood' },
		{ id: 9, value: 'WindHail', title: 'Wind/Hail', labelKey: 'lossesType.windHail' },
		{ id: 10, value: 'AllOther', title: 'Other', labelKey: 'lossesType.allOther' },
	];

	constructor(
		injector: Injector,
		protected quoteDataService: QuoteDataService,
		protected store: Store<IAppState>,
		actionApiService: ActionApiService,
		prefillService: PrefillDefaultsService
	) {
		super(injector, store, quoteDataService);
		this.actionApiService = actionApiService;
		this.prefillService = prefillService;
	}

	get driversFormControl(): FormArray {
		return this.form.controls.driverHistory as FormArray;
	}

	get driverHistory(): FormArray {
		return this.form.get('driverHistory') as FormArray;
	}

	ngOnInit(): void {
		super.ngOnInit();
		this.initForm();

		setTimeout(() => {
			this.patchData(this.form);
			this.registerOnChange(this.form);
			this.activateTab(0);
		});

		this.store
			.select(selectQuoteData)
			.pipe(first())
			.subscribe((quoteData) => {
				let tabToActivate = 0;
				const driverHistory =
					quoteData?.driverHistory && quoteData?.driverHistory?.length ? quoteData.driverHistory : [];
				let drivers = [...quoteData.Drivers, ...quoteData.discoverDrivers, ...quoteData.manualDrivers].filter(
					(driver) => driver.active !== false
				);
				if (drivers.length > 1) {
					this.skipbutton = true;
				}
				drivers.forEach((driver, index) => {
					const driverHistoryItem = driverHistory.find((item) => item.Id === driver.Id);
					this.driversFormControl.push(this.createDriverForm({ ...driver, ...driverHistoryItem }));
					if (driverHistoryItem) {
						tabToActivate = index;
					}
				});
				this.activateTab(tabToActivate);
			});
	}

	initForm() {
		this.form = new FormGroup({
			driverHistory: new FormArray([]), //list of drivers
		});
	}

	createDriverForm(driver): FormGroup {
		let data = {
			Id: driver.Id,
			SequenceNum: driver.SequenceNum,
			FirstName: driver.FirstName,
			LastName: driver.LastName,
			Accidents: driver.Accidents
				? new FormArray(
					driver.Accidents.map((item, index) => {
						return this.createFormGroupIncident('Accidents', item.SequenceNum, item);
					})
				)
				: new FormArray([]),
			Violations: driver.Violations
				? new FormArray(
					driver.Violations.map((item, index) => {
						return this.createFormGroupIncident('Violations', item.SequenceNum, item);
					})
				)
				: new FormArray([]),
			Losses: driver.Losses
				? new FormArray(
					driver.Losses.map((item, index) => {
						return this.createFormGroupIncident('Losses', item.SequenceNum, item);
					})
				)
				: new FormArray([]),
			HasAccidents: [typeof driver.HasAccidents === 'boolean' ? driver.HasAccidents : null, Validators.required],
			HasViolations: [typeof driver.HasViolations === 'boolean' ? driver.HasViolations : null, Validators.required],
			HasAutoLosses: [typeof driver.HasAutoLosses === 'boolean' ? driver.HasAutoLosses : null, Validators.required],
		};
		let driverFormGroup = this.formBuilder.group(data);

		let handleValueChanges = (value, driverIndex, type, fieldName) => {
			if (value) {
				this.addIncident(driverIndex, type, fieldName);
			} else {
				// remove all incidents where sequence number is not -1
				this.getIncidents(driverIndex, type).controls.forEach((item, index) => {
					if (item.get('SequenceNum').value !== -1) {
						this.removeIncident(index, driverIndex, type, fieldName);
					}
				});
			}
		};

		driverFormGroup.controls.HasAccidents.valueChanges.subscribe((value) => {
			let driverIndex = this.driverHistory.controls.findIndex(
				(item) => item.get('Id').value === driverFormGroup.get('Id').value
			);
			handleValueChanges(value, driverIndex, 'Accidents', 'HasAccidents');
		});

		driverFormGroup.controls.HasViolations.valueChanges.subscribe((value) => {
			let driverIndex = this.driverHistory.controls.findIndex(
				(item) => item.get('Id').value === driverFormGroup.get('Id').value
			);
			handleValueChanges(value, driverIndex, 'Violations', 'HasViolations');
		});

		driverFormGroup.controls.HasAutoLosses.valueChanges.subscribe((value) => {
			let driverIndex = this.driverHistory.controls.findIndex(
				(item) => item.get('Id').value === driverFormGroup.get('Id').value
			);
			handleValueChanges(value, driverIndex, 'Losses', 'HasAutoLosses');
		});

		setTimeout(() => {
			let driverIndex = this.driverHistory.controls.findIndex(
				(item) => item.get('Id').value === driverFormGroup.get('Id').value
			);
			if (driver.HasAccidents && data.Accidents.length === 0) {
				this.addIncident(driverIndex, 'Accidents', 'HasAccidents');
			}
			if (driver.HasViolations && data.Violations.length === 0) {
				this.addIncident(driverIndex, 'Violations', 'HasViolations');
			}
			if (driver.HasAutoLosses && data.Losses.length === 0) {
				this.addIncident(driverIndex, 'Losses', 'HasAutoLosses');
			}
		}, 0);

		return driverFormGroup as FormGroup;
	}

	createFormGroupIncident(type, SequenceNum, data?) {
		let formGroup;
		switch (type) {
			case 'Accidents':
				formGroup = {
					Id: data?.Id ? data.Id : uuidv4(),
					SequenceNum: SequenceNum,
					Description: [data?.Description || null, [Validators.required]],
					DateOfAccident: [
						data?.DateOfAccident ? moment(data.DateOfAccident).format('MM/DD/YYYY') : null,
						[Validators.required, Validations.isDateLessThanYears(5), Validations.futureDate, Validations.validDate],
					],
				};
				break;
			case 'Violations':
				formGroup = {
					Id: data?.Id ? data.Id : uuidv4(),
					SequenceNum: SequenceNum,
					Description: [data?.Description || null, [Validators.required]],
					DateOfViolation: [
						data?.DateOfViolation ? moment(data.DateOfViolation).format('MM/DD/YYYY') : null,
						[Validators.required, Validations.isDateLessThanYears(5), Validations.futureDate, Validations.validDate],
					],
				};
				break;
			case 'Losses':
				formGroup = {
					Id: data?.Id ? data.Id : uuidv4(),
					SequenceNum: SequenceNum,
					AutoLossesDescription: [data?.AutoLossesDescription || null, [Validators.required]],
					AutoLossesDate: [
						data?.AutoLossesDate ? moment(data.AutoLossesDate).format('MM/DD/YYYY') : null,
						[Validators.required, Validations.isDateLessThanYears(5), Validations.futureDate, Validations.validDate],
					],
				};
				break;
		}

		formGroup = this.formBuilder.group(formGroup) as FormGroup;
		if (formGroup.value.SequenceNum === -1) {
			formGroup.disable();
		}
		return formGroup;
	}

	getIncidents(driverIndex, type) {
		// get incidents of specific driver where item is not disabled
		return this.driverHistory.at(driverIndex).get(type) as FormArray;
	}

	getEnabledIncidentsLength(driver: FormGroup, type) {
		return driver.get(type).value.filter((item) => item.SequenceNum !== -1).length;
	}

	addIncident(driverIndex, type, fieldName) {
		// don't add more than 5 incidents ignore if incident have sequence number -1
		let driver = this.driverHistory.at(driverIndex) as FormGroup;
		if (this.getEnabledIncidentsLength(driver, type) >= this.maxAccidentValue) {
			return;
		}

		// add incident
		const incidentArray = this.driverHistory.at(driverIndex).get(type) as FormArray;
		incidentArray.push(this.createFormGroupIncident(type, incidentArray.length));
		this.eventsService.addEvent(this.EventActions.BUTTON_CLICK, {
			label: `add-incident-${type}`,
			button: 'add-incident',
			module: 'driver-history-module',
		});
	}

	removeIncident(index, driverIndex, type, fieldName) {
		const formArray = this.driverHistory.at(driverIndex).get(type) as FormArray;
		// set the sequence number to -1 to indicate that it should be deleted and disable the form control
		let formControl = formArray.at(index) as FormGroup;
		formControl.get('SequenceNum').setValue(-1, { emitEvent: false });
		// delete all fields besides Id and SequenceNum
		Object.keys(formControl.controls).forEach((key) => {
			if (key !== 'Id' && key !== 'SequenceNum') {
				formControl.removeControl(key);
			}
		});
		formControl.disable();

		// set value to false when no incidents
		if (formArray.length === 0 || formArray.value.every((item) => item.SequenceNum === -1)) {
			this.driverHistory.at(driverIndex).get(fieldName).setValue(false);
		}
		this.eventsService.addEvent(this.EventActions.BUTTON_CLICK, {
			label: 'remove-incident',
			button: 'remove-incident',
			module: 'driver-history-module',
		});
	}

	handleContinue() {
		const nextTabIndex = this.activeTabIndex + 1;
		if (!this.isLastTabActive()) {
			if (nextTabIndex < this.driverHistory?.length) {
				this.activateTab(nextTabIndex);
			}
			return;
		} else {
			// get last item of NextButtonComponents and set it's loading to true
			let nextButtonComponent = this.nextButtonComponents.last;
			nextButtonComponent.loading = true;
			let data = this.form.getRawValue().driverHistory;
			data.forEach((driver) => {
				delete driver.FirstName;
				delete driver.LastName;
			});
			this.submitForm(data);
		}
	}

	handleSkipButton() {
		this.driverHistory.controls.forEach((driver) => {
			driver.patchValue({
				HasAccidents: false,
				HasViolations: false,
				HasAutoLosses: false,
			});
		});

		let data = this.form.getRawValue().driverHistory;
		let nextButtonComponent = this.nextButtonComponents.first;
		nextButtonComponent.loading = true;
		this.submitForm(data);
		this.eventsService.addEvent(this.EventActions.BUTTON_CLICK, {
			label: 'skip-all',
			button: 'skip-all',
			module: 'driver-History-module',
		});
	}

	submitForm(data) {
		// delete empty arrays depending on the type
		data = data.map((driver) => {
			if (driver.Accidents?.length === 0) {
				delete driver.Accidents;
			}
			if (driver.Violations?.length === 0) {
				delete driver.Violations;
			}
			if (driver.Losses?.length === 0) {
				delete driver.Losses;
			}
			return driver;
		});

		// Updates the quote
		this.actionApiService
			.updateApplication({
				Drivers: data,
			})
			.pipe(first())
			.subscribe((serverRes) => {
				this.onUpdateApplicationResult(serverRes, this.driversFormControl.controls as Array<FormGroup>);
			});
	}

	isTabActive(index: number): boolean {
		return index === this.activeTabIndex;
	}

	isTabActiveOrPassed(index: number): boolean {
		return index <= this.activeTabIndex;
	}

	isLastTabActive(): boolean {
		return this.activeTabIndex === this.driverHistory.length - 1;
	}

	activateTab(index: number) {
		this.activeTabIndex = index;
	}

	activateNextTab() {
		const nextTabIndex = this.activeTabIndex + 1;

		if (nextTabIndex < this.driverHistory.length) {
			this.activateTab(nextTabIndex);
		}
	}

	activatePrevTab() {
		const prevTabIndex = this.activeTabIndex - 1;

		if (prevTabIndex >= 0) {
			this.activateTab(prevTabIndex);
			this.eventsService.addEvent(this.EventActions.BUTTON_CLICK, {
				label: 'go-back',
				button: 'go-back-driver-history',
				module: 'driver-history-module',
			});
		}
	}
}
