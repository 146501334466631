import { Component, Injector, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Store } from '@ngrx/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import moment from 'moment';

import { BasePageComponent } from '../../../../../base-page.component';
import { IAppState } from '../../../../../../../../store/states/app.state';
import * as FieldsOptions from '../../../../../../../../constants/progressive-fields-options';
import { PGR_CARRIERS_LIST } from '../../../../../../../../constants/progressive-fields-options';
import { selectQuoteData } from '../../../../../../../../store/selectors/quote-data.selectors';
import { IQuoteDataState } from '../../../../../../../../store/states/quote-data.state';
import { ActionApiService } from '../../../../../../../../services/action-api.service';
import { Validations } from '../../../../../../../../utils/validation';
import { QuoteDataService } from '../../../../../../../../services/quote-data.service';
import { handleIncompleteAddressInAddressForm } from '../../../../../../../../utils/general.utils';

@UntilDestroy()
@Component({
	selector: 'app-progressive-additional-questions',
	templateUrl: './progressive-additional-questions.component.html',
	styleUrls: ['./progressive-additional-questions.component.scss'],
})
export class ProgressiveAdditionalQuestionsComponent extends BasePageComponent implements OnInit {
	internalStep = 1;
	priorCarrier: string;
	carriersList = PGR_CARRIERS_LIST;

	fieldsOptions = FieldsOptions;
	quoteData: IQuoteDataState;
	fieldsNames = {
		AddressLine1: 'PreviousAddress.AddressLine1',
		AddressLine2: 'PreviousAddress.AddressLine2',
		City: 'PreviousAddress.City',
		State: 'PreviousAddress.State',
		ZipCode: 'PreviousAddress.ZipCode',
		County: 'PreviousAddress.County',
	};

	addressFormGroup: FormGroup;
	address = false;

	stepTwoFields = [
		this.Fields.PGR_DsbPkgInd.name,
		this.Fields.PGR_DsbBalanceInd.name,
		this.Fields.PGR_MultiPolicyInd.name,
		this.Fields.PGR_MultiPolicyRiskCd.name,
		this.Fields.PGR_Houseoccup.name,
	];

	stepThreeFields = [
		this.Fields.PGR_AllHdshldDrvListedInd.name,
		this.Fields.eSignAcknowledgeDriversInd.name,
		this.Fields.eSignAcknowledgeVehiclesInd.name,
	];

	constructor(
		injector: Injector,
		protected actionApiService: ActionApiService,
		protected store: Store<IAppState>,
		private fb: FormBuilder,
		protected quoteDataService: QuoteDataService
	) {
		super(injector, store);
	}

	get currentProgress(): number {
		return (100 / 3) * this.internalStep;
	}

	get addressForm(): FormGroup {
		return this.form.get('PreviousAddress') as FormGroup;
	}

	tabindex(ctrlName): number {
		return this.form.controls[ctrlName] && this.form.controls[ctrlName].enabled ? 0 : -1;
	}

	ngOnInit(): void {
		super.ngOnInit();

		this.store
			.select(selectQuoteData)
			.pipe(untilDestroyed(this))
			.subscribe((quoteData) => {
				this.quoteData = quoteData;
				this.priorCarrier = quoteData[this.Fields.PGR_CurrentCarrier.name] || null;
				this.addressFormGroup = this.fb.group({
					[this.Fields.AddressLine1.name]: [
						{
							value: quoteData.PreviousAddress?.AddressLine1 || null,
							disabled: false,
						},
						[Validators.required, Validators.maxLength(50), Validations.isPoBox],
					],
					[this.Fields.AddressLine2.name]: [
						{ value: quoteData.PreviousAddress?.AddressLine2 || null, disabled: false },
						[Validators.maxLength(50)],
					],
					[this.Fields.City.name]: [
						{
							value: quoteData.PreviousAddress?.City || null,
							disabled: false,
						},
						[Validators.required, Validators.maxLength(25)],
					],
					[this.Fields.State.name]: [
						{
							value: quoteData.PreviousAddress?.State || null,
							disabled: false,
						},
						[Validators.required, Validators.maxLength(2)],
					],
					[this.Fields.ZipCode.name]: [
						{
							value: quoteData.PreviousAddress?.ZipCode || null,
							disabled: false,
						},
						[Validators.required, Validators.maxLength(5), Validations.zipStrictLength(5)],
					],
				});
			});

		this.form = this.fb.group({
			[this.Fields.PGR_CurrentCarrier.name]: [null, [Validators.required]],
			[this.Fields.AutoInsuranceCancelled.name]: [null, [Validators.required]],
			[this.Fields.PGR_DsbPkgInd.name]: [null],
			[this.Fields.PGR_DsbBalanceInd.name]: [null],
			[this.Fields.PGR_AllHdshldDrvListedInd.name]: [null],
			[this.Fields.PGR_LiabilityLimits.name]: [null, [Validators.required]],
			[this.Fields.YearsWithContinuousCoverageAuto.name]: [null, [Validators.required]],
			[this.Fields.YearsWithPriorCarrierAuto.name]: [null, [Validators.required, Validations.greaterThan0]],
			[this.Fields.PGR_CarrierExpirationDate.name]: [null, [Validators.required, Validations.dateFormat]],
			[this.Fields.NumPIPClaims.name]: [null, [Validators.required]],
			[this.Fields.PGR_YearsAtAddress.name]: [null, [Validators.required]],
			[this.Fields.PGR_MonthsAtAddress.name]: [null, [Validators.required]],
			[this.Fields.eSignAcknowledgeDriversInd.name]: [null],
			[this.Fields.eSignAcknowledgeVehiclesInd.name]: [null],
			[this.Fields.PGR_MultiPolicyInd.name]: [null],
			[this.Fields.PGR_Houseoccup.name]: [null],

			[this.Fields.PGR_MultiPolicyRiskCd.name]: [{ value: null, disabled: true }],
			[this.Fields.ReasonForLapse.name]: [{ value: null, disabled: true }],
			[this.Fields.PGR_SpinOffCode.name]: [{ value: null, disabled: true }],
			[this.Fields.PGR_PriorCarrierPolicyNumAuto.name]: [{ value: null, disabled: true }],
			PreviousAddress: this.addressFormGroup,
		});

		setTimeout(() => {
			// patch form on first load
			this.firstLoadPatchForm();

			// watch Prior Carrier change
			this.form.controls[this.Fields.PGR_CurrentCarrier.name]?.valueChanges
				.pipe(untilDestroyed(this))
				.subscribe((carrier) => {
					this.priorCarrier = carrier;

					this.patchForm();
				});

			this.form.controls[this.Fields.PGR_MultiPolicyInd.name]?.valueChanges
				.pipe(untilDestroyed(this))
				.subscribe((value) => {
					if (value) {
						this.addControl(this.Fields.PGR_MultiPolicyRiskCd.name, true);
					} else {
						this.form.controls[this.Fields.PGR_MultiPolicyRiskCd.name].disable();
					}
				});

			this.form.controls[this.Fields.PGR_YearsAtAddress.name]?.valueChanges
				.pipe(untilDestroyed(this))
				.subscribe((res) => {
					this.checkForAddressValidity(res, this.form.controls[this.Fields.PGR_MonthsAtAddress.name].value);
				});

			this.form.controls[this.Fields.PGR_MonthsAtAddress.name]?.valueChanges
				.pipe(untilDestroyed(this))
				.subscribe((res) => {
					this.checkForAddressValidity(this.form.controls[this.Fields.PGR_YearsAtAddress.name].value, res);
				});

			// continious coverage watcher
			this.form.controls[this.Fields.YearsWithContinuousCoverageAuto.name]?.valueChanges
				.pipe(untilDestroyed(this))
				.subscribe(() => {
					this.validateYears();
				});

			// years with prior carrier watcher
			this.form.controls[this.Fields.YearsWithPriorCarrierAuto.name]?.valueChanges
				.pipe(untilDestroyed(this))
				.subscribe(() => {
					this.validateYears();
				});
			// save form changes in Store
			super.registerOnChange(this.form);
		});
	}

	navigateInternal(): void {
		if (this.internalStep === 3) {
			return;
		}

		++this.internalStep;

		this.handleFieldsVisibilityPerStep();
	}

	navigateInternalBack(): void {
		--this.internalStep;

		this.handleFieldsVisibilityPerStep();
		this.eventsService.addEvent(this.EventActions.BUTTON_CLICK, {
			label: 'go-back',
			button: 'go-back-progressive-additional-questions',
			module: 'progressive-additional-questions-module',
		});
	}

	checkForAddressValidity(years, months) {
		if (years && years < 2) {
			this.form.controls.PreviousAddress.enable();
			if (this.addressFormGroup.controls.AddressLine1.value) {
				this.form.controls.PreviousAddress.setValue(this.addressFormGroup.value);
			}
		} else {
			this.form.controls.PreviousAddress.disable();
		}
	}

	handleValidation(errors) {
		if (errors && errors.length) {
			// navigate to step with error
			if (this.stepTwoFields.find((field) => errors.find((error) => error.id === field))) {
				this.internalStep = 1;
			} else if (this.stepThreeFields.find((field) => errors.find((error) => error.id === field))) {
				this.internalStep = 2;
			} else {
				this.internalStep = 0;
			}
			this.navigateInternal();
		}

		super.handleValidation(errors);
	}

	handleAddressChange() {
		this.address = true;
		handleIncompleteAddressInAddressForm(this.addressForm);
	}

	private addControl(fieldName: string, isValueReset = true, resetValue = null): void {
		const ctrlValue = isValueReset ? resetValue : this.quoteData[fieldName];
		this.form.controls[fieldName].enable();
		this.form.controls[fieldName].setValue(ctrlValue);
		this.form.controls[fieldName].addValidators([Validators.required]);

		if (this.stepTwoFields.indexOf(fieldName) >= 0 && this.internalStep !== 2) {
			this.form.controls[fieldName].removeValidators(Validators.required);
		}

		if (this.stepThreeFields.indexOf(fieldName) >= 0 && this.internalStep !== 3) {
			this.form.controls[fieldName].removeValidators(Validators.required);
		}
	}

	private firstLoadPatchForm(): void {
		// if we have Prior Carrier in Quote Data then patch form with all data from it
		if (this.priorCarrier) {
			this.form.controls[this.Fields.PGR_CurrentCarrier.name].setValue(this.priorCarrier);

			if (this.quoteData[this.Fields.PGR_MultiPolicyInd.name]) {
				this.addControl(this.Fields.PGR_MultiPolicyRiskCd.name, false);
			}

			[...this.stepTwoFields, ...this.stepThreeFields].forEach((fieldName) => {
				if (this.form.controls[fieldName]) {
					this.form.controls[fieldName].setValue(this.quoteData[fieldName]);
				}
			});
		}

		this.patchForm(false);
		super.patchData(this.form);
		this.checkForAddressValidity(
			this.form.controls[this.Fields.PGR_YearsAtAddress.name].value,
			this.form.controls[this.Fields.PGR_MonthsAtAddress.name].value
		);
	}

	private patchForm(isValueReset = true): void {
		if (this.priorCarrier) {
			// toggle fields visibility for Progressive carrier
			if (this.priorCarrier === 'Progressive') {
				this.addControl(this.Fields.PGR_SpinOffCode.name, isValueReset);
				this.form.controls[this.Fields.PGR_SpinOffCode.name].disable();

				this.addControl(this.Fields.PGR_PriorCarrierPolicyNumAuto.name, isValueReset);
				this.form.controls[this.Fields.PGR_PriorCarrierPolicyNumAuto.name].disable();
			} else {
				this.form.controls[this.Fields.PGR_SpinOffCode.name].disable();
				this.form.controls[this.Fields.PGR_PriorCarrierPolicyNumAuto.name].disable();
			}
		}

		// prior expiration date watcher
		this.form.controls[this.Fields.PGR_CarrierExpirationDate.name]?.valueChanges
			.pipe(untilDestroyed(this))
			.subscribe((date) => {
				// check if prior expiration date is before effective date
				// and create select reason for this lapse
				if (moment(date).isValid()) {
					if (moment(date).isBefore(this.quoteData.EffectiveDate)) {
						this.addControl(this.Fields.ReasonForLapse.name, true);
						this.form.controls[this.Fields.ReasonForLapse.name].addValidators(Validators.required);
					} else {
						this.form.controls[this.Fields.ReasonForLapse.name].disable();
						this.form.controls[this.Fields.ReasonForLapse.name]?.removeValidators(Validators.required);
					}
				}
			});
	}

	private validateYears() {
		if (
			/^\d+$/.test(this.form.controls[this.Fields.YearsWithContinuousCoverageAuto.name]?.value) &&
			/^\d+$/.test(this.form.controls[this.Fields.YearsWithPriorCarrierAuto.name]?.value)
		) {
			if (
				parseInt(this.form.controls[this.Fields.YearsWithContinuousCoverageAuto.name]?.value) <
				parseInt(this.form.controls[this.Fields.YearsWithPriorCarrierAuto.name]?.value)
			) {
				this.form.controls[this.Fields.YearsWithPriorCarrierAuto.name]?.setErrors({
					yearspriorcannotbehigercontinuous: true,
				});
				this.form.controls[this.Fields.YearsWithPriorCarrierAuto.name]?.markAsTouched();
				this.form.updateValueAndValidity();
			} else {
				let activeErrors = this.form.controls[this.Fields.YearsWithPriorCarrierAuto.name].errors;
				if (activeErrors?.yearspriorcannotbehigercontinuous) {
					delete activeErrors.yearspriorcannotbehigercontinuous;
				}
				this.form.controls[this.Fields.YearsWithPriorCarrierAuto.name]?.setErrors(
					(activeErrors && Object.keys(activeErrors).length) ? activeErrors : null
				);
				this.form.updateValueAndValidity();
			}
		}
	}

	private handleFieldsVisibilityPerStep(): void {
		// activate additional fields if prior carrier is Progressive
		const isPRGFieldActive = this.internalStep >= 2 && this.priorCarrier === 'Progressive';
		this.form.controls[this.Fields.PGR_SpinOffCode.name] &&
			this.form.controls[this.Fields.PGR_SpinOffCode.name][isPRGFieldActive ? 'enable' : 'disable']();
		this.form.controls[this.Fields.PGR_PriorCarrierPolicyNumAuto.name] &&
			this.form.controls[this.Fields.PGR_PriorCarrierPolicyNumAuto.name][isPRGFieldActive ? 'enable' : 'disable']();

		// toggle fields visibility for step 2
		this.stepTwoFields.forEach((fieldName) => {
			const errors = this.form.controls[fieldName].errors;
			if (this.form.controls[fieldName]) {
				this.form.controls[fieldName][this.internalStep === 2 ? 'addValidators' : 'removeValidators'](
					Validators.required
				);
			}

			if (this.internalStep === 2) {
				this.form.controls[fieldName].setErrors(errors);
				this.form.controls[fieldName].markAsTouched();
			}
		});

		// toggle fields visibility for step 3
		this.stepThreeFields.forEach((fieldName) => {
			const errors = this.form.controls[fieldName].errors;
			this.form.controls[fieldName][this.internalStep === 3 ? 'addValidators' : 'removeValidators'](
				Validators.required
			);
			this.form.controls[fieldName][this.internalStep === 3 ? 'enable' : 'disable']();
			if (this.internalStep === 3) {
				this.form.controls[fieldName].setErrors(errors);
				this.form.controls[fieldName].markAsTouched();
			}
		});
	}
}
